import React, { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import {
  Label,
  Card,
  CardBody,
  FormGroup,
  CardHeader,
  CardTitle,
  Col,
  Row,
  UncontrolledTooltip,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { ButtonToggle } from '@/components';
import { Field, Input } from '@availity/form';
import { FaQuestionCircle } from 'react-icons/fa';
import classNames from 'classnames';

const AgedSettings: FC = () => {
  const [breakPoint, setBreakPoint] = useState(0);
  const { values, setFieldValue } = useFormikContext();
  const attributePrefix = 'bulk-price-breakpoints';

  useEffect(() => {
    if (values.priceBreakPoints) {
      setBreakPoint(values?.priceBreakPoints.length - 1);
    }
  }, [values.priceBreakPoints]);

  return (
    <Card className="mb-3">
      <CardHeader className="d-flex justify-content-between align-items-center">
        <CardTitle
          tag="h5"
          className="py-2 h4 mb-0 d-flex justify-content-center align-items-center"
        >
          Bulk Purchases{' '}
          <FaQuestionCircle
            className="ml-2 cursor-pointer text-primary"
            id={`${attributePrefix}-tooltip`}
          />
          <UncontrolledTooltip
            target={`${attributePrefix}-tooltip`}
            placement="auto-start"
            style={{ textAlign: 'left' }}
          >
            For advanced users and admins
          </UncontrolledTooltip>
        </CardTitle>
        <ButtonToggle
          active={values.bulkPurchase}
          onToggle={() => setFieldValue('bulkPurchase', !values.bulkPurchase)}
        />
      </CardHeader>
      <CardBody noGutters>
        <Row>
          <Col
            xs={2}
            className={classNames('pl-2', {
              'd-none': !values.bulkPurchase,
            })}
          >
            <Label for="bulkPurchaseOnly">Bulk Only</Label>
            <FormGroup>
              <ButtonToggle
                active={values.bulkPurchaseOnly}
                onToggle={() =>
                  setFieldValue('bulkPurchaseOnly', !values.bulkPurchaseOnly)
                }
              />
            </FormGroup>
          </Col>
          <Col
            xs={3}
            className={classNames('pl-2', {
              'd-none': !values.bulkPurchase,
            })}
          >
            <Label for="bulkPurchase" className="d-flex">
              Custom Orders{' '}
              <FaQuestionCircle
                id="bulkPurchaseCustom"
                className="ml-1 cursor-pointer"
              />
              <UncontrolledTooltip
                target="bulkPurchaseCustom"
                style={{ textAlign: 'left' }}
                placement="auto-start"
              >
                If enabled, agents can place orders with custom quantities with
                optional minimums and maximums. If custom orders are disabled,
                the defaults are 500, 1000, and 2000.
              </UncontrolledTooltip>
            </Label>
            <FormGroup>
              <ButtonToggle
                active={values.bulkPurchaseCustom}
                onToggle={() =>
                  setFieldValue(
                    'bulkPurchaseCustom',
                    !values.bulkPurchaseCustom
                  )
                }
              />
            </FormGroup>
          </Col>
          {values?.bulkPurchaseCustom && values?.bulkPurchase && (
            <Col xs={3}>
              <Label for="bulkPurchaseMin">Min Custom Quantity</Label>
              <FormGroup>
                <Field
                  name="bulkPurchaseMin"
                  type="number"
                  style={{ maxWidth: 120 }}
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row className="mt-2">
          <Col className="d-flex align-items-center">
            <FormGroup
              className={classNames({ 'd-none': !values.bulkPurchase })}
            >
              <Label>Bulk Lead Settings</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  Max Leads:
                </InputGroupAddon>
                <Input
                  name="bulkPurchaseMax"
                  type="number"
                  style={{ maxWidth: 120 }}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    per order, 1 order allowed per agent every
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="bulkPurchaseTimeout"
                  type="number"
                  style={{ maxWidth: 100 }}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>hours.</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default AgedSettings;
