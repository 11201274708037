import React from 'react';
import Helmet from 'react-helmet';

export const getHost = (): string => {
  const domain = window.location.hostname;

  if (domain.includes('localhost')) {
    return 'sonicleads';
  }

  return domain.split('.')[1];
};

export const getImageUrl = (imageName: string): string => {
  return `https://s3.amazonaws.com/docs.sonicwebdev.com/images/${getHost()}/${imageName}`;
};

const getTitle = () => {
  if (window.location.href.includes('sonicleads')) {
    return 'Sonic Leads';
  }

  if (window.location.href.includes('leadtopia')) {
    return 'Leadtopia';
  }

  return 'Lead Arena | Marketplace';
};

const getHelmet = () => {
  return (
    <Helmet>
      <title>{getTitle()}</title>
      <link
        rel="icon"
        type="image/png"
        href={getImageUrl('favicon.ico')}
        sizes="16x16"
      />
    </Helmet>
  );
};

export default getHelmet;
