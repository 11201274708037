import React from 'react';
import {
  ListGroupItem,
  Row,
  Col,
  Button,
  Spinner,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from 'reactstrap';
import classNames from 'classnames';
import { formatCurrencyForDisplay } from '@/utils';
import { FaClock } from 'react-icons/fa';
import { CopyButton, SwitchButton } from '@/components';
import { useHistory } from 'react-router-dom';
import { useNetwork, useNotifications } from '@/hooks';
import { useMutation } from '@apollo/react-hooks';
import { toggleTierMutation } from '@/graphql/mutations';

const getTierTypeBadge = (isAged, liveTransfers, bulkPurchase, isOverflow) => {
  if (bulkPurchase) {
    return <Badge color="success">Bulk Purchases</Badge>;
  }
  if (isAged) {
    return <Badge style={{ background: '#16ccc9' }}>Aged</Badge>;
  }
  if (liveTransfers) {
    return <Badge color="warning">Live Transfers</Badge>;
  }
  if (isOverflow) {
    return <Badge style={{ background: '#6200a8' }}>Overflow</Badge>;
  }
  return <Badge color="primary">Data Only</Badge>;
};

const PriceTierRow: React.FC<{
  tier: any;
  loading: boolean;
  priceTiers: any[];
  togglePaginationModal: any;
  toggleAssignAllModal: any;
  setTierID: any;
  toggleUnassignAllModal: any;
}> = ({
  tier,
  loading,
  priceTiers,
  togglePaginationModal,
  setTierID,
  toggleAssignAllModal,
  toggleUnassignAllModal,
}) => {
  const history = useHistory();
  const { network } = useNetwork();
  const { create } = useNotifications();
  const [togglePause, { loading: toggling }] = useMutation(toggleTierMutation, {
    update: (_cache, { data: { toggleTier } }) => {
      const { success } = toggleTier;

      if (!success) {
        create('danger', 'Failed to toggle queue', 3000);
      } else {
        _cache.writeData({
          id: tier.id,
          data: {
            redisPriceTier: {
              ...tier.redisPriceTier,
              status: tier.redisPriceTier.status === 'ON' ? 'OFF' : 'ON',
            },
          },
        });
      }
    },
    onCompleted: () => create('success', 'Successfully toggled queue', 3000),
    onError: () => create('danger', 'Failed to toggle queue', 3000),
  });
  const { isAged, liveTransfers, bulkPurchase, isOverflow } = tier;
  return (
    <ListGroupItem
      id={`${tier.priceTierID}-${tier.priceTierID}`}
      tag={Row}
      noGutters
      className="mb-3 d-flex align-items-center"
    >
      <Col xs={1} className="pr-5">
        <UncontrolledButtonDropdown
          className={classNames(
            'align-items-center',
            tier.priceTierID === network?.overflowPriceTierID
              ? 'd-none'
              : 'd-flex'
          )}
        >
          <Button
            color="secondary"
            id={`${tier.priceTierID}-edit-button`}
            disabled={loading}
            onClick={() => history.push(`/price-tiers/${tier.id}`)}
          >
            Edit
          </Button>
          <DropdownToggle split color="secondary" />
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                togglePaginationModal();
                setTierID(tier.id);
              }}
            >
              Assign Agents
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                toggleAssignAllModal();
                setTierID(tier.id);
              }}
            >
              Assign All Agents
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                toggleUnassignAllModal();
                setTierID(tier.id);
              }}
            >
              Unassign All Agents
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </Col>
      <Col xs={2} className="d-flex flex-column text-center">
        <span className="h5">{tier.name}</span>
        <small>Name</small>
      </Col>
      <Col xs={1} className="d-flex flex-column text-center">
        <span className="h5">
          {!tier.liveTransfers ? (
            <SwitchButton
              width={70}
              checked={tier.redisPriceTier?.status === 'ON'}
              onlabel="On"
              offlabel="Off"
              disabled={toggling}
              onstyle="success"
              offstyle="primary"
              onChange={() => {
                togglePause({
                  variables: {
                    input: {
                      ids: [tier.id],
                      networkID: network?.networkID,
                      paused: tier.redisPriceTier?.status === 'ON',
                    },
                  },
                });
              }}
            />
          ) : (
            'N/A'
          )}
        </span>
      </Col>
      <Col xs={1} className="d-flex flex-column text-center">
        <span className="h5">
          {formatCurrencyForDisplay(tier.liveTransferPrice || tier.price!)}
        </span>
        <small>{tier.dynamicPrice && 'Average '}Price</small>
      </Col>
      <Col xs={1} className="d-flex flex-column text-center">
        <span className="h5">
          {loading ? <Spinner size="sm" /> : tier.mpVendorCampaignIDs.length}
        </span>
        <small>Lead Sources</small>
      </Col>
      <Col xs={1} className="d-flex flex-column text-center">
        <span className="h5">
          {tier.liveTransfers ? 'N/A' : tier.activeSpendingPeriods}
        </span>
        <small>Active Campaigns</small>
      </Col>
      <Col xs={1} className="d-flex flex-column text-center">
        <span className="h5">{tier.totalCampaigns}</span>
        <small>Total Campaigns</small>
      </Col>
      <Col xs={1} className="d-flex flex-column text-center">
        <span className="h5">
          {getTierTypeBadge(isAged, liveTransfers, bulkPurchase, isOverflow)}
        </span>
        <small>Tier Type</small>
      </Col>
      <Col xs={2} className="d-flex flex-column text-center">
        <span className="h5">
          {tier.fallbackPriceTierID ? (
            <small>
              <FaClock /> {tier.fallbackAmount} {tier.fallbackIncrement} -{' '}
              {
                priceTiers.find(
                  (t) => t.priceTierID === tier.fallbackPriceTierID
                )!.name
              }
            </small>
          ) : (
            'None'
          )}
        </span>
        <small>Fallback Tier</small>
      </Col>
      <Col xs={1}>
        <CopyButton
          id={`${tier.priceTierID}-copy-button`}
          content={tier.priceTierID}
          className="float-right"
        />
      </Col>
    </ListGroupItem>
  );
};

export default PriceTierRow;
