import React, { useEffect } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ListGroup,
  ListGroupItem,
  ModalFooter,
  ModalProps,
  Button,
  Col,
} from 'reactstrap';
import { Pagination, PaginationContent } from '@availity/pagination';
import { PaginationControls, DebouncedInput } from '@/components';
import { FaUser } from 'react-icons/fa';
import { Form, CheckboxGroup, Checkbox } from '@availity/form';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { agentPaginationQuery } from '@/graphql/queries';
import { updateAgentsMutation } from '@/graphql/mutations';
import { useNotifications, useNetwork } from '@/hooks';
import useAgent from '@/areas/Agents/DetailView/useAgent';

const roleText = (role: string): string => {
  switch (role) {
    case 'user':
      return 'User';
    case 'manager':
      return 'Manager';
    case 'admin':
      return 'Administrator';
    case 'super_admin':
      return 'Super Admin';
    default:
      return 'User';
  }
};

const ProfileEditAgentsModal: React.SFC<Omit<ModalProps, 'toggle'>> = ({
  isOpen,
  toggle,
  ...props
}) => {
  const client = useApolloClient();
  const { user } = useAgent();
  const { create: createNotification } = useNotifications();
  const [updateUsers, { loading: updating }] = useMutation(
    updateAgentsMutation,
    {
      onCompleted: () => {
        createNotification('success', 'Successfully update Agents.');
        toggle();
      },
      onError: () => {
        createNotification('danger', 'Failed to update Agents.');
      },
    }
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle} {...props}>
      <Form
        onSubmit={(values, formikBag) => {
          updateUsers({
            variables: {
              input: {
                managerID: user!.agentID,
                agentIDs: values.agents,
              },
            },
            update: (cache, { data: { agentsUpdate } }) => {
              cache.writeData({
                id: user?.id,
                data: {
                  agentIDs: agentsUpdate,
                },
              });
            },
          });
        }}
        initialValues={{
          agents: user!.agentIDs,
          searchText: '',
        }}
      >
        {({ values, resetForm }) => {
          useEffect(() => {
            resetForm();
          }, [isOpen]);

          const fetchItems = async (
            currentPage: number,
            itemsPerPage: number
          ): Promise<any> => {
            const response = await client.query({
              query: agentPaginationQuery,
              variables: {
                filter: {
                  networkID: user?.network.id,
                  q: values.searchText,
                },
                perPage: itemsPerPage,
                page: currentPage,
                orderBy: {
                  firstName: 'ASC',
                },
              },
            });

            const currentPageData = response.data?.agentPagination.items;

            return {
              totalCount: response.data.agentPagination.count,
              items: currentPageData,
            };
          };

          return (
            <>
              <ModalHeader toggle={toggle}>Network Agents</ModalHeader>
              <Pagination
                items={fetchItems}
                itemsPerPage={10}
                resetParams={[values.searchText]}
              >
                <ModalBody>
                  <Col xs={12} className="d-flex">
                    <DebouncedInput name="searchText" className="mr-4" />
                    <PaginationControls
                      pageRange={3}
                      marginPages={0}
                      directionLinks
                      style={{ marginTop: 1 }}
                    />
                  </Col>
                  <CheckboxGroup
                    name="agents"
                    for="agents"
                    groupClassName="bg-dark"
                  >
                    <PaginationContent
                      containerProps={{
                        className: 'bg-dark text-white',
                      }}
                      containerTag={ListGroup}
                      itemKey="agentID"
                      component={({ agentID, firstName, lastName, role }) => (
                        <ListGroupItem className="d-flex justify-content-between align-items-center">
                          <Checkbox
                            name={agentID}
                            label={`${firstName} ${lastName} - ${roleText(
                              role
                            )}`}
                            value={agentID}
                          />
                          <FaUser />
                        </ListGroupItem>
                      )}
                    />
                  </CheckboxGroup>
                </ModalBody>
              </Pagination>
              <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
                <Button color="primary" type="submit" disabled={updating}>
                  Save Agents
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default ProfileEditAgentsModal;
