import gql from 'graphql-tag';

export const vendorPartial = `
id
__typename
vendorName
vendorID
vendorPriceIncluded
flags {
  allowAgentDirect
  allowDuplicateRejects
  allowDisconnectedRejects
  sendsPricesWithLeads
}
`;
const VendorFragment = gql`
  fragment VendorFragment on Vendor {
    ${vendorPartial}
  }
`;

export default VendorFragment;
