import React, { useState } from 'react';
import { useFormikContext, Formik, ErrorMessage } from 'formik';
import { useNotifications } from '@/hooks';
import brokerPhoneAvailableQuery from '@/graphql/queries/brokerPhoneAvailableNumber';
import { Input, FormGroup } from '@availity/form';
import Button from '@/components/LoadingButton';
import { LabelWithToolTip } from '@/components';
import * as yup from 'yup';
import { useLazyQuery } from 'react-apollo';
import {
  InputGroup,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  InputGroupAddon,
  DropdownItem,
  Label,
} from 'reactstrap';

const PhoneInputField: React.FC = () => {
  const { create } = useNotifications();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((o) => !o);
  const { values, setFieldValue } = useFormikContext<{
    phoneLookUp: string;
    phoneLookUpValue: string;
    liveTransferPhoneNumber: string;
    liveTransfersEnabled: boolean;
  }>();
  const [availablePhones, { data }] = useLazyQuery(brokerPhoneAvailableQuery, {
    variables: {
      [values?.phoneLookUp]: values?.phoneLookUpValue,
    },
    onCompleted: () => {
      setFieldValue('liveTransferPhoneNumber', data.brokerPhoneAvailableNumber);
    },
    onError: () => {
      create('danger', `No numbers found. Please try again.`, 3000);
    },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Formik
        initialValues={{
          phoneLookUp: values.phoneLookUp,
          phoneLookUpValue: '',
        }}
        initialTouched={{
          phoneLookUp: true,
          phoneLookUpValue: true,
        }}
        validationSchema={yup.object().shape({
          phoneLookUpValue: yup
            .string()
            .matches(/^\d+$/, 'Please only enter numbers.')
            .when(['phoneLookUp'], {
              is: 'areaCode',
              then: (schema: yup.StringSchema) =>
                schema
                  .min(3, 'Area code should be three digits.')
                  .max(3, 'Area code should be three digits.'),
              otherwise: (schema: yup.StringSchema) =>
                schema
                  .min(5, 'Zip code should be five digits')
                  .max(5, 'Zip code should be five digits'),
            })
            .required('This field is required.'),
        })}
        onSubmit={(values) => {
          availablePhones({
            variables: {
              [values.phoneLookUp]: values.phoneLookUpValue,
            },
          });
        }}
        validateOnMount
      >
        {({ handleSubmit, setFieldValue, isValid, values }) => (
          <FormGroup for="phoneLookUpValue">
            <LabelWithToolTip
              name="phoneLookUpValue"
              label="Live Transfer Phone"
              helpMessage="Phone number that the lead will be calling to."
            />
            <InputGroup>
              <InputGroupButtonDropdown
                addonType="prepend"
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
              >
                <DropdownToggle split caret>
                  {values.phoneLookUp === 'zipCode' ? 'Zip Code' : 'Area Code'}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    active={values.phoneLookUp === 'zipCode'}
                    onClick={() => setFieldValue('phoneLookUp', 'zipCode')}
                  >
                    Zip Code
                  </DropdownItem>
                  <DropdownItem
                    active={values.phoneLookUp === 'areaCode'}
                    onClick={() => setFieldValue('phoneLookUp', 'areaCode')}
                  >
                    Area Code
                  </DropdownItem>
                </DropdownMenu>
              </InputGroupButtonDropdown>
              <Input
                name="phoneLookUpValue"
                placeholder="Search for a Number"
              />
              <InputGroupAddon addonType="append">
                <Button
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!isValid}
                >
                  Find
                </Button>
              </InputGroupAddon>
            </InputGroup>
            <ErrorMessage
              name="phoneLookUpValue"
              component={(props) => <div {...props} className="text-danger" />}
            />
          </FormGroup>
        )}
      </Formik>
    </>
  );
};

export default PhoneInputField;
