import React from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { SelectField } from '@availity/select';
import { components } from 'react-select';
import { SelectFieldProps } from '@availity/select/typings/SelectField';
import { priceTierManyQuery } from '@/graphql/queries';
import { formatCurrencyForDisplay } from '@/utils';
import { useAdmin, useNetwork, useNetworks } from '@/hooks';

interface PriceTierSelectProps extends SelectFieldProps<any> {
  defaultLeadTiers: string[];
  networkID: string | string[];
}

const PriceTierSelect: React.SFC<PriceTierSelectProps> = ({
  defaultLeadTiers,
  networkID,
  ...props
}) => {
  const { data: networkData } = useNetworks({
    fetchPolicy: 'cache-only',
  });
  const { network: currentNetwork } = useNetwork();

  const networks = networkData?.networkMany || [];

  const isMultiNetworkTierSelect = typeof networkID === 'object';
  const { data } = useQuery(priceTierManyQuery, {
    variables: {
      [isMultiNetworkTierSelect ? 'networkIDs' : 'networkID']: networkID,
    },
  });

  const tiers = data?.priceTierMany || [];

  return (
    <SelectField
      getOptionLabel={(opt: any) => {
        return `${opt.name} - ${formatCurrencyForDisplay(
          opt.liveTransferPrice || opt.price
        )} ${
          defaultLeadTiers?.find((t) => t === opt.name) ? ' (Default)' : ''
        }`;
      }}
      valueKey="id"
      defaultOptions
      raw
      components={{
        MultiValueContainer: (p) => {
          if (
            !isMultiNetworkTierSelect &&
            p.data.networkID !== currentNetwork?.networkID
          ) {
            return null;
          }
          return <components.MultiValueContainer {...p} />;
        },
      }}
      options={
        isMultiNetworkTierSelect
          ? tiers.reduce(
              (arr, tier) => {
                // eslint-disable-next-line no-unused-expressions
                arr[
                  (networkID as string[]).findIndex(
                    (nId) => tier.networkID === nId
                  )
                ]?.options.push(tier);

                return arr;
              },
              (networkID as string[]).map((nId) => ({
                label: networks.find((n: any) => n.networkID === nId)
                  ?.networkName,
                options: [],
              }))
            )
          : tiers
      }
      {...props}
    />
  );
};

export default PriceTierSelect;
