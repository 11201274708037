import gql from 'graphql-tag';
import { VendorCampaignTierFragment } from '@/graphql/fragments';

export default gql`
  query vendorCampaignManyTierQuery($networkID: String, $layerID: String) {
    vendorCampaignManyTierQuery(networkID: $networkID, layerID: $layerID) {
      ...VendorCampaignTierFragment
    }
  }
  ${VendorCampaignTierFragment}
`;
