import React, { useState, useEffect } from 'react';
import { Input, InputProps } from 'reactstrap';
import { useField } from 'formik';

interface DebouncedInputProps extends InputProps {
  name: string;
}

const DebouncedInput: React.SFC<DebouncedInputProps> = ({ name, ...rest }) => {
  // eslint-disable-next-line unicorn/no-unreadable-array-destructuring
  const [{ value: currentValue }, , { setValue }] = useField(name);
  const [search, setSearch] = useState(currentValue);

  useEffect(() => {
    const tmId = setTimeout(() => {
      if (currentValue !== search) {
        setValue(search);
      }
    }, 500);

    return () => clearTimeout(tmId);
  }, [search, setValue]);

  useEffect(() => {
    if (currentValue !== search) {
      setSearch(currentValue);
    }
  }, [currentValue]);

  return (
    <Input
      name={name}
      placeholder="Search..."
      value={search}
      onChange={({ target }) => setSearch(target.value)}
      type="text"
      {...rest}
    />
  );
};

export default DebouncedInput;
