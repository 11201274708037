/* eslint-disable consistent-return */
/* eslint-disable no-useless-computed-key */
/* eslint-disable unicorn/consistent-function-scoping */
import { useEffect, useState } from 'react';
import aws4 from 'aws4';
import { WebSocketLink } from 'apollo-link-ws';
import { Auth } from 'aws-amplify';
import url from 'url';
import { Vars } from '@/utils';
import { split, HttpLink, ApolloLink } from 'apollo-boost';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const createHttpLink = (): HttpLink => {
  const awsGraphqlFetch = async (
    uri: string,
    options: any
  ): Promise<Response> => {
    options = options || {};
    const signable: any = {};
    const urlObject = url.parse(uri);
    signable.host = urlObject.host;
    signable.path = urlObject.path;
    ['method', 'body', 'headers', 'region', 'service'].forEach(
      (key: string) => {
        signable[key] = options[key];
      }
    );

    const {
      secretAccessKey,
      accessKeyId,
      sessionToken,
      identityId: agentID,
    } = await Auth.currentCredentials();

    aws4.sign(signable, {
      secretAccessKey,
      accessKeyId,
      sessionToken,
    });
    options.headers = signable.headers;

    // TODO lets not add this if we are not running locally since API Gateway does this for us
    if (uri === '/graphql') {
      options.headers['x-identity-header'] = agentID;
    }
    options.headers[
      'x-time-zone'
    ] = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return fetch(uri, options);
  };

  return new HttpLink({
    uri: Vars.uri,
    fetch: awsGraphqlFetch,
  });
};

const createWebSocketLink = (
  subscriptionClient: SubscriptionClient
): WebSocketLink => {
  return new WebSocketLink(subscriptionClient);
};

const useApolloLink = (authState?: string): ApolloLink | undefined => {
  const [link, setLink] = useState<ApolloLink | undefined>(undefined);

  useEffect(() => {
    if (!['signedIn', 'verifyContact'].includes(authState || '')) {
      setLink(undefined);
      return;
    }

    let subscriptionClient: SubscriptionClient;

    const createLink = async (): Promise<any> => {
      const { identityId: agentID } = await Auth.currentCredentials();

      const {
        accessToken: { jwtToken },
        refreshToken: { token },
        idToken: {
          payload: {
            ['custom:network']: networkID,
            ['custom:vendor']: vendorID,
            ['custom:publisher']: publisherID,
          },
        },
      }: any = await Auth.currentSession();

      subscriptionClient = new SubscriptionClient(Vars.subScriptionUri, {
        connectionParams: {
          accessToken: jwtToken,
          refeshToken: token,
          agentID,
          networkID,
          vendorID,
          publisherID,
          ClientId: Vars.awsmobile.aws_user_pools_web_client_id,
        },
        reconnect: true,
      });

      subscriptionClient = new SubscriptionClient(Vars.subScriptionUri, {
        connectionParams: {
          accessToken: jwtToken,
          refeshToken: token,
          agentID,
          networkID,
          ClientId: Vars.awsmobile.aws_user_pools_web_client_id,
        },
        reconnect: true,
      });

      const wsLink = createWebSocketLink(subscriptionClient);

      const httpLink = createHttpLink();

      setLink(
        split(
          ({ query }) => {
            const { kind, operation } = getMainDefinition(query);
            return (
              kind === 'OperationDefinition' && operation === 'subscription'
            );
          },
          wsLink,
          httpLink
        )
      );
    };

    createLink();

    return () => {
      if (subscriptionClient) {
        subscriptionClient.close();
      }
    };
  }, [authState]);

  return link;
};

export default useApolloLink;
