/* eslint-disable unicorn/no-nested-ternary */
import React from 'react';
import {
  ListGroupItem,
  Col,
  Row,
  Button,
  Collapse,
  ListGroup,
  Spinner,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledPopover,
  UncontrolledTooltip,
  PopoverBody,
  Table,
} from 'reactstrap';
import classnames from 'classnames';
import qs from 'querystring';
import { Link } from 'react-router-dom';
import {
  FaExpand,
  FaCompress,
  FaSortAmountDown,
  FaDivide,
} from 'react-icons/fa';
import { formatCurrencyForDisplay } from '@/utils';

function numberWithCommas(x: number): string {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '0';
}

const SoldPopOver: React.FC<{
  target: string;
  sold: number;
  overflow: number;
}> = ({ target, sold, overflow }) => (
  <UncontrolledPopover
    target={target}
    trigger="hover"
    placement="top"
    // offset={50}
  >
    {/* <PopoverHeader>Totals</PopoverHeader> */}
    <PopoverBody className="p-1">
      <Table borderless size="sm" className="mb-0">
        <tbody>
          <tr>
            <td className="p-1">
              <small>{sold - overflow}</small>
            </td>
            <td className="p-1 text-right">
              <small>Sold</small>
            </td>
          </tr>
          <tr>
            <td className="p-1">
              <small>{overflow}</small>
            </td>
            <td className="p-1 text-right">
              <small>Overflow</small>
            </td>
          </tr>
        </tbody>
      </Table>
    </PopoverBody>
  </UncontrolledPopover>
);

const DashboardItem: React.SFC<any> = ({
  name,
  description,
  displayName,
  expanding,
  rejected,
  totalVendorPrice,
  sold,
  total = 0,
  overflow,
  vendorPrice,
  leadPrice,
  totalLeadPrice,
  children,
  expanded,
  toggleExpanded,
  className,
  queryParams,
  color,
  openExportsModal,
}) => {
  const adjustedNet = totalVendorPrice * 100 - totalLeadPrice * 100;
  const idBase =
    (displayName || description || name)
      ?.replace(/\s+/g, '')
      ?.replace(/[^a-zA-Z0-9]/g, '') || Math.floor(Math.random() * 10000);

  return (
    <ListGroupItem
      tag={Row}
      noGutters
      className={classnames('d-flex px-0', className)}
      color={color}
    >
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={1}
        className="d-flex flex-column align-items-lg-center align-items-md-start pl-lg-0 pl-md-4 pl-sm-4 pl-xs-4"
      >
        <div className="col d-flex flex-column align-items-center justify-content-center">
          <span className="h5">{displayName || description || name}</span>
          <small
            className={classnames({
              'd-none': displayName === 'Totals',
              'd-sm-none d-xs-none d-md-none d-lg-block':
                displayName !== 'Totals',
            })}
          >
            Name
          </small>
        </div>
      </Col>
      <Col xs={1} className="d-flex flex-column text-center">
        <span className="h5">{numberWithCommas(total)}</span>
        <small id={`rejected-${idBase}`}>Received</small>
        {/* <UncontrolledTooltip placement="bottom" target={`rejected-${idBase}`}>
          Received
        </UncontrolledTooltip> */}
      </Col>
      <Col xs={1} className="d-flex flex-column text-center">
        <span className="h5">{numberWithCommas(rejected)}</span>
        <small id={`rejected-${idBase}`}>Rejected</small>
        {/* <UncontrolledTooltip placement="bottom" target={`rejected-${idBase}`}>
          Dupes/DNC
        </UncontrolledTooltip> */}
      </Col>
      <Col xs={1} className="d-flex flex-column text-center">
        <span className="h5">{numberWithCommas(total - rejected - sold)}</span>
        <small id={`rejected-${idBase}`}>Unsold</small>
        {/* <UncontrolledTooltip placement="bottom" target={`rejected-${idBase}`}>
          Unsold
        </UncontrolledTooltip> */}
      </Col>
      <Col xs={1} className="d-flex flex-column text-center">
        <span id={`accepted-${idBase}`} className="h5 cursor-help">
          {numberWithCommas(sold)}
        </span>
        <small id={`accepted-tp-${idBase}`}>Sold</small>
        {/* <UncontrolledTooltip
          placement="bottom"
          target={`accepted-tp-${idBase}`}
        >
          Sold (Including Overflow)
        </UncontrolledTooltip> */}
        <SoldPopOver
          sold={sold}
          overflow={overflow}
          target={`accepted-${idBase}`}
        />
      </Col>
      <Col
        xs={2}
        sm={2}
        md={2}
        lg={1}
        className="d-flex flex-column text-center"
      >
        <span className="h5 text-danger">
          {formatCurrencyForDisplay(totalVendorPrice * 100)}
        </span>
        <small id={`cost-tp-${idBase}`} className="cursor-help">
          Total Cost
        </small>
        <UncontrolledTooltip placement="bottom" target={`cost-tp-${idBase}`}>
          ~{formatCurrencyForDisplay(vendorPrice * 100)} X {sold} Leads
        </UncontrolledTooltip>
      </Col>
      <Col
        xs={2}
        sm={2}
        md={2}
        lg={1}
        className="d-flex flex-column text-center"
      >
        <span className="h5 text-success">
          {formatCurrencyForDisplay(totalLeadPrice * 100)}
        </span>
        <small id={`sales-tp-${idBase}`} className="cursor-help">
          Total Sales
        </small>
        <UncontrolledTooltip placement="bottom" target={`sales-tp-${idBase}`}>
          ~{formatCurrencyForDisplay(leadPrice * 100)} X {sold - overflow} Leads
        </UncontrolledTooltip>
      </Col>
      <Col
        xs={2}
        sm={2}
        md={2}
        lg={1}
        className="d-flex flex-column text-center"
      >
        <span
          className={classnames(
            'h5',
            adjustedNet < 0 ? 'text-success' : 'text-danger'
          )}
        >
          {formatCurrencyForDisplay(Math.abs(adjustedNet))}
        </span>
        <small id={`adjusted-tp-${idBase}`} className="cursor-help">
          Adjusted Net
        </small>
        <UncontrolledTooltip
          placement="bottom"
          target={`adjusted-tp-${idBase}`}
        >
          ({formatCurrencyForDisplay(totalLeadPrice)} Sales) - (
          {formatCurrencyForDisplay(totalVendorPrice * 100)} Cost)
        </UncontrolledTooltip>
      </Col>
      <Col xs={1} className="d-flex flex-column text-center">
        <span id={`overflow-${idBase}`} className="h5 cursor-help">
          {Math.floor((overflow / sold) * 100) || 0}%
        </span>
        <small id={`overflow-tp-${idBase}`} className="cursor-help">
          Overflow
        </small>
        <UncontrolledTooltip
          placement="bottom"
          target={`overflow-tp-${idBase}`}
        >
          {overflow} Overflow{' '}
          <FaDivide style={{ fontSize: 10, paddingBottom: 2 }} /> {sold} Leads
        </UncontrolledTooltip>
        <SoldPopOver
          sold={sold}
          overflow={overflow}
          target={`overflow-${idBase}`}
        />
      </Col>
      <Col
        xs={1}
        className="d-flex flex-column text-center"
        title="Average Vendor Price"
      >
        <span className="h5 text-danger">
          {formatCurrencyForDisplay(vendorPrice * 100)}
        </span>
        <small id={`vendor-tp-${idBase}`} className="cursor-help">
          Vendor Price
        </small>
        <UncontrolledTooltip placement="bottom" target={`vendor-tp-${idBase}`}>
          Average Vendor Price
        </UncontrolledTooltip>
      </Col>
      <Col xs={1} className="d-flex flex-column text-center">
        <span className="h5 text-success">
          {formatCurrencyForDisplay(leadPrice * 100)}
        </span>
        <small id={`lead-price-${idBase}`} className="cursor-help">
          Lead Price
        </small>
        <UncontrolledTooltip placement="bottom" target={`lead-price-${idBase}`}>
          Average Sold Price (Overflow leads omitted)
        </UncontrolledTooltip>
      </Col>
      {/* <Col
        xs={1}
        className="flex-column text-center d-xs-none d-md-none d-lg-flex"
      >
        <span className="h5">$--.--</span>
        <small id={`acq-tp-${idBase}`} className="cursor-help">
          Acq. Cost
        </small>
        <UncontrolledTooltip placement="bottom" target={`acq-tp-${idBase}`}>
          Coming Soon
        </UncontrolledTooltip>
      </Col> */}
      <Col xs={1} className="d-flex align-items-center justify-content-center">
        {toggleExpanded && (
          <Button color="primary" onClick={() => toggleExpanded()}>
            {expanding ? (
              <Spinner size="sm" />
            ) : expanded ? (
              <FaCompress />
            ) : (
              <FaExpand />
            )}
          </Button>
        )}
        {openExportsModal && (
          <UncontrolledButtonDropdown className="ml-2">
            <DropdownToggle color="primary">
              <FaSortAmountDown />
            </DropdownToggle>
            <DropdownMenu>
              {/* <DropdownItem onClick={() => openExportsModal()}>
                Export
              </DropdownItem> */}
              <DropdownItem
                tag={Link}
                to={`/leads?${qs.stringify({
                  ...queryParams,
                  dt: queryParams.dt,
                  ls: 'sold',
                  fr:
                    typeof queryParams.fr === 'string'
                      ? queryParams.fr
                      : queryParams.fr.format('YYYY-MM-DD'),
                  to:
                    typeof queryParams.to === 'string'
                      ? queryParams.to
                      : queryParams.to.format('YYYY-MM-DD'),
                })}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Inspect Sold
              </DropdownItem>
              {queryParams.dt === 'created' ? (
                <>
                  <DropdownItem
                    tag={Link}
                    to={`/leads?${qs.stringify({
                      ...queryParams,
                      dt: 'created',
                      ls: 'unsold',
                      fr:
                        typeof queryParams.fr === 'string'
                          ? queryParams.fr
                          : queryParams.fr.format('YYYY-MM-DD'),
                      to:
                        typeof queryParams.to === 'string'
                          ? queryParams.to
                          : queryParams.to.format('YYYY-MM-DD'),
                    })}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Inspect Unsold
                  </DropdownItem>
                  <DropdownItem
                    tag={Link}
                    to={`/leads?${qs.stringify({
                      ...queryParams,
                      dt: 'created',
                      ls: 'all',
                      fr:
                        typeof queryParams.fr === 'string'
                          ? queryParams.fr
                          : queryParams.fr.format('YYYY-MM-DD'),
                      to:
                        typeof queryParams.to === 'string'
                          ? queryParams.to
                          : queryParams.to.format('YYYY-MM-DD'),
                    })}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Inspect All
                  </DropdownItem>
                </>
              ) : null}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        )}
      </Col>
      <Collapse isOpen={expanded} className="w-100">
        <ListGroup>
          <div className="border-top border-secondary mt-3">{children}</div>
        </ListGroup>
      </Collapse>
    </ListGroupItem>
  );
};

export default DashboardItem;
