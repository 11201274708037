import gql from 'graphql-tag';

export default gql`
  fragment LeadFragment on Lead {
    id
    __typename
    leadID
    agentID
    firstName
    lastName
    created
    email
    campaignName
    mpVendorCampaignName
    mpVendorCampaignID
    corpLeadID
    price
    soldDate
    orgPhone
    region
    vendorName
    crmContactID
    leadType
    result {
      icon
      status
    }
    isDuplicate
    isCorpDuplicate
    passDNC
    vendorLeadID
    createdEpoch
    agentEmail
    vendorPrice
    duplicateFromDate
    addedFromDate
    conferenceRecording
  }
`;
