import React, { useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, Route, Link } from 'react-router-dom';
import {
  Button,
  Container,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {
  FaArrowCircleLeft,
  FaCopy,
  FaCheck,
  FaTrash,
  FaLock,
  FaAddressBook,
} from 'react-icons/fa';
import SecondaryNav from '@/components/Nav/SecondaryNav';
import {
  AgentsConfirmDeleteModal,
  AgentsPasswordResetModal,
  AgentsChangeRegionModal,
} from '@/modals';
import access_obj from '@/utils/uiAccess';
import { usePageLoader, useAdmin, useNetwork } from '@/hooks';
import { CopyButton } from '@/components';
import AgentGeneralTab from './General';
import CRMProfiles from './CRMProfiles';
import useAgent from './useAgent';
import Agents from './Agents';
import Services from './Services';
import CampaignsTab from './Campaigns';

let navItems = [
  {
    label: 'General Information',
    path: 'general',
  },
  {
    label: 'Integrations',
    path: 'integrations',
  },
  {
    label: 'Campaigns',
    path: 'campaigns',
  },
  {
    label: 'Agents',
    path: 'agents',
  },
  {
    label: 'Services',
    path: 'services',
  },
];

const AgentDetailView: React.FC<RouteComponentProps<{
  id: string;
}>> = ({ history }) => {
  const { changeNetwork } = useNetwork();
  const [copied, setCopied] = useState(false);
  const { user, loading } = useAgent();
  const { role } = useAdmin();
  const { start, complete } = usePageLoader();

  useEffect(() => {
    if (loading) {
      start();
      return () => complete();
    }
    return () => {};
  }, [loading]);

  useEffect(() => {
    if (copied) {
      const tm = setTimeout(() => {
        setCopied(false);
      }, 1000);

      return () => clearTimeout(tm);
    }
    return () => {};
  }, [copied]);

  useEffect(() => {
    if (!user && !loading) {
      history.push('/agents');
    }
  }, [user, loading]);

  useEffect(() => {
    if (user?.network.id && user?.network.id !== user?.networkID) {
      changeNetwork(user.network.id, false);
    }
  }, [user]);

  const [disableModalOpen, setDisableModalOpen] = useState(false);
  const [pwResetModalOpen, setPwResetModalOpen] = useState(false);
  const [changeRegionModalOpen, setChangeRegionModalOpen] = useState(false);
  const togglePwResetModal = (): void => setPwResetModalOpen((o) => !o);
  const toggleDisableModal = (): void => setDisableModalOpen((o) => !o);
  const toggleChangeRegionModal = (): void =>
    setChangeRegionModalOpen((o) => !o);

  const route_map = {
    general: (
      <Route
        component={AgentGeneralTab}
        exact
        path="/agents/:id/general"
        key="agents-general"
      />
    ),
    campaigns: (
      <Route
        component={CampaignsTab}
        exact
        path="/agents/:id/campaigns"
        key="agent-campaigns"
      />
    ),
    integrations: (
      <Route
        component={CRMProfiles}
        exact
        path="/agents/:id/integrations"
        key="agent-integrations"
      />
    ),
    agents: (
      <Route
        component={Agents}
        exact
        path="/agents/:id/agents"
        key="agent-agents"
      />
    ),
    services: (
      <Route
        component={Services}
        exact
        path="/agents/:id/services"
        key="agent-services"
      />
    ),
    reset_password: (
      <DropdownItem
        onClick={togglePwResetModal}
        key="reset-password"
        className="d-flex align-items-center"
      >
        <FaLock className="mr-2" /> Reset Password
      </DropdownItem>
    ),
    change_region: (
      <DropdownItem
        key="change-region"
        onClick={toggleChangeRegionModal}
        className="d-flex align-items-center"
      >
        <FaAddressBook className="mr-2" /> Change Region
      </DropdownItem>
    ),
    delete_agent: (
      <DropdownItem
        onClick={toggleDisableModal}
        className="d-flex align-items-center"
        key="delete-agent"
      >
        <FaTrash className="mr-2" /> Delete Agent
      </DropdownItem>
    ),
  } as any;
  navItems = useMemo(
    () =>
      navItems.filter(({ path }) =>
        access_obj[role].agent.allowed.includes(path)
      ),
    [role]
  );

  const actions: any = useMemo(
    () => access_obj[role].agent.actions.map((item: any) => route_map[item]),
    [role]
  );

  const allowed = useMemo(
    () => access_obj[role].agent.allowed.map((page: any) => route_map[page]),
    [role]
  );

  return (
    <div className="d-flex flex-column flex-fill">
      <div className="py-3 px-3 border-bottom border-secondary d-flex align-items-center justify-content-between">
        <div className="flex-fill d-flex align-items-center">
          <Button color="secondary" onClick={() => history.push('/agents')}>
            <FaArrowCircleLeft />
          </Button>
          <h4 className="mb-0 ml-3 d-flex align-items-center">
            {user?.firstName} {user?.lastName}{' '}
            <span className="text-muted ml-3">UID #: {user?.agentID}</span>{' '}
            <CopyButton className="ml-3" content={user?.agentID!} />
          </h4>
        </div>
        <UncontrolledButtonDropdown id="agent-detail-actions-dropdown">
          <DropdownToggle color="secondary" caret>
            Actions
          </DropdownToggle>
          <DropdownMenu>{actions}</DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
      <div className="d-flex flex-fill">
        <SecondaryNav navItems={navItems} />
        {user && (
          <Container className="flex-fill d-flex flex-column my-5">
            {allowed}
          </Container>
        )}
      </div>
      {user && (
        <>
          <AgentsConfirmDeleteModal
            isOpen={disableModalOpen}
            toggle={toggleDisableModal}
            user={user!}
          />
          <AgentsPasswordResetModal
            isOpen={pwResetModalOpen}
            toggle={togglePwResetModal}
            user={user!}
          />
          <AgentsChangeRegionModal
            isOpen={changeRegionModalOpen}
            toggle={toggleChangeRegionModal}
            user={user!}
          />
        </>
      )}
    </div>
  );
};

export default AgentDetailView;
