import React, { useState } from 'react';
import { SelectField } from '@availity/select';
import { FaInfoCircle } from 'react-icons/fa';
import {
  Card,
  CardTitle,
  Row,
  Col,
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupText,
} from 'reactstrap';
import { MoneyField, SwitchButton } from '@/components';
import { useAdmin, useNetwork } from '@/hooks';
import { useQuery } from '@apollo/react-hooks';
import { Field, Input } from '@availity/form';
import { vendorManyQuery } from '@/graphql/queries';
import { useFormikContext } from 'formik';

const ConnectionCard: React.FC<{ isNew: boolean }> = ({ isNew }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { network } = useNetwork();
  const { data } = useQuery(vendorManyQuery, {
    variables: {
      networkID: network?.networkID,
    },
  });

  const { role } = useAdmin();

  const [refundDropdownOpen, setRefundDropdownOpen] = useState(false);
  const toggleRefundDropdown = (): void => setRefundDropdownOpen((o) => !o);

  const [refundIncrementOpen, setRefundIncrementOpen] = useState(false);
  const toggleIncremement = () => setRefundIncrementOpen((o) => !o);

  const [refundDateTypeOpen, setRefundDateTypeOpen] = useState(false);
  const toggleDateType = () => setRefundDateTypeOpen((o) => !o);

  return (
    <Card body className="mb-3">
      <CardTitle tag="h4" className="lead text-uppercase">
        Connect to a Lead Vendor
      </CardTitle>
      <Row noGutters>
        <Col xs={12}>
          <SelectField
            name="vendor"
            options={data?.vendorMany || []}
            labelKey="vendorName"
            valueKey="id"
            isDisabled={!isNew}
            raw
            getOptionLabel={({ vendorName, vendorID }) => (
              <>
                {vendorName} <small>({vendorID})</small>
              </>
            )}
            label="Vendor"
          />
        </Col>
        <Col xs={12}>
          <Field
            id="lead-source-description"
            name="description"
            label="Description"
            placeholder="Description..."
            type="text"
            helpMessage="Descriptive name for this lead source."
            disabled={!isNew}
          />
        </Col>
        <Col xs={5} id="lead-source-brokers">
          <SelectField
            name="brokerID"
            label="Lead Broker"
            labelKey="label"
            valueKey="value"
            options={
              network?.brokers?.map((i) => ({
                label: i.brokerName,
                value: i.brokerID,
              })) || []
            }
          />
        </Col>
        <Col xs={6} className="ml-5" id="lead-source-tags">
          <SelectField
            name="tags"
            label="Tags"
            labelKey="label"
            valueKey="value"
            placeholder="Select Tags"
            options={network?.tags?.map((i) => ({ label: i, value: i })) || []}
            isMulti
            isClearable
          />
        </Col>
      </Row>
      <Row>
        <Col xs={3} id="lead-source-vertical-type">
          <SelectField
            name="verticalType"
            label="Vertical Type"
            labelKey="label"
            valueKey="value"
            options={
              network?.verticalTypes?.map((i) => ({
                label: i[0].toUpperCase() + i.slice(1),
                value: i,
              })) || []
            }
          />
        </Col>
        <Col xs={3} className="ml-5" id="lead-source-kind-of-lead">
          <SelectField
            name="kindOfLead"
            label="Type of Lead"
            labelKey="label"
            valueKey="value"
            options={
              network?.leadTypes?.map((i) => ({
                label: i[0].toUpperCase() + i.slice(1),
                value: i,
              })) || []
            }
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs={2}>
          <CardTitle tag="h4" className="lead text-uppercase">
            Live Transfers
          </CardTitle>
          <div id="live-transfers-switch-button">
            <SwitchButton
              width={100}
              checked={!!values.liveTransfersEnabled}
              onlabel="Enabled"
              offlabel="Disabled"
              onstyle="success"
              offstyle="danger"
              onChange={() =>
                setFieldValue(
                  'liveTransfersEnabled',
                  !values.liveTransfersEnabled
                )
              }
            />
          </div>
        </Col>

        <Col xs={{ size: 6 }} className="ml-auto">
          <FormGroup>
            <Label tag="h4" className="lead text-uppercase">
              Refunds
            </Label>
            <InputGroup>
              <InputGroupButtonDropdown
                toggle={toggleRefundDropdown}
                isOpen={refundDropdownOpen}
                addonType="append"
              >
                <DropdownToggle id="refunds-dropdown-toggle" caret>
                  {values.refundEligible === true ? 'Allow' : 'Deny'}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    id="refunds-allow-dropdown-item"
                    active={values.refundEligible === true}
                    onClick={() => setFieldValue('refundEligible', true)}
                  >
                    Allow
                  </DropdownItem>
                  <DropdownItem
                    id="refunds-deny-dropdown-item"
                    active={values.refundEligible === false}
                    onClick={() => setFieldValue('refundEligible', false)}
                  >
                    Deny
                  </DropdownItem>
                </DropdownMenu>
              </InputGroupButtonDropdown>
              <InputGroupAddon addonType="append">
                <InputGroupText className="border-0">
                  Refunds {values.refundEligible === true ? 'for' : ''}
                </InputGroupText>
              </InputGroupAddon>
              <Input
                id="lead-source-refund-max-age"
                name="refundMaxAge"
                type="number"
                hidden={!values.refundEligible}
              />
              {values.refundEligible && (
                <>
                  <InputGroupButtonDropdown
                    addonType="append"
                    isOpen={refundIncrementOpen}
                    toggle={() => toggleIncremement()}
                  >
                    <DropdownToggle id="refund-time-dropdown-toggle" caret>
                      {values.refundMaxAgeIncrement}
                      {values.refundMaxAge !== 1 ? 's' : ''}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        id="refund-minute-dropdown-item"
                        onClick={() =>
                          setFieldValue('refundMaxAgeIncrement', 'minute')
                        }
                      >
                        minute{values.refundMaxAge !== 1 ? 's' : ''}
                      </DropdownItem>
                      <DropdownItem
                        id="refund-hour-dropdown-item"
                        onClick={() =>
                          setFieldValue('refundMaxAgeIncrement', 'hour')
                        }
                      >
                        hour{values.refundMaxAge !== 1 ? 's' : ''}
                      </DropdownItem>
                      <DropdownItem
                        id="refund-day-dropdown-item"
                        onClick={() =>
                          setFieldValue('refundMaxAgeIncrement', 'day')
                        }
                      >
                        day{values.refundMaxAge !== 1 ? 's' : ''}
                      </DropdownItem>
                    </DropdownMenu>
                  </InputGroupButtonDropdown>
                  <InputGroupAddon addonType="append">
                    <InputGroupText className="border-0">
                      after lead was
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputGroupButtonDropdown
                    addonType="append"
                    isOpen={refundDateTypeOpen}
                    toggle={() => toggleDateType()}
                  >
                    <DropdownToggle
                      id="refund-sold-created-dropdown-toggle"
                      caret
                    >
                      {values.refundMaxAgeDateType === 'created'
                        ? 'Created'
                        : 'Sold'}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        id="refund-created-dropdown-item"
                        onClick={() =>
                          setFieldValue('refundMaxAgeDateType', 'created')
                        }
                      >
                        Created
                      </DropdownItem>
                      <DropdownItem
                        id="refund-sold-dropdown-item"
                        onClick={() =>
                          setFieldValue('refundMaxAgeDateType', 'soldDate')
                        }
                      >
                        Sold
                      </DropdownItem>
                    </DropdownMenu>
                  </InputGroupButtonDropdown>
                </>
              )}
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    </Card>
  );
};

export default ConnectionCard;
