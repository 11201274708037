import gql from 'graphql-tag';

export default gql`
  query getConvosoAgentID {
    attributes {
      attributes {
        __typename
        convosoAgentID
      }
    }
  }
`;
