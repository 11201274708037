import React from 'react';
import { FaCheckCircle, FaSnowflake, FaBug } from 'react-icons/fa';
import { Spinner, Badge } from 'reactstrap';

export const deploymentStatusMapping = (status: string) => {
  switch (status) {
    case 'FAILED':
      return <FaBug size={30} title="failed" className="text-danger" />;
    case 'SUCCEEDED':
    case 'COMPLETED':
      return (
        <FaCheckCircle size={30} title={status} className="text-success" />
      );
    case 'PENDING':
      return <Spinner size={30} title="pending" color="warning" />;
    default:
      return <FaBug size={30} title={status} className="text-danger" />;
  }
};
export const serverStatusMapping = (status: string) => {
  switch (status) {
    case 'PAUSED':
      return <FaSnowflake title="paused" size={30} className="text-primary" />;
    case 'ACTIVE':
      return (
        <FaCheckCircle title="active" size={30} className="text-success" />
      );
    case 'ERROR':
      return <FaBug title="error" size={30} className="text-danger" />;
    default:
      return <FaBug title={status} size={30} className="text-danger" />;
  }
};

export const latestBadge = (
  <Badge
    style={{
      fontSize: '.9rem',
    }}
    color="primary"
    title="Queue is running latest version"
    className="ml-2"
  >
    Latest
  </Badge>
);
