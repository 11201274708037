import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Checkbox, CheckboxGroup, Field } from '@availity/form';
import classNames from 'classnames';
import { attributesQuery } from '@/graphql/queries';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import { useQuery } from 'react-apollo';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroupItem,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { FaPlus, FaQuestionCircle, FaTimes } from 'react-icons/fa';
import { AttributesContext } from '@/context';

interface AttributeColumnProps {
  attribute: any;
  selectedAttributes: string[];
  errors: any;
}

const AttributeColumn: React.FC<AttributeColumnProps> = ({
  attribute,
  selectedAttributes,
  errors,
}) => {
  const { setFieldValue } = useFormikContext();
  const [{ value: filters }] = useField<any[]>('filters');
  const a = attribute.internalAttribute;
  const isChecked = selectedAttributes?.includes(a);
  const idPrefix = `attribute-${attribute.sourceAttribute}`;

  const isDisabled = useMemo(() => {
    const filter = filters.find((f) => {
      if (f.skipMissing) {
        return false;
      }
      if (f.attribute === 'povertyLevel') {
        return ['household', 'income']?.includes(a);
      }
      if (f.attribute === 'tcpa') {
        return [
          'jornayaLeadID',
          'trustedFormCertID',
          'trustedFormLink',
        ]?.includes(a);
      }

      return f.attribute === a;
    });

    return !!filter;
  }, [filters, a]);

  const noIntRangeButton =
    !isChecked || attribute.attributeType !== 'integerRange';
  const attRangeName = attribute?.sourceAttribute?.replace('Range', '');

  const attRangeErrors = !!(
    errors?.attributeRanges?.[attribute.sourceAttribute]?.lowestBound ||
    errors?.attributeRanges?.[attribute.sourceAttribute]?.highestBound
  );

  return (
    <ListGroupItem
      className="d-flex justify-content-between align-items-center text-white"
      style={{ ...(!noIntRangeButton && { padding: '5px 20px' }) }}
    >
      <Checkbox
        className="text-white"
        disabled={isDisabled && isChecked}
        checked={isChecked}
        name={attribute.sourceAttribute}
        value={attribute.internalAttribute}
        label={attribute.sourceAttribute}
        id={`${idPrefix}-checkbox`}
      />
      {attribute.sourceAttribute.includes('Range') && (
        <small className="text-danger ml-2">
          <ErrorMessage name={`attributeRanges.${attRangeName}.nextSegment`} />{' '}
          <ErrorMessage name={`attributeRanges.${attRangeName}.lowestBound`} />{' '}
          <ErrorMessage name={`attributeRanges.${attRangeName}.highestBound`} />
        </small>
      )}
      <UncontrolledDropdown
        className={classNames('text-primary', {
          'd-none': noIntRangeButton,
        })}
      >
        <DropdownToggle id={`${idPrefix}-range-dropdown-toggle`}>
          <FaPlus />
        </DropdownToggle>
        <DropdownMenu className="pt-3 px-3" style={{ width: 260, left: -218 }}>
          <Field
            id={`${idPrefix}-range-next-segment`}
            name={`attributeRanges.${attRangeName}.nextSegment`}
            type="number"
            label={
              <>
                <span>Range </span>
                <small className="text-muted">
                  (Range = 5, ageRange: 20-24)
                </small>
                <FaTimes
                  id={`${idPrefix}-range-next-segment-clear-button`}
                  className="cursor-pointer ml-2 text-danger"
                  onClick={() =>
                    setFieldValue(
                      `attributeRanges.${attRangeName}.nextSegment`,
                      ''
                    )
                  }
                />
              </>
            }
          />

          <Field
            id={`${idPrefix}-range-lowest-bound`}
            name={`attributeRanges.${attRangeName}.lowestBound`}
            label={
              <>
                Range - Lowest Bound
                <FaTimes
                  id={`${idPrefix}-range-lowest-bound-clear-button`}
                  className="cursor-pointer ml-2 text-danger"
                  onClick={() =>
                    setFieldValue(
                      `attributeRanges.${attRangeName}.lowestBound`,
                      ''
                    )
                  }
                />
              </>
            }
            type="number"
          />
          <Field
            id={`${idPrefix}-range-highest-bound`}
            name={`attributeRanges.${attRangeName}.highestBound`}
            label={
              <>
                Range - Highest Bound
                <FaTimes
                  id={`${idPrefix}-range-highest-bound-clear-button`}
                  className="cursor-pointer ml-2 text-danger"
                  onClick={() =>
                    setFieldValue(
                      `attributeRanges.${attRangeName}.highestBound`,
                      ''
                    )
                  }
                />
              </>
            }
            type="number"
          />
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledTooltip
        placement="left"
        target={`${idPrefix}-range-dropdown-toggle`}
        style={{ textAlign: 'left' }}
      >
        Use the dropdown to set the range options for the field. If no options
        are selected, the defaults will be use.
      </UncontrolledTooltip>
      <small
        className={classNames('text-danger ml-2', {
          'd-none': attRangeErrors || !isDisabled,
        })}
      >
        {isChecked ? 'Cannot Skip Attribute' : ''}
      </small>
    </ListGroupItem>
  );
};

const AttributesTab: React.FC = () => {
  const { data, loading } = useQuery(attributesQuery);
  const [type, setType] = useState('Post');
  const {
    pingAttributes: standardPingAttributes,
    postAttributes: standardPostAttributes,
  } = useContext(AttributesContext);

  const attributes =
    type === 'Post'
      ? standardPostAttributes || []
      : standardPingAttributes || [];

  const [{ value: postAttributes }, , { setValue: setPost }] = useField(
    'postAttributes'
  );
  const [{ value: pingAttributes }, , { setValue: setPing }] = useField(
    'pingAttributes'
  );
  const [{ value: apiType }] = useField('apiType');

  const { errors } = useFormikContext();

  useEffect(() => {
    if (apiType === 'pingPost') setType('Ping');
  }, [apiType]);

  if (loading && !data) {
    return null;
  }

  return (
    <div>
      <Card className="mt-3 ">
        <CardHeader className="d-flex align-items-center ">
          <CardTitle
            tag="h4"
            className="my-2 text-uppercase d-flex align-items-center"
          >
            Attributes{' '}
            <FaQuestionCircle
              className="ml-2 cursor-pointer text-primary"
              id="attributes-tooltip"
            />
            <UncontrolledTooltip
              placement="right"
              target="attributes-tooltip"
              style={{ textAlign: 'left' }}
            >
              Check any attributes that are required, all unchecked attributes
              are considered optional. To set an integer range, please check the
              desired range attribute and then follow the dropdown instructions.
            </UncontrolledTooltip>
          </CardTitle>
          <Button
            className="ml-auto mr-2"
            onClick={() => {
              if (apiType === 'pingPost' && type !== 'Post') {
                setPing([]);
              } else {
                setPost([]);
              }
            }}
          >
            Reset
          </Button>
          <UncontrolledDropdown>
            <DropdownToggle id="attribute-type-dropdown-toggle" caret>
              {apiType === 'pingPost' && type === 'Post' ? 'Submit' : type}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                active={type === 'Post'}
                onClick={() => setType('Post')}
              >
                {apiType === 'pingPost' ? 'Submit' : 'Post'}
              </DropdownItem>
              {apiType === 'pingPost' && (
                <DropdownItem
                  active={type === 'Ping'}
                  onClick={() => setType('Ping')}
                >
                  Ping
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
          {apiType === 'pingPost' && (
            <UncontrolledTooltip
              placement="left"
              target="attribute-type-dropdown-toggle"
            >
              <div className="">
                <span className="text-warning">Please Note: </span>
                <span>
                  Required attribute lists for Ping posts and Submit posts are
                  separate. Please review both lists to ensure they reflect your
                  desired selections for each type of post.
                </span>
              </div>
            </UncontrolledTooltip>
          )}
        </CardHeader>
        <CardBody className="py-4 px-5">
          <CheckboxGroup
            name={type === 'Post' ? 'postAttributes' : 'pingAttributes'}
            for={type === 'Post' ? 'postAttributes' : 'pingAttributes'}
            groupClassName="bg-dark rounded-0"
          >
            <Row id="attribute-list-row">
              {attributes
                .sort((a, b) => {
                  if (
                    a.sourceAttribute.toLowerCase() <
                    b.sourceAttribute.toLowerCase()
                  ) {
                    return -1;
                  }
                  if (
                    a.sourceAttribute.toLowerCase() >
                    b.sourceAttribute.toLowerCase()
                  ) {
                    return 1;
                  }
                  return 0;
                })
                .map((attribute: any) => (
                  <Col
                    xs={4}
                    className="m-0 px-0"
                    key={attribute.sourceAttribute}
                    id={`attribute-list-col-${attribute.sourceAttribute}-${
                      attribute.attributeType
                        ? attribute.attributeType
                        : 'string'
                    }`}
                  >
                    <AttributeColumn
                      attribute={attribute}
                      selectedAttributes={
                        type === 'Post' ? postAttributes : pingAttributes
                      }
                      errors={errors}
                    />
                  </Col>
                ))}
            </Row>
          </CheckboxGroup>
        </CardBody>
      </Card>
    </div>
  );
};

export default AttributesTab;
