/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { Route } from 'react-router-dom';
import {
  Container,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { useAdmin } from '@/hooks';
import { CopyButton } from '@/components';
import { RouteComponentProps, useRouteMatch } from 'react-router';
import { useQuery } from 'react-apollo';
import { vendorNode } from '@/graphql/queries';

import access_obj from '@/utils/uiAccess';
import TertiaryNav from '@/components/Nav/TertiaryNav';
import VendorsGeneralTab from './General';

// eslint-disable-next-line prefer-const
let navItems = [
  {
    label: 'General Information',
    path: 'general',
  },
];

const VendorsDetailView: React.FC<RouteComponentProps> = ({ history }) => {
  const match = useRouteMatch<{
    id: string;
  }>('/vendors/:id');

  const { data } = useQuery(vendorNode, {
    variables: {
      id: match!.params.id,
    },
  });
  const vendor = data?.node;
  const { role } = useAdmin();

  const route_map = {
    general: (
      <Route
        component={VendorsGeneralTab}
        exact
        path="/vendors/:id/general"
        key="vendors-general"
      />
    ),
  } as any;

  const allowed = useMemo(
    () => access_obj[role].vendor.allowed.map((page: any) => route_map[page]),
    [role]
  );
  return (
    <>
      <div className="d-flex flex-column flex-fill">
        <div className="py-3 px-3 border-bottom border-secondary d-flex align-items-center justify-content-between">
          <div className="flex-fill d-flex align-items-center">
            <Button color="secondary" onClick={() => history.push('/vendors')}>
              <FaArrowCircleLeft />
            </Button>
            <h4 className="mb-0 ml-3 d-flex align-items-center">
              {vendor?.vendorName}{' '}
              <span className="text-muted ml-3">
                Vendor ID: {vendor?.vendorID}
              </span>{' '}
              <CopyButton className="ml-3" content={vendor?.vendorID!} />
            </h4>
          </div>
        </div>
        <div className="d-flex flex-fill">
          <TertiaryNav
            navItems={navItems}
            basePath="vendors"
            extendedPath="/:id/:path"
          />
          <Container className="flex-fill d-flex flex-column my-5">
            {allowed}
          </Container>
        </div>
      </div>
    </>
  );
};

export default VendorsDetailView;
