import React, { useState } from 'react';
import {
  Card,
  CardTitle,
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { Input } from '@availity/form';
import { FaTrash } from 'react-icons/fa';
import { useField, useFormikContext } from 'formik';
import { ButtonToggle } from '@/components';
import { useRequiredAttributes } from '@/hooks';

interface TimeFilterProps {
  attribute: 'postalCode' | 'region';
  bid?: number;
  bidType?: 'percent' | 'dollars';
  rejectType: 'reject' | 'modify-bid';
  index: number;
  min: number;
  max: number;
  include: boolean;
  remove: () => void;
  className?: string;
}

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const minutesToTime = (minutes: number): string => {
  // Remove additional days

  minutes %= 1440;

  let hours = Math.floor(minutes / 60);
  minutes %= 60;

  if (hours < 10) {
    hours = `0${hours}`;
  }

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  return `${hours}:${minutes}`;
};

export const timeToMinutes = (timeString: string) => {
  const [hours, minutes] = timeString.split(':').map((s) => parseInt(s, 10));

  if (Number.isNaN(hours)) {
    return 0;
  }
  return hours * 60 + (Number.isNaN(minutes) ? 0 : minutes);
};

const TimeFilter: React.FC<TimeFilterProps> = ({
  attribute,
  index,
  remove,
  className,
  min,
  max,
  include,
  rejectType,
  bid,
  bidType,
}) => {
  const { setFieldValue, values } = useFormikContext<any>();
  const [includeOpen, setIncludeOpen] = useState(false);
  const [rejectTypeOpen, setRejectTypeOpen] = useState(false);
  const [bidTypeOpen, setBidTypeOpen] = useState(false);

  const [{ value: skipMissing }, , { setValue: setSkipMissing }] = useField(
    `filters.${index}.skipMissing`
  );

  useRequiredAttributes([attribute], !skipMissing);

  return (
    <Col xs={12} className={className}>
      <Card body className="h-100">
        <CardTitle
          tag="h4"
          className="lead text-uppercase d-flex justify-content-between align-items-center"
        >
          <span>
            {capitalize(attribute)} Time Filter
            <small className="text-muted ml-2">
              (Time calculated based on attribute)
            </small>
          </span>
          <FaTrash
            className="text-danger cursor-pointer"
            onClick={remove}
            id={`${attribute}-time-filter-trash-button`}
          />
        </CardTitle>
        <Row
          noGutters
          id={`${attribute}-time-filter-row`}
          className="align-items-center"
        >
          <Col>
            <FormGroup className="mt-3">
              <InputGroup>
                <InputGroupButtonDropdown
                  id={`${attribute}-time-filter-group-dropdown`}
                  addonType="prepend"
                  isOpen={includeOpen}
                  toggle={() => setIncludeOpen((o) => !o)}
                >
                  <DropdownToggle
                    id={`${attribute}-time-filter-dropdown-toggle`}
                    caret
                  >
                    {include ? 'Accept' : 'Reject'}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      id={`${attribute}-time-filter-dropdown-accept`}
                      active={include === true}
                      onClick={() =>
                        setFieldValue(`filters.${index}.include`, true)
                      }
                    >
                      Accept
                    </DropdownItem>
                    <DropdownItem
                      id={`${attribute}-time-filter-dropdown-reject`}
                      active={include === false}
                      onClick={() =>
                        setFieldValue(`filters.${index}.include`, false)
                      }
                    >
                      Reject
                    </DropdownItem>
                  </DropdownMenu>
                </InputGroupButtonDropdown>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="border-0">
                    leads from
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  id={`${attribute}-time-filter-min`}
                  size="md"
                  name={`filters.${index}.min`}
                  onChange={(e) =>
                    setFieldValue(
                      `filters.${index}.min`,
                      timeToMinutes(e.target.value)
                    )
                  }
                  type="time"
                  placeholder="0"
                  value={minutesToTime(min)}
                  style={{ maxWidth: 130 }}
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="border-0">to</InputGroupText>
                </InputGroupAddon>
                <Input
                  id={`${attribute}-time-filter-max`}
                  size="md"
                  name={`filters.${index}.max`}
                  onChange={(e) =>
                    setFieldValue(
                      `filters.${index}.max`,
                      timeToMinutes(e.target.value)
                    )
                  }
                  type="time"
                  placeholder="0"
                  value={minutesToTime(max)}
                  style={{ maxWidth: 130 }}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText className="border-0">
                    using Postal Code
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs={3}>
            <FormGroup className="mb-0">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="border-0">
                    When filtered
                  </InputGroupText>
                </InputGroupAddon>
                <InputGroupButtonDropdown
                  id={`${attribute}-time-filter-dropdown-bid`}
                  addonType="prepend"
                  isOpen={rejectTypeOpen}
                  toggle={() => setRejectTypeOpen((o) => !o)}
                >
                  <DropdownToggle
                    id={`${attribute}-time-filter-dropdown-bid-toggle`}
                    caret
                  >
                    {rejectType === 'modify-bid' ? 'Modify Bid' : 'Reject'}
                  </DropdownToggle>
                  <DropdownMenu>
                    {values.apiType === 'pingPost' &&
                      values.pingPostType === 'fixed' && (
                        <DropdownItem
                          id={`${values.apiType}-${attribute}-time-filter-dropdown-bid-item`}
                          active={rejectType === 'modify-bid'}
                          onClick={() =>
                            setFieldValue(
                              `filters.${index}.rejectType`,
                              'modify-bid'
                            )
                          }
                        >
                          Modify Bid
                        </DropdownItem>
                      )}
                    <DropdownItem
                      id={`${attribute}-time-filter-dropdown-bid-reject`}
                      active={rejectType === 'reject'}
                      onClick={() =>
                        setFieldValue(`filters.${index}.rejectType`, 'reject')
                      }
                    >
                      Reject
                    </DropdownItem>
                  </DropdownMenu>
                </InputGroupButtonDropdown>
                {rejectType === 'modify-bid' && (
                  <>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="border-0">by</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      id={`${attribute}-time-filter-input-bid`}
                      size="md"
                      name={`filters.${index}.bid`}
                      type="number"
                      placeholder="0"
                    />
                    <InputGroupButtonDropdown
                      id={`${attribute}-time-filter-group-dropdown-bid`}
                      addonType="prepend"
                      isOpen={bidTypeOpen}
                      toggle={() => setBidTypeOpen((o) => !o)}
                    >
                      <DropdownToggle
                        id={`${attribute}-time-filter-dropdown-bid-toggle-button`}
                        caret
                      >
                        {bidType === 'percent' ? 'Percent' : 'Dollars'}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          id={`${attribute}-time-filter-group-dropdown-bid-percent`}
                          active={bidType === 'percent'}
                          onClick={() =>
                            setFieldValue(`filters.${index}.bidType`, 'percent')
                          }
                        >
                          % Percent
                        </DropdownItem>
                        {/* <DropdownItem
                          active={rejectType === 'dollars'}
                          onClick={() =>
                            setFieldValue(`filters.${index}.bidType`, 'dollars')
                          }
                        >
                          $ Dollars
                        </DropdownItem> */}
                      </DropdownMenu>
                    </InputGroupButtonDropdown>
                  </>
                )}
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs={2} style={{ paddingBottom: 30 }}>
            <div className="mb-2">Skip if Missing</div>
            <ButtonToggle
              key={`${attribute}-skip-missing`}
              active={skipMissing}
              onToggle={(value: any) => setSkipMissing(value)}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default TimeFilter;
