import React, { useState } from 'react';
import { Input } from '@availity/form';
import { ButtonToggle, LabelWithToolTip } from '@/components';
import { ErrorMessage, useFormikContext } from 'formik';
import {
  Card,
  CardTitle,
  Row,
  FormGroup,
  Label,
  InputGroupAddon,
  InputGroup,
  Col,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  InputGroupButtonDropdown,
  InputGroupText,
} from 'reactstrap';
import {
  EarlyHangUpLeadSourceSelect,
  LiveTransferMp3Upload,
  LiveTransferPriceTierSelect,
  PhoneDropdown,
} from './components';

const getActions = (values: any) => {
  if (values.liveTransferOverflowRedirectCall) {
    return 'forward call';
  }
  if (values.liveTransferDropCall) {
    return 'drop call';
  }
  return 'send voicemail recording';
};

const LiveTransfersTab: React.FC = () => {
  const [openOverflowAction, setOpenOverflowAction] = useState(false);
  const toggleOpenOverflowAction = (): void => setOpenOverflowAction((o) => !o);

  const [
    openOverflowSecondsConversion,
    setOpenOverflowSecondsConversion,
  ] = useState(false);
  const toggleOpenOverflowSecondsConversion = (): void =>
    setOpenOverflowSecondsConversion((o) => !o);

  const [openDataOverflowAction, setOpenDataOverflowAction] = useState(false);
  const toggleDataOpenOverflowAction = (): void =>
    setOpenDataOverflowAction((o) => !o);

  const { values, setFieldValue } = useFormikContext<{
    vendor: { vendorID: string };
    liveTransferIntroGreeting: boolean;
    liveTransferDoNotOverflow: boolean;
    liveTransferDropCall: boolean;
    liveTransferOverflowRedirectCall: boolean;
    liveTransferRejectNoDataCalls: boolean;
    liveTransferTierID: string;
    liveTransferTimeConversion: string;
    liveTransferDelaySecondsForCall: number;
    liveTransferNotificationsOnly: boolean;
    liveTransferEarlyHangUpLayerID: string;
  }>();

  const toggleRejectNoDataCallSwitch = () => {
    setFieldValue(
      'liveTransferRejectNoDataCalls',
      !values.liveTransferRejectNoDataCalls
    );
  };
  const toggleLiveTransferNotificationsOnlySwitch = () => {
    setFieldValue(
      'liveTransferNotificationsOnly',
      !values.liveTransferNotificationsOnly
    );
  };
  const toggleLiveTransferIntroGreetingSwitch = () => {
    setFieldValue(
      'liveTransferIntroGreeting',
      !values.liveTransferIntroGreeting
    );
  };
  return (
    <>
      <Card body className="my-3">
        <CardTitle tag="h4" className="lead text-uppercase">
          Live Transfer Details
        </CardTitle>
        <Row>
          <Col xs={{ size: 3, offset: 2 }}>
            <LiveTransferPriceTierSelect
              priceTierID={values.liveTransferTierID}
              name="liveTransferTierID"
              label="Assigned Price Tier"
            />
          </Col>
          <Col xs={{ size: 3, offset: 1 }}>
            <LabelWithToolTip
              name="liveTransferRejectNoDataCalls"
              label="Reject No Data Calls"
              helpMessage="If enabled, all calls with no data available will be rejected."
            />
            <FormGroup className="d-flex flex-column">
              <ButtonToggle
                active={values.liveTransferRejectNoDataCalls}
                onToggle={() => toggleRejectNoDataCallSwitch()}
              />
            </FormGroup>
          </Col>
          <Col xs={{ size: 3, offset: 0 }}>
            <LabelWithToolTip
              name="liveTransferNotificationsOnly"
              label="Notifications Only?"
              helpMessage="If enabled, you will be able to answer incoming live transfer calls in the browser"
            />
            <FormGroup className="d-flex flex-column">
              <ButtonToggle
                active={values.liveTransferNotificationsOnly}
                onToggle={() => toggleLiveTransferNotificationsOnlySwitch()}
              />
            </FormGroup>
          </Col>
          <Col xs={{ size: 8, offset: 2 }}>
            <EarlyHangUpLeadSourceSelect
              name="liveTransferEarlyHangUpLayerID"
              label="Early Hangup Lead Source"
              vendorID={values.vendor?.vendorID}
            />
          </Col>
          <Col xs={{ size: 8, offset: 2 }}>
            <FormGroup>
              <Label>Call Flow </Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">Cycle</InputGroupAddon>
                <Input name="liveTransferBatchCycleCount" type="number" />
                <InputGroupAddon addonType="prepend">
                  times and notify
                </InputGroupAddon>
                <Input name="liveTransferBatchSize" type="number" />
                <InputGroupAddon addonType="prepend">
                  agents every
                </InputGroupAddon>
                <Input name="liveTransferAnswerTime" type="number" />
                <InputGroupAddon addonType="append">seconds</InputGroupAddon>
              </InputGroup>
              <ErrorMessage
                name="liveTransferBatchCycleCount"
                component={(props) => (
                  <div {...props} className="text-danger" />
                )}
              />
              <ErrorMessage
                name="liveTransferBatchSize"
                component={(props) => (
                  <div {...props} className="text-danger" />
                )}
              />
              <ErrorMessage
                name="liveTransferAnswerTime"
                component={(props) => (
                  <div {...props} className="text-danger" />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center my-3">
          <Col xs={8}>
            <FormGroup>
              <Label>Voice Overflow</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="border-0">
                    When no active agents
                  </InputGroupText>
                </InputGroupAddon>
                <InputGroupButtonDropdown
                  addonType="append"
                  isOpen={openOverflowAction}
                  toggle={() => toggleOpenOverflowAction()}
                >
                  <DropdownToggle caret style={{ zIndex: 'auto' }}>
                    {getActions(values)}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => {
                        setFieldValue('liveTransferDropCall', false);
                        setFieldValue(
                          'liveTransferOverflowRedirectCall',
                          false
                        );
                      }}
                    >
                      send voicemail recording
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setFieldValue('liveTransferDropCall', false);
                        setFieldValue('liveTransferOverflowRedirectCall', true);
                      }}
                    >
                      forward call
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setFieldValue(
                          'liveTransferOverflowRedirectCall',
                          false
                        );
                        setFieldValue('liveTransferDropCall', true);
                      }}
                    >
                      drop call
                    </DropdownItem>
                  </DropdownMenu>
                </InputGroupButtonDropdown>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText
                    className="border-0"
                    hidden={values.liveTransferDropCall}
                  >
                    to
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="liveTransferEmail"
                  type="text"
                  hidden={
                    values.liveTransferOverflowRedirectCall ||
                    values.liveTransferDropCall
                  }
                />

                <Input
                  name="liveTransferVoip"
                  type="text"
                  hidden={!values.liveTransferOverflowRedirectCall}
                  placeholder="No dashes, no parenthesis"
                />
              </InputGroup>
            </FormGroup>
            <ErrorMessage
              name="liveTransferEmail"
              component={(props) => <div {...props} className="text-danger" />}
            />
            <ErrorMessage
              name="liveTransferVoip"
              component={(props) => <div {...props} className="text-danger" />}
            />
          </Col>
        </Row>
        <Row className="justify-content-center ">
          <Col xs={8}>
            <FormGroup>
              <Label>Data Overflow </Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="border-0">
                    When lead data is available
                  </InputGroupText>
                </InputGroupAddon>
                <InputGroupButtonDropdown
                  addonType="append"
                  type="number"
                  isOpen={openDataOverflowAction}
                  toggle={() => toggleDataOpenOverflowAction()}
                >
                  <DropdownToggle caret style={{ zIndex: 'auto' }}>
                    {values.liveTransferDoNotOverflow
                      ? 'do not overflow.'
                      : 'wait'}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => {
                        setFieldValue('liveTransferDoNotOverflow', false);
                      }}
                    >
                      wait
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setFieldValue('liveTransferDoNotOverflow', true);
                      }}
                    >
                      do not overflow.
                    </DropdownItem>
                  </DropdownMenu>
                </InputGroupButtonDropdown>
                <Input
                  name="liveTransferDelaySecondsForCall"
                  type="number"
                  hidden={values.liveTransferDoNotOverflow}
                />
                <InputGroupButtonDropdown
                  addonType="append"
                  type="number"
                  isOpen={openOverflowSecondsConversion}
                  toggle={() => toggleOpenOverflowSecondsConversion()}
                  hidden={values.liveTransferDoNotOverflow}
                >
                  <DropdownToggle caret style={{ zIndex: 'auto' }}>
                    {values.liveTransferTimeConversion}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => {
                        if (values.liveTransferDelaySecondsForCall === 1) {
                          setFieldValue('liveTransferTimeConversion', 'second');
                        } else {
                          setFieldValue(
                            'liveTransferTimeConversion',
                            'seconds'
                          );
                        }
                      }}
                    >
                      seconds
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        if (values.liveTransferDelaySecondsForCall === 1) {
                          setFieldValue('liveTransferTimeConversion', 'minute');
                        } else {
                          setFieldValue(
                            'liveTransferTimeConversion',
                            'minutes'
                          );
                        }
                      }}
                    >
                      minutes
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        if (values.liveTransferDelaySecondsForCall === 1) {
                          setFieldValue('liveTransferTimeConversion', 'hour');
                        } else {
                          setFieldValue('liveTransferTimeConversion', 'hours');
                        }
                      }}
                    >
                      hours
                    </DropdownItem>
                  </DropdownMenu>
                </InputGroupButtonDropdown>
                <InputGroupAddon
                  addonType="append"
                  hidden={values.liveTransferDoNotOverflow}
                >
                  <InputGroupText className="border-0">
                    for a call.
                  </InputGroupText>
                </InputGroupAddon>
                <ErrorMessage
                  name="liveTransferDelaySecondsForCall"
                  component={(props) => (
                    <div {...props} className="text-danger" />
                  )}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </Card>
      <Card body className="my-3">
        <CardTitle tag="h4" className="lead text-uppercase">
          Voice Call Settings
        </CardTitle>
        <Row className="justify-content-center my-3">
          <Col xs={4}>
            <PhoneDropdown />
          </Col>
          <Col xs={{ size: 3, offset: 1 }}>
            <LabelWithToolTip
              name="liveTransferIntroGreeting"
              label="Play Intro Greeting?"
              helpMessage="If enabled, agent will hear 'Prepare to Connect' greeting before live transfer call is connected."
            />
            <FormGroup className="d-flex flex-column">
              <ButtonToggle
                active={values.liveTransferIntroGreeting}
                onToggle={() => toggleLiveTransferIntroGreetingSwitch()}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center my-3">
          <Col xs={4}>
            <LiveTransferMp3Upload
              fieldName="liveTransferWaitUrl"
              label="Voice call ringer sound"
            />
          </Col>
          <Col xs={4}>
            <LiveTransferMp3Upload
              fieldName="liveTransferVoiceMailUrl"
              label="Live Transfer Voicemail Sound"
              id="liveTransferVoiceMailUrl"
            />
          </Col>
        </Row>
      </Card>
      <Card body className="my-3">
        <CardTitle tag="h4" className="lead text-uppercase mb-2">
          Agent Script
        </CardTitle>
        <small className="text-muted">
          Create a script that will be presented to the Agent along with the
          lead information.
        </small>
        <br />
        <small className="text-muted mb-1">
          <span className="d-block mb-2">
            Please use the following keys to add lead information into the
            script below only if this lead source not accepting no data calls:
          </span>
          <span className="mr-3">&#123;&#123;firstName&#125;&#125; </span>
          <span className="mr-3">&#123;&#123;lastName&#125;&#125;</span>
          <span className="mr-3">&#123;&#123;phoneNumber&#125;&#125;</span>
          <span className="mr-3">&#123;&#123;state&#125;&#125;</span>
        </small>
        <Row className="mt-3">
          <Col xs={12}>
            <Input
              name="liveTransferScript"
              type="textarea"
              style={{ minHeight: 200 }}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default LiveTransfersTab;
