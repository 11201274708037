import React, { useMemo } from 'react';
import { Route } from 'react-router-dom';
import { Container } from 'reactstrap';
import access_obj from '@/utils/uiAccess';
import TertiaryNav from '@/components/Nav/TertiaryNav';
import { useAdmin } from '@/hooks';
import NetworksGeneralTab from './General';
import NetworksListsTab from './CompanyLists';

let navItems = [
  {
    label: 'General Information',
    path: 'base',
  },
  {
    label: 'Company Lists',
    path: 'lists',
  },
];

const NetworksSettings: React.FC = () => {
  const { role } = useAdmin();

  const route_map = {
    general: (
      <Route
        component={NetworksGeneralTab}
        exact
        path="/settings"
        key="networks-general"
      />
    ),
    lists: (
      <Route
        component={NetworksListsTab}
        exact
        path="/settings/lists"
        key="networks-lists"
      />
    ),
  } as any;

  navItems = useMemo(
    () =>
      navItems.filter(({ path }) =>
        access_obj[role].network.allowed.includes(path)
      ),
    [role]
  );
  const allowed = useMemo(
    () => access_obj[role].network.allowed.map((page: any) => route_map[page]),
    [role]
  );
  return (
    <div className="d-flex flex-fill">
      <TertiaryNav
        navItems={navItems}
        basePath="settings"
        extendedPath="/:path"
      />
      <Container className="flex-fill d-flex flex-column">{allowed}</Container>
    </div>
  );
};

export default NetworksSettings;
