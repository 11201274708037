import React, { useEffect } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
} from 'reactstrap';
import { Form, Field } from '@availity/form';
import * as yup from 'yup';
import { useNotifications, usePageLoader } from '@/hooks';
import { useMutation } from 'react-apollo';
import { activateServiceMutation } from '@/graphql/mutations';
import { AgentFragment } from '@/graphql/fragments';

const ServiceLoginModal: React.FC<Omit<ModalProps, 'toggle'>> = ({
  user,
  serviceID,
  toggle,
  isOpen,
  ...props
}) => {
  const { create: createNotification } = useNotifications();
  const { complete } = usePageLoader();
  const [activeService] = useMutation(activateServiceMutation, {
    update(cache, { data: { serviceActivation } }) {
      cache.writeFragment({
        id: user.id,
        fragment: AgentFragment,
        data: { ...user, serviceActivation },
      });
      window.location.reload();
    },
    //  update(cache, { data: { agentUpdateOne } }) {
    //   cache.writeQuery({
    //     query: agentNodeQuery,
    //     data: {
    //       account: {
    //         ...account,
    //         ...accountServiceCharge.result,
    //       },
    //     },
    //   });
    // },
    onCompleted: () => {
      createNotification('success', 'Successfully activatedu user');
      toggle();
      complete();
    },
    onError: (error) => {
      createNotification('danger', error.message.replace('GraphQL error:', ''));
      complete();
    },
  });

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} {...props}>
        <Form
          initialValues={{
            callerReadyEmail: user.email,
            callerReadyPassword: '',
          }}
          validationSchema={yup.object().shape({
            callerReadyEmail: yup.string().required('This field is required.'),
            // .matches(
            //   /^[a-zA-Z]*$/,
            //   'Only letters allowed. Use camelCase for multiple words'
            // )
            callerReadyPassword: yup
              .string()
              .required('This field is required.'),
            // .matches(/^[a-zA-Z ]*$/, 'Only letters and spaces allowed')
          })}
          onSubmit={(values) =>
            activeService({
              variables: {
                input: {
                  email: values.callerReadyEmail,
                  password: values.callerReadyPassword,
                  agentID: user.agentID,
                  serviceID,
                },
              },
            })
          }
        >
          {({ resetForm }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              if (isOpen) {
                return () => resetForm();
              }
              // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [isOpen, resetForm]);

            return (
              <>
                <ModalHeader toggle={toggle}>
                  Activate Caller Ready User
                </ModalHeader>
                <ModalBody>
                  <Field
                    name="callerReadyEmail"
                    label="Caller Ready Email"
                    placeholder="Enter Email..."
                  />
                  <Field
                    name="callerReadyPassword"
                    label="Caller Ready Password"
                    placeholder="Enter Password..."
                  />
                </ModalBody>
                <ModalFooter>
                  <Button color="success" type="submit">
                    Create
                  </Button>
                </ModalFooter>
              </>
            );
          }}
        </Form>
      </Modal>
    </>
  );
};

export default ServiceLoginModal;
