/* eslint-disable no-unused-expressions */
import React from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  ModalProps,
  CardHeader,
} from 'reactstrap';
import moment from 'moment';
import { SpendingPeriod } from '@sonic-web-dev/types';
import {
  sumSpendingPeriods,
  localizeSpendingPeriod,
} from '@/areas/Agents/DetailView/Campaigns/utils';
import { formatCurrencyForDisplay } from '@/utils';
import SpendingPeriodItem from './SpendingPeriod';

interface SpendingPeriodModalProps extends Omit<ModalProps, 'toggle'> {
  campaignName?: string;
  spendingPeriods?: SpendingPeriod[];
}

const SpendingPeriodModal: React.SFC<SpendingPeriodModalProps> = ({
  toggle,
  isOpen,
  spendingPeriods,
  campaignName,
}) => {
  const spendingPeriodExists = spendingPeriods?.length > 0;

  const getNoActive = () => (
    <tr>
      <td>No active spending periods.</td>
    </tr>
  );

  const getSpendingPeriods = () =>
    spendingPeriods?.map((data: any, periodIdx: number) => {
      return (
        <SpendingPeriodItem
          {...data}
          period={periodIdx}
          spendingPeriods={spendingPeriods}
        />
      );
    });

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader wrapTag={CardHeader} toggle={toggle}>
        Spending Periods - {campaignName}
      </ModalHeader>
      <ModalBody>
        {spendingPeriodExists ? getSpendingPeriods() : getNoActive()}
      </ModalBody>
      <ModalFooter>
        Weekly maximum:{' '}
        {formatCurrencyForDisplay(sumSpendingPeriods(spendingPeriods || []))}
      </ModalFooter>
    </Modal>
  );
};

export default SpendingPeriodModal;
