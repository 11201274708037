import React, { useState } from 'react';
import { Field } from '@availity/form';
import { useFormikContext } from 'formik';
import Button from '@/components/LoadingButton';
import { FaFileUpload, FaPlay } from 'react-icons/fa';
import Mp3UploadModal from './Mp3UploadModal';

const LiveTransferMp3Upload: React.FC<{
  fieldName: string;
  label: string;
  id?: string;
}> = ({ fieldName, label }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = (): void => setIsModalOpen((o) => !o);
  const { values } = useFormikContext<{
    layerID: string;
    liveTransferWaitUrl: string;
    liveTransferVoiceMailUrl: string;
  }>();

  return (
    <>
      <Field
        name={fieldName}
        label={label}
        disabled
        append={
          <>
            <Button
              color="primary"
              onClick={() => {
                toggleModal();
              }}
            >
              <FaFileUpload />
            </Button>
            <Button
              onClick={() => {
                const link = document.createElement('a');
                link.href =
                  fieldName === 'liveTransferWaitUrl'
                    ? values.liveTransferWaitUrl
                    : values.liveTransferVoiceMailUrl;
                link.target = '_blank';
                link.click();
              }}
            >
              <FaPlay />
            </Button>
          </>
        }
      />

      <Mp3UploadModal
        isOpen={isModalOpen}
        fieldName={fieldName}
        layerID={values.layerID}
        close={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default LiveTransferMp3Upload;
