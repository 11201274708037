import React, { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { ButtonToggle, MoneyField } from '@/components';
import { Field } from '@availity/form';
import { FaPlus, FaQuestionCircle, FaTimes } from 'react-icons/fa';
import classNames from 'classnames';

const BulkPriceBreakPoints: FC = () => {
  const [breakPoint, setBreakPoint] = useState(0);
  const { values, setFieldValue } = useFormikContext();
  const attributePrefix = 'bulk-price-breakpoints';

  useEffect(() => {
    if (values.priceBreakPoints) {
      setBreakPoint(values?.priceBreakPoints.length - 1);
    }
  }, [values.priceBreakPoints]);

  return (
    <>
      <Card className="mb-3">
        <CardHeader className="d-flex justify-content-between align-items-center">
          <CardTitle
            tag="h5"
            className="py-2 h4 mb-0 d-flex justify-content-center align-items-center"
          >
            Bulk Price Discounts{' '}
            <FaQuestionCircle
              className="ml-2 cursor-pointer text-primary"
              id={`${attributePrefix}-tooltip`}
            />
            <UncontrolledTooltip
              target={`${attributePrefix}-tooltip`}
              placement="auto-start"
              style={{ textAlign: 'left' }}
            >
              Create price breakpoints below to apply a bulk discount for
              agents. The amount of leads in a breakpoint will determine the
              price of leads for that quantity and above.
            </UncontrolledTooltip>
          </CardTitle>
          <ButtonToggle
            id={`${attributePrefix}-enable-toggle`}
            active={values?.priceBreakPointsEnabled}
            onToggle={() =>
              setFieldValue(
                'priceBreakPointsEnabled',
                !values?.priceBreakPointsEnabled
              )
            }
          />
        </CardHeader>
        {values.priceBreakPointsEnabled && (
          <>
            <CardBody className="container px-5">
              <Row>
                <Col>
                  {[0, 1, 2, 3, 4].map((i: number) => {
                    if (i > breakPoint && i !== 0) return null;
                    return (
                      <Row
                        className="d-flex justify-content-center align-items-center mb-2"
                        key={i}
                      >
                        <Col xs={1} className="d-flex align-items-center pt-3">
                          <Button
                            className="px-3 mr-2"
                            color="secondary"
                            id={`${attributePrefix}-minus-button`}
                            onClick={() => {
                              if (breakPoint === 0) return;
                              setFieldValue(
                                'priceBreakPoints',
                                values?.priceBreakPoints?.filter(
                                  (_: any, index: number) => index !== i
                                )
                              );
                              setBreakPoint(breakPoint - 1);
                            }}
                          >
                            <FaTimes />
                          </Button>
                        </Col>
                        <Col xs={3}>
                          <Field
                            type="number"
                            name={`priceBreakPoints.${i}.breakPoint`}
                            label="Leads"
                            placeholder="500"
                            id={`${attributePrefix}-breakpoint-leads`}
                          />
                        </Col>
                        <Col xs={3} className="pb-3">
                          <MoneyField
                            label="Price"
                            name={`priceBreakPoints.${i}.breakPointPrice`}
                            size="md"
                            placeholder="0.00"
                            id={`${attributePrefix}-money-field`}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                  <Row className="mt-3 d-flex justify-content-center">
                    <Button
                      className={classNames('px-5', {
                        'd-none':
                          breakPoint === 4 ||
                          !values.priceBreakPoints?.[breakPoint]?.breakPoint ||
                          !values.priceBreakPoints?.[breakPoint]
                            ?.breakPointPrice,
                      })}
                      color="primary"
                      id={`${attributePrefix}-plus-button`}
                      onClick={() => {
                        if (breakPoint === 4) return;
                        setBreakPoint(breakPoint + 1);
                      }}
                    >
                      <FaPlus />
                    </Button>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </>
        )}
      </Card>
    </>
  );
};

export default BulkPriceBreakPoints;
