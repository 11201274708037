/* eslint-disable no-unused-expressions */
/* eslint-disable promise/always-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable unicorn/prefer-add-event-listener */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Progress,
  CardHeader,
} from 'reactstrap';
import { Storage } from 'aws-amplify';
import Dropzone from 'react-dropzone';
import { FaUpload } from 'react-icons/fa';
import { useNotifications, useNetwork } from '@/hooks';
import { Vars } from '@/utils';
import moment from 'moment';

const CsvUploadModal: React.SFC<{
  close: () => void;
  isOpen: boolean;
  vendorInfo: {
    layerID: string;
    vendorID: string;
    vendorSourceCampaign?: string;
    newAgedUpload?: boolean;
  };
}> = ({
  close = () => {},
  vendorInfo: { layerID, vendorID, newAgedUpload },
  isOpen,
}: any) => {
  const [[fileName, file], setFileName] = useState(['', null]);

  const [progress, setProgress] = useState(0);

  const [headerCheck, setHeaderCheck] = useState<boolean>(false);

  const [isClicked, setIsClicked] = useState(false);

  const { network } = useNetwork();

  const currentDate = useMemo(() => {
    return moment().format();
  }, []);

  const uploadKey = useMemo(() => {
    return newAgedUpload
      ? `bulkUploadCSV/${network?.networkID}/${vendorID}/${layerID}/${currentDate}-${fileName}`
      : `networks/${network?.networkID}/${vendorID}/${layerID}/csv/${fileName}`;
  }, [currentDate, fileName, layerID, network, newAgedUpload, vendorID]);

  useEffect(() => {
    Storage.configure({
      AWSS3: {
        bucket: Vars.bulkUploadBucket,
        region: 'us-east-1',
      },
    });
  }, []);

  useEffect(() => {
    setProgress(0);
  }, [isOpen]);
  const { create: createNotification } = useNotifications();

  const onDrop = useCallback((acceptedFiles) => {
    setProgress(0);
    setTimeout(() => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();

        reader.onprogress = (a) => {
          if (a.total > 524288000) {
            createNotification(
              'danger',
              'File Needs to be smaller then 500MB',
              2000
            );
            reader.abort();
            return;
          }
          setProgress(a.loaded / a.total);
        };

        reader.onloadend = () => {
          const view2: Int8Array = new Int8Array(reader.result as ArrayBuffer);

          let tempString = '';

          let i = 0;
          while (true) {
            const char = String.fromCharCode(view2[i]);
            if (char === '\n' || char === '\r') {
              break;
            }
            tempString += char;
            i += 1;
          }
          const headers = tempString.split(',');
          const requiredHeaders = ['primaryPhone', 'state', 'email'];
          (requiredHeaders.every((h) => headers.includes(h))
            ? () => {
                setHeaderCheck(true);
                setFileName([file.name, file]);
              }
            : () => {
                createNotification('danger', 'Invalid Headers', 2000);
                setHeaderCheck(false);
                setProgress(0);
                reader.abort();
              })();
        };
        reader.onabort = () =>
          createNotification('danger', 'Error Uploading', 2000);
        reader.onerror = () =>
          createNotification('danger', 'Error Uploading', 2000);
        reader.readAsArrayBuffer(file);
      });
    }, 1000);
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={close}>
      <ModalHeader wrapTag={CardHeader} toggle={close}>
        Upload CSV
      </ModalHeader>
      <ModalBody>
        <Dropzone onDrop={onDrop} maxFiles={1} accept={['.csv']}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <section>
              <div
                {...getRootProps({
                  className: isDragActive ? 'file-drop-active' : 'file-drop',
                })}
              >
                <input data-testid="file-picker" {...getInputProps()} />
                <p>
                  <strong>Drag and Drop</strong>
                </p>
                <Button color="primary">
                  <div className="d-flex justify-content-center">
                    Upload File &nbsp;
                    <FaUpload />
                  </div>
                </Button>
              </div>
            </section>
          )}
        </Dropzone>
        <div className="w-100 mt-3">
          <Progress color="success" value={progress * 100}>
            {progress === 1 ? `Uploaded ${fileName}` : ''}
          </Progress>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={close}>
          Cancel
        </Button>
        <Button
          color="success"
          disabled={progress !== 1 || isClicked || !headerCheck}
          onClick={async () => {
            setIsClicked(true);
            Storage.vault
              .put(uploadKey, file, {
                level: 'public',
                bucket: Vars.bulkUploadBucket,
                region: 'us-east-1',
              })
              .then(() => {
                createNotification(
                  'success',
                  'Successfully uploaded csv',
                  2000
                );
                close();
              })
              .catch(() => {
                setIsClicked(false);
                createNotification('danger', 'Error Saving', 2000);
              });
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CsvUploadModal;
