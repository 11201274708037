import React, { useEffect } from 'react';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
} from 'reactstrap';
import { Form, CheckboxGroup, Checkbox } from '@availity/form';
import { useNetworks, useNotifications } from '@/hooks';
import { useMutation } from 'react-apollo';
import { Pagination, PaginationContent } from '@availity/pagination';
import { networkUpdateVendorsMutation } from '@/graphql/mutations';

const VendorAssignModal: React.FC<Omit<ModalProps, 'toggle'>> = ({
  toggle,
  isOpen,
  vendorID,
  ...props
}) => {
  const { create: createNotification } = useNotifications();
  const { data, refetch } = useNetworks({
    fetchPolicy: 'network-only',
  });

  const networkIDs = data?.networkMany?.map((n: any) => n.networkID);
  const assignedNetworks =
    data?.networkMany
      ?.filter((n: any) => {
        if (n.allowedVendors?.includes(vendorID)) return n;
      })
      .map((n: any) => n.networkID) || [];

  const [networkUpdateVendors] = useMutation(networkUpdateVendorsMutation, {
    onCompleted: () => {
      createNotification('success', 'Successfully updated Networks.');
      refetch();
      toggle();
    },
    onError: () => {
      createNotification('danger', 'Failed to update Networks.');
    },
  });

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} {...props} size="sm">
        <Form
          initialValues={{
            assignedNetworks,
            vendorID,
          }}
          onSubmit={(values) => {
            networkUpdateVendors({
              variables: {
                input: {
                  networkIDs,
                  assignedIDs: values.assignedNetworks,
                  vendorID: values.vendorID,
                },
              },
            });
          }}
        >
          {({ resetForm }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              if (isOpen) {
                return () => resetForm();
              }
              // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [isOpen, resetForm]);

            return (
              <>
                <ModalHeader toggle={toggle}>
                  Assign Vendor to Networks
                </ModalHeader>
                <ModalBody>
                  <Pagination items={data?.networkMany}>
                    <CheckboxGroup
                      name="assignedNetworks"
                      for="assignedNetworks"
                      groupClassName="bg-dark"
                    >
                      <PaginationContent
                        containerProps={{
                          className: 'bg-dark text-white',
                        }}
                        containerTag={ListGroup}
                        itemKey="networkID"
                        component={({ networkID, networkName }) => (
                          <ListGroupItem className="d-flex justify-content-between align-items-center">
                            <Checkbox
                              name={networkID}
                              label={networkName}
                              value={networkID}
                            />
                          </ListGroupItem>
                        )}
                      />
                    </CheckboxGroup>
                  </Pagination>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" type="submit">
                    Assign
                  </Button>
                </ModalFooter>
              </>
            );
          }}
        </Form>
      </Modal>
    </>
  );
};

export default VendorAssignModal;
