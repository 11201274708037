import React, { useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardHeader,
} from 'reactstrap';
import { useNetwork, useNotifications } from '@/hooks';
import { Form, Field } from '@availity/form';
import * as yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { shareVendorIntegreation } from '@/graphql/mutations';

export interface ShareEmailModalProps {
  close: () => void;
  isOpen: boolean;
  vendorInfo: {
    layerID: string;
    vendorID: string;
    description: string;
  };
}

const ShareEmailModal: React.SFC<ShareEmailModalProps> = ({
  close,
  isOpen,
  vendorInfo: { layerID, vendorID, description },
}) => {
  const { create: createNotification } = useNotifications();
  const { network } = useNetwork();
  const [shareIntegration, { loading: sending }] = useMutation(
    shareVendorIntegreation,
    {
      onCompleted: () => {
        createNotification('success', `Email successfully sent!`);
        close();
      },
      onError: (error) => createNotification('danger', error.message),
    }
  );

  return (
    <Modal isOpen={isOpen} toggle={close}>
      <Form
        initialValues={{
          email: '',
        }}
        onSubmit={(values) => {
          // send email
          shareIntegration({
            variables: {
              input: {
                toAddresses: [values.email],
                templateName:
                  network!.marketplace === 'leadtopia'
                    ? 'vendorIntegrationLeadtopia'
                    : 'vendorIntegrationNew',
                templateData: {
                  layerID,
                  vendorID,
                  vendorSourceCampaign: description,
                },
              },
            },
          });
        }}
        validationSchema={yup.object().shape({
          email: yup
            .string()
            .email('Must be a valid email address')
            .required('This field is required.'),
        })}
      >
        {({ resetForm }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (isOpen) {
              return () => resetForm();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [isOpen, resetForm]);

          return (
            <>
              <ModalHeader wrapTag={CardHeader} toggle={close}>
                Share Integration Email
              </ModalHeader>
              <ModalBody>
                <p className="text-muted">
                  We sent an email with instructions on how to establish the
                  link with your vendor to the creator of this vendor campaign
                  when it was first created. You may send another copy of these
                  instructions using this form.
                </p>
                <Field
                  name="email"
                  label="Email Address"
                  placeholder="example@email.com"
                  type="email"
                />
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button disabled={!sending} onClick={close}>
                    Dismiss
                  </Button>
                  <Button color="success" disabled={sending} type="submit">
                    Send Email
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default ShareEmailModal;
