import React from 'react';
import PropTypes from 'prop-types';
import { Button as RsButton, Spinner } from 'reactstrap';
import classnames from 'classnames';
import { ButtonProps } from '@gopro16/button/Button';

const Button: React.SFC<ButtonProps> = ({
  children,
  loading,
  block,
  ...rest
}) => (
  <RsButton {...rest} block={block} disabled={loading}>
    {!(block && !loading) && (
      <Spinner
        className={classnames({
          'position-relative': true,
          'button-style': !block,
          visible: loading,
          invisible: !loading,
          'd-none': !loading,
        })}
        size="sm"
        // type="grow"
      />
    )}
    {!(block && loading) && (
      <span
        className={classnames({
          invisible: loading,
          visible: !loading,
          'span-style': !block,
        })}
      >
        {children}
      </span>
    )}
  </RsButton>
);

Button.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  block: PropTypes.bool,
};

export default Button;
