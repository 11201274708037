import React from 'react';
import { Container, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';

interface AuthHeaderProps {
  cardTitle: string;
}

const AuthHeader: React.SFC<AuthHeaderProps> = ({ children, cardTitle }) => {
  return (
    <Container className="mt-5" style={{ maxWidth: 500 }}>
      <Card>
        <CardHeader>
          <CardTitle tag="h5" className="py-2 h4 mb-0">
            {cardTitle}
          </CardTitle>
        </CardHeader>
        <CardBody>{children}</CardBody>
      </Card>
    </Container>
  );
};

export default AuthHeader;
