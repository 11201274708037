/* eslint-disable react/jsx-boolean-value */
/* eslint-disable promise/always-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable unicorn/prefer-add-event-listener */
import React from 'react';
import { Field, Input } from '@availity/form';
import * as yup from 'yup';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  CardHeader,
  InputGroup,
  Label,
} from 'reactstrap';
import { useMutation } from '@apollo/react-hooks';
import { useNotifications } from '@/hooks';
import { processAgedLeads } from '@/graphql/mutations';
import { Formik } from 'formik';

const AgedLeadModal: React.SFC<{
  close: () => void;
  isOpen: boolean;
  pendingLeads: number;
  vendorID: string;
  layerID: string;
}> = ({ close = () => {}, isOpen, pendingLeads, vendorID, layerID }) => {
  const { create } = useNotifications();
  const [process] = useMutation(processAgedLeads);
  return (
    <Formik
      initialValues={{ leads: 0 }}
      onSubmit={({ leads }) => {
        process({
          variables: {
            maxItems: leads,
            vendorID,
            layerID,
          },
        });
        create('success', 'Batch process sucessfully created', 3000);
        close();
      }}
      validationSchema={yup.object().shape({
        leads: yup
          .number()
          .lessThan(
            pendingLeads > Math.max(pendingLeads, 1000)
              ? Math.max(pendingLeads, 1000)
              : pendingLeads + 1,
            pendingLeads > 1000
              ? `Cannot process more than 1000 at a time`
              : 'Cannot process more than the pending leads.'
          )
          .moreThan(0, 'Needs to be more than zero')
          .required(),
      })}
    >
      {({ errors, handleSubmit }) => {
        return (
          <Modal isOpen={isOpen} toggle={close}>
            <ModalHeader wrapTag={CardHeader} toggle={close}>
              Add Aged leads
            </ModalHeader>
            <ModalBody>
              <Field
                name="leads"
                label="# of leads to add"
                step="1"
                min={0}
                max={pendingLeads}
                type="number"
              />
            </ModalBody>
            <ModalFooter>
              {' '}
              <Button color="danger" onClick={close}>
                Cancel
              </Button>
              <Button
                color="success"
                type="submit"
                onClick={handleSubmit}
                disabled={!!errors.leads}
              >
                Add Leads
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default AgedLeadModal;
