/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import {
  Table,
  ButtonGroup,
  Button,
  Row,
  Col,
  Label,
  FormText,
  FormGroup,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
} from 'reactstrap';
import axios from 'axios';
import chunk from 'lodash.chunk';
import { FaHashtag, FaFlask, FaTrash } from 'react-icons/fa';
import { Form, Field, Input } from '@availity/form';
import { FieldArray } from 'formik';
import omit from 'lodash.omit';
import * as yup from 'yup';
import { uuid } from 'uuidv4';
import { useQuery } from '@apollo/react-hooks';
import { convosoAgentIDQuery } from '@/graphql/queries';
import { CopyButton, LoadingButton } from '@/components';
import { useNetwork, useNotifications } from '@/hooks';
import useAgent from '../useAgent';

interface ConvosoProfileProps {
  crmID: string;
  profiles: any[];
  fields: any;
  convosoAgentEmail: string;
  convosoAgentID: string;
  authToken: string;
}

const ConvosoProfile: React.FC<ConvosoProfileProps> = (inputs) => {
  const {
    profiles = [],
    fields,
    convosoAgentEmail,
    authToken,
    // convosoAgentID: orgCAgentID,
    convosoAgentID,
  } = inputs;

  const [isLoading, setLoading] = useState(false);
  // const [convosoAgentID, setConvosoAgentID] = useState(orgCAgentID)
  const { user, updating, update } = useAgent();
  const { network } = useNetwork();

  const { create: createNotification } = useNotifications();

  const syncConvsosoID = async (event: any, email: string): Promise<void> => {
    event.preventDefault();
    setLoading(true);
    const url = `https://api.convoso.com/v1/users/search?auth_token=${authToken}&user=${email}&limit=20&offset=0`;
    try {
      const response = await axios({
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        url,
        // data:{
        //   "auth_token":authToken,
        //   "user":email,
        //   "limit":20,
        //   "offset":0
        //   }
      });
      const { data } = response;
      const { success, total, results } = data as any;

      if (!success) {
        console.error(response);
        createNotification('danger', 'Convoso error!');
      }

      if (total === 0) {
        console.error(response);
        createNotification('danger', 'No Convoso user found!');
      }

      if (total > 1) {
        console.error(response);
        createNotification(
          'danger',
          'More than one Convoso user found! Please update manually'
        );
      }

      const keys = Object.keys(results);
      if (keys.length === 0) {
        createNotification('danger', 'No Convoso user found!');
      }

      setConvosoAgentID(convosoAgentID);
    } catch (error) {
      console.error(error.message);
      createNotification('danger', error.message);
    }

    setLoading(false);
    // {"success":true,"data":{"offset":"0","limit":"20","total":"1","results":{"1100154":{"id":1100154,"acl_access_profile":10,"email":"jason@sonicwebdev.com","first_name":"USHA","last_name":"MARKETPLACE","user_level":1,"queue_override":"COMBINE","allow_queue_selection":"0","allow_callbacks":"1","callback_types_allowed":"2","allow_transfers":"1","default_blended_selection":"0","alter_custdata_override":"Y","allow_task_assign":"YES","status":"Y","local_gmt":"-5.00","allow_blended_campaign":"1","notification":"0","created_at":"2022-05-17T07:24:12-0700","connection_type":"BROWSER","extension":[219],"last_login":"2022-07-12 11:12:37","custom_fields":[]}}}}
  };

  return (
    <Form
      validationSchema={yup.object().shape({
        convosoAgentEmail: yup.string(),
        profiles: yup.array().of(
          yup
            .object()
            .shape({
              crmFields: yup.object().shape(
                fields.reduce((acc: any, field: any) => {
                  console.log(field);
                  acc[field.name] = yup
                    .string()
                    .isRequired(field.required === 'true');

                  return acc;
                }, {} as any)
              ),
            })
            .test(
              'duplicate-field',
              'Destination already in use.',
              (
                val: any,
                ctx: yup.TestContext<Record<string, any>>
              ): boolean => {
                const uniqueId = Object.values(val.crmFields).join('@');
                return !ctx.parent.find((profile) => {
                  if (profile.id === val.id) {
                    return false;
                  }
                  const profileID = Object.values(profile.crmFields).join('@');
                  return profileID === uniqueId;
                });
              }
            )
        ),
      })}
      initialValues={{
        profiles: profiles || [],
        convosoAgentEmail: convosoAgentEmail || '',
        convosoAgentID: convosoAgentID || '',
      }}
      onSubmit={(values) => {
        update({
          variables: {
            input: {
              id: user!.id,
              agentID: user!.agentID,
              networkID: user!.networkID,
              crmProfiles: {
                vanillaSoftDirect: {
                  ...user!.crmProfiles!.vanillaSoftDirect,
                  label: 'VanillaSoft',
                  profiles: user!.crmProfiles!.vanillaSoftDirect?.profiles?.map(
                    (p) => ({
                      ...omit(p, ['__typename', 'profileID']),
                      id: p.profileID || p.id,
                    })
                  ),
                },
                iSalesCRM: {
                  ...user!.crmProfiles!.iSalesCRM,
                  label: 'ISalesCRM',
                  profiles: user!.crmProfiles!.iSalesCRM?.profiles?.map(
                    (p) => ({
                      ...omit(p, ['__typename', 'profileID']),
                      id: p.profileID || p.id,
                    })
                  ),
                },
                convoso: {
                  ...user!.crmProfiles!.convoso,
                  convosoAgentEmail: values.convosoAgentEmail.trim(),
                  convosoAgentID: values.convosoAgentID.trim(),
                  label: 'Convoso',
                  profiles: values.profiles?.map((p) => ({
                    ...omit(p, ['__typename', 'profileID']),
                    id: p.profileID || p.id,
                  })),
                },
              },
            },
          },
        });
      }}
    >
      {({ values, resetForm, errors }) => {
        useEffect(() => {
          resetForm();
        }, [user!.agentID]);

        return (
          <div>
            <div className="mb-3">
              <FormGroup>
                <Label>Convoso User Settings</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="border-0">Email:</InputGroupText>
                  </InputGroupAddon>
                  <Input type="email" name="convosoAgentEmail" />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <LoadingButton
                      type="submit"
                      color="success"
                      size="sm"
                      loading={isLoading}
                      onClick={(event) => {
                        syncConvsosoID(event, values.convosoAgentEmail);
                      }}
                    >
                      SYNC
                    </LoadingButton>
                  </InputGroupAddon>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="border-0">
                      Agent ID:
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" name="convosoAgentID" />
                </InputGroup>
              </FormGroup>
            </div>
            <Table className="border-bottom border-secondary">
              <thead>
                <tr>
                  <th colSpan={2} className="border-0">
                    Profiles
                  </th>
                </tr>
              </thead>
              <tbody>
                <FieldArray
                  name="profiles"
                  render={(arrayHelpers) => (
                    <>
                      {values.profiles?.map((profile, profileIdx) => (
                        <tr key={profile.id}>
                          <td className="d-flex flex-column">
                            <span>
                              <FaHashtag className="mb-2" />
                              <h4 className="d-inline">{profileIdx + 1}</h4>
                            </span>
                            <div>
                              <ButtonGroup vertical>
                                <Button
                                  color="info"
                                  disabled
                                  title="Test profile (COMING SOON)"
                                >
                                  <FaFlask />
                                </Button>
                                <Button
                                  color="danger"
                                  title="Delete Profile"
                                  id={`delete-profile-${profileIdx}`}
                                  onClick={() =>
                                    arrayHelpers.remove(profileIdx)
                                  }
                                >
                                  <FaTrash />
                                </Button>
                              </ButtonGroup>
                            </div>
                          </td>
                          <td>
                            <Row noGutters>
                              <Col xs={12} className="mb-2">
                                <small className="text-muted">{`${
                                  user!.agentID
                                }@convoso@agent@${profile.profileID}`}</small>
                                <CopyButton
                                  content={`${user!.agentID}@convoso@agent@${
                                    profile.profileID
                                  }`}
                                  className="ml-3 float-right"
                                />
                              </Col>
                              <Col xs={12}>
                                <Field
                                  label="Description"
                                  placeholder="A descriptive name for this profile"
                                  name={`profiles.${profileIdx}.description`}
                                />
                              </Col>
                              {chunk(fields, 2).map((chunk: any) =>
                                chunk.map((subChunk: any) => {
                                  return (
                                    <Col
                                      key={`profiles.${profileIdx}.crmFields.${subChunk.name}`}
                                      xs={chunk.length === 2 ? 6 : 12}
                                    >
                                      <Field
                                        label={subChunk.label}
                                        placeholder={subChunk.placeholder}
                                        type={subChunk.type}
                                        name={`profiles.${profileIdx}.crmFields.${subChunk.name}`}
                                      />
                                    </Col>
                                  );
                                })
                              )}
                              <div className="text-danger">
                                {typeof errors?.profiles?.[profileIdx] ===
                                'string'
                                  ? errors.profiles[profileIdx]
                                  : undefined}
                              </div>
                            </Row>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={2}>
                          <Button
                            color="success"
                            className="w-100"
                            onClick={() =>
                              arrayHelpers.push({
                                crmFields: fields.reduce(
                                  (acc: any, field: any) => ({
                                    ...acc,
                                    [field.name]: '',
                                  }),
                                  {}
                                ),
                                id: `convoso-${uuid()}`,
                              })
                            }
                          >
                            Add New Profile
                          </Button>
                        </td>
                      </tr>
                    </>
                  )}
                />
              </tbody>
            </Table>
            <Button
              className="float-right"
              color="primary"
              type="submit"
              disabled={updating}
            >
              Save Changes
            </Button>
            <Button className="mr-1 float-right" color="secondary" type="reset">
              Reset Changes
            </Button>
          </div>
        );
      }}
    </Form>
  );
};

export default ConvosoProfile;
