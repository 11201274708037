import gql from 'graphql-tag';

export default gql`
  mutation queueAddTierMutation($input: RelayAddTierQueueInput!) {
    queueAddTier(input: $input) {
      success
      priceTierID
      queueID
    }
  }
`;
