import gql from 'graphql-tag';
import { VendorFragment } from '@/graphql/fragments';

export default gql`
  query nodeQuery($id: ID!) {
    node(id: $id) {
      __typename
      ...VendorFragment
    }
  }
  ${VendorFragment}
`;
