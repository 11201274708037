import gql from 'graphql-tag';
import { VendorFragment } from '../fragments';

export default gql`
  mutation createVendor($input: CreateVendorInput!) {
    __typename
    createVendor(input: $input) {
      ...VendorFragment
    }
  }
  ${VendorFragment}
`;
