import gql from 'graphql-tag';

export const NetworkDetails = `
    id
    __typename
    networkName
    networkID
    companyLists{
        postalCode{
            total
            values
        }
        region
    }
`;

const NetworkDetailFragment = gql`
  fragment NetworkDetailFragment on Network {
    ${NetworkDetails}
  }`;

export default NetworkDetailFragment;
