import React, { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { ListGroup, Card, CardHeader, CardTitle, Button } from 'reactstrap';
import Pagination, { PaginationContent } from '@availity/pagination';
import { RouteComponentProps } from 'react-router-dom';
import agentPagination from '@/graphql/queries/agentPagination';
import { useNotifications, usePageLoader } from '@/hooks';
import { ProfileEditAgentsModal } from '@/modals';
import { Form } from '@availity/form';
import { NoItemsMessage, SearchSummary } from '@/components';
import AgentListItem from '../Search/AgentListItem';
import useAgent from './useAgent';
import SearchControls from '../Search/Controls/SearchControls';

const Agents: React.SFC<RouteComponentProps<{
  id: string;
}>> = ({ match }) => {
  const { start, complete } = usePageLoader();
  const [resetCount, setResetCount] = useState(0); // Todo find better way to force refresh
  const client = useApolloClient();
  const [agentsModal, setAgentsModal] = useState(false);
  const toggleModal = (): void => setAgentsModal((o) => !o);
  const { user } = useAgent();
  const { create: createNotification } = useNotifications();

  return (
    <Form
      initialValues={{
        searchText: '',
        roles: [],
        agentApproved: undefined,
      }}
      onSubmit={() => {}}
    >
      {({ values, resetForm }) => {
        useEffect(() => {
          resetForm();
        }, [user?.agentID]);

        const fetchItems = async (
          currentPage: number,
          itemsPerPage: number
        ): Promise<any> => {
          start();
          const response = await client.query<any>({
            query: agentPagination,
            fetchPolicy: 'network-only',
            variables: {
              filter: {
                managerID: match.params.id,
                networkID: user?.network!.id,
                q: values.searchText,
                roles: values.roles || [],
                agentApproved:
                  typeof values.agentApproved === 'boolean'
                    ? values.agentApproved
                    : undefined,
              },
              perPage: itemsPerPage,
              page: currentPage,
              orderBy: {
                firstName: 'ASC',
              },
            },
          });

          const currentPageData = response.data?.agentPagination.items;

          complete();
          return {
            totalCount: response.data.agentPagination.count,
            items: currentPageData,
          };
          // }
        };

        return (
          <div>
            <Card className="mb-3">
              <CardHeader>
                <CardTitle tag="h5" className="py-2 h4 mb-0">
                  Manage Agents
                  <Button
                    className="float-right"
                    color="primary"
                    onClick={() => {
                      if (
                        !['manager', 'admin', 'super_admin'].includes(
                          user!.role!
                        )
                      ) {
                        createNotification(
                          'warning',
                          'Agent must be at least a Manager to add agents.'
                        );
                      } else {
                        toggleModal();
                      }
                    }}
                  >
                    Edit Agents
                  </Button>
                </CardTitle>
              </CardHeader>
            </Card>
            <Pagination
              items={fetchItems}
              itemsPerPage={25}
              resetParams={[
                match.params.id,
                values.searchText,
                values.roles,
                values.agentApproved,
                resetCount,
              ]}
            >
              <SearchControls showManager={false} />
              <SearchSummary className="d-flex justify-content-between py-3" />
              <PaginationContent
                component={AgentListItem}
                itemKey="agentID"
                containerTag={ListGroup}
              />
              <NoItemsMessage className="d-flex justify-content-center align-items-center pt-2 pb-2 h5">
                This user currently manages no agents.
              </NoItemsMessage>
            </Pagination>
            <ProfileEditAgentsModal
              onClosed={() => {
                setResetCount((i) => i + 1);
              }}
              isOpen={agentsModal}
              toggle={toggleModal}
            />
          </div>
        );
      }}
    </Form>
  );
};

export default Agents;
