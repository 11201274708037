import React from 'react';
import classnames from 'classnames';
import { Nav, NavItem, Badge } from 'reactstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';

interface TertiaryNavItemProps {
  label: string;
  path: string;
}

const TertiaryNav: React.FC<{
  navItems: TertiaryNavItemProps[];
  basePath: string;
  extendedPath?: string;
}> = ({ navItems, basePath, extendedPath }) => {
  const history = useHistory();
  const match = useRouteMatch<{
    path: string;
    id: string;
  }>(`/${basePath}${extendedPath}`);
  const baseMatch = useRouteMatch(`/${basePath}`);
  return (
    <Nav
      className="px-2 py-4"
      navbar
      vertical
      style={{
        backgroundColor: 'rgb(49,49,49)',
        width: 168,
      }}
    >
      {navItems.map((item) => {
        const active =
          item.path === 'base'
            ? baseMatch?.isExact
            : match?.params.path === item.path;
        return (
          <NavItem
            key={item.path}
            className={classnames('cursor-pointer mb-2 text-left', {
              'bg-primary': active,
              'bg-transparent': !active,
            })}
            style={{
              fontSize: 15,
            }}
            onClick={() =>
              item.path === 'base'
                ? history.push(`/${basePath}`)
                : history.push(
                    match?.params?.id
                      ? `/${basePath}/${match?.params?.id}/${item.path}`
                      : `/${basePath}/${item.path}`
                  )
            }
            tag={Badge}
          >
            {item.label}
          </NavItem>
        );
      })}
    </Nav>
  );
};

export default TertiaryNav;
