import gql from 'graphql-tag';

const SpendingPeriodFragment = gql`
  fragment SpendingPeriodFragment on SpendingPeriod {
    __typename
    id
    dailySpendLimit
    dowSun
    dowMon
    dowTue
    dowWed
    dowThu
    dowFri
    dowSat
    endTime
    spendingPeriodID
    lastPurchaseEpoch
    lastPurchasePeriodSpend
    delaySpendBy
    throttleNoMoreThan
    throttleLeadsPer
    startTime
    agentID
    campaignID
    status
  }
`;

export default SpendingPeriodFragment;
