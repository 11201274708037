import { useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import usePriceTiers, {
  UsePriceTiersResult,
  CustomPriceTier,
} from '../Search/usePriceTiers';

interface UsePriceTierResult extends UsePriceTiersResult {
  tier?: CustomPriceTier;
}

const usePriceTier = (id?: string): UsePriceTierResult => {
  const {
    priceTiers,
    vendorCampaigns,
    vendors,
    loading,
    upsertPriceTier,
    updating,
    refetch,
    refetchVendorCampaigns,
  } = usePriceTiers();
  const match = useRouteMatch<{
    id: string;
  }>('/price-tiers/:id');
  const history = useHistory();
  const tier = priceTiers?.find(
    (t: any) => t.id === id || t.id === match?.params.id
  );

  useEffect(() => {
    if (!loading && !tier && match?.params.id !== 'new') {
      history.push('/price-tiers');
    }
  }, [loading, tier, history, match]);

  return {
    priceTiers,
    vendorCampaigns,
    vendors,
    loading,
    tier,
    upsertPriceTier,
    updating,
    refetch,
    refetchVendorCampaigns,
  };
};

export default usePriceTier;
