import React from 'react';
import { Badge } from 'reactstrap';
import { FaTimesCircle } from 'react-icons/fa';
import { capitalize } from '@/utils';

interface FilterProps {
  index: number;
  attributes?: string[];
  resultAttribute: string;
  functionName?: string;
  remove: () => void;
  className?: string;
  type: string;
}

const getAttributeFilterName = (attributes?: string[]): string | undefined => {
  if (!attributes || attributes.length === 0) {
    return undefined;
  }

  if (attributes.length > 1) {
    // TODO start-up code
    return 'TCPA';
  }

  return attributes[0];
};
const VerifyFilter: React.FC<FilterProps> = ({
  attributes,
  functionName,
  resultAttribute,
  remove,
}) => {
  const attributeName =
    getAttributeFilterName(attributes) || functionName! || resultAttribute;

  return (
    <Badge
      color="primary"
      className="d-flex justify-content-between align-items-center mr-2 mb-2"
      id={`${attributeName}-verify-filter`}
    >
      <span className="mr-2 h5 mb-0">Verify {capitalize(attributeName)}</span>
      <FaTimesCircle
        className="cursor-pointer h5 mb-0"
        onClick={remove}
        id={`${attributeName}-verify-filter-remove`}
      />
    </Badge>
  );
};

export default VerifyFilter;
