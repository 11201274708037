/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  Badge,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import * as yup from 'yup';
import { SelectField } from '@availity/select';
import { Formik } from 'formik';
import { FaTimes, FaLongArrowAltRight } from 'react-icons/fa';
import {
  serverStatusMapping,
  deploymentStatusMapping,
} from '@/utils/infastructureUtils';
import { useMutation } from '@apollo/react-hooks';
import { updateQueueVersionMutation } from '@/graphql/mutations';

interface SelectedQueue {
  instanceID: string;
  fixedCampaignName: string;
  DEPLOYMENT_STATUS: string;
  SERVER_STATUS: string;
  version: string;
}
const UpdateQueuesModal: React.FunctionComponent<{
  close: () => any;
  versions: string[];
  selectedQueues: SelectedQueue[];
  isOpen: boolean;
  removeQueue: (instanceId: string) => void;
  clearQueues: () => void;
}> = ({
  isOpen,
  close,
  versions,
  selectedQueues = [],
  clearQueues = () => {},
  removeQueue = () => {},
}) => {
  const [updateQueueVersionFn] = useMutation(updateQueueVersionMutation);
  return (
    <Modal isOpen={isOpen} toggle={close}>
      <Formik
        onSubmit={(values) => {
          updateQueueVersionFn({
            variables: {
              input: {
                version: values.selectedVersion,
                queueIds: selectedQueues.map(({ id }) => id),
              },
            },
          });
          clearQueues();
          close();
        }}
        initialValues={{
          selectedVersion: '',
        }}
        validationSchema={yup.object().shape({
          selectedVersion: yup.string().isRequired(),
        })}
      >
        {({ handleSubmit, values: { selectedVersion } }) => {
          if (selectedQueues.length === 0) {
            close();
          }

          return (
            <>
              <ModalHeader toggle={close}>Queue Update</ModalHeader>
              <ModalBody>
                <div className="d-flex flex-column">
                  <SelectField
                    name="selectedVersion"
                    label="Queue Version"
                    options={JSON.parse(JSON.stringify(versions))
                      .reverse()
                      .map((key) => ({
                        label: key,
                        value: key,
                      }))}
                  />

                  <div className="d-flex flex-column">
                    Selected Queues
                    <div
                      style={{
                        minHeight: 100,
                        maxHeight: 200,
                        overflowY: 'scroll',
                      }}
                    >
                      {selectedQueues.map(
                        ({
                          instanceID,
                          fixedCampaignName,
                          DEPLOYMENT_STATUS,
                          SERVER_STATUS,
                          version = '0.0.0',
                        }) => (
                          <div
                            key={instanceID}
                            style={{
                              height: 100,
                              overflowX: 'hidden',
                              paddingLeft: 5,
                              cursor: 'pointer',
                            }}
                            className="bg-light rounded position-relative mt-1"
                          >
                            <div
                              className="position-absolute"
                              style={{ right: 5, zIndex: 999 }}
                              onClick={() => {
                                removeQueue(instanceID);
                              }}
                            >
                              <FaTimes color="dark" />
                            </div>
                            <Row className="h-100">
                              <Col
                                xs={7}
                                className="h-100 d-flex flex-column justify-content-around text-secondary"
                              >
                                <div>
                                  <span className="font-weight-bold">
                                    Instance Id
                                  </span>
                                  : {instanceID}
                                </div>
                                <div>
                                  <span className="font-weight-bold">
                                    Tier Name
                                  </span>
                                  : {fixedCampaignName}
                                </div>
                                <div>
                                  <span className="font-weight-bold">
                                    Version
                                  </span>
                                  :
                                  <Badge
                                    style={{
                                      fontSize: '.9rem',
                                    }}
                                    color="warning"
                                    title="Queue is running latest version"
                                    className="ml-2"
                                  >
                                    v{version}
                                  </Badge>
                                  {!!selectedVersion && (
                                    <>
                                      <FaLongArrowAltRight className="ml-2" />
                                      <Badge
                                        style={{
                                          fontSize: '.9rem',
                                        }}
                                        color="warning"
                                        title="Queue is running latest version"
                                        className="ml-2"
                                      >
                                        v{selectedVersion}
                                      </Badge>
                                    </>
                                  )}
                                </div>
                              </Col>
                              <Col xs={5}>
                                <div
                                  className="h-100 w-100 d-flex flex-column justify-content-around"
                                  style={{ paddingRight: 10 }}
                                >
                                  <div className="d-flex flex-row justify-content-between align-items-center">
                                    <div>Last Deployment:</div>
                                    <div>
                                      {deploymentStatusMapping(
                                        DEPLOYMENT_STATUS
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex flex-row justify-content-between align-items-center">
                                    <div>Server Status:</div>
                                    <div>
                                      {serverStatusMapping(SERVER_STATUS)}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-end">
                <Button color="secondary" onClick={close}>
                  Cancel
                </Button>
                <Button color="success" onClick={handleSubmit}>
                  Update
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default UpdateQueuesModal;
