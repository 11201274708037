import React from 'react';
import { ListGroupItem, Row, Col, Badge } from 'reactstrap';
import { Agent } from '@sonic-web-dev/types';
import { useHistory } from 'react-router-dom';
import { FaCheckCircle, FaQuestionCircle, FaTimesCircle } from 'react-icons/fa';

const badgeFontSize = '.85rem';

const getMarketplaceHighLight = (marketplace: string) => {
  if (marketplace === 'usha') {
    return {
      backgroundColor: '#002250',
      color: 'white',
      fontSize: badgeFontSize,
    };
  }

  return {
    backgroundColor: '#66FCF1',
    color: '#232323',
    fontSize: badgeFontSize,
  };
};

const getRoleColor = (role: string) => {
  switch (role) {
    case 'user':
      return 'secondary';
    case 'manager':
      return 'warning';
    case 'admin':
    case 'super_admin':
      return 'danger';
    default:
      return 'secondary';
  }
};

interface AgentProps extends Agent {
  id: string;
  managers: any[];
  legacySync?: boolean;
  status?: string;
  editable?: boolean;
}

const getAgentStatus = (
  agentApproved: boolean,
  legacySync: boolean,
  status?: 'active' | 'deleted'
) => {
  if (status === 'deleted') {
    return <FaTimesCircle className="text-danger" title="Agent Inactive" />;
  }

  if (!legacySync && agentApproved) {
    return (
      <FaCheckCircle
        className="text-info"
        title="Agent Approved using old marketplace."
      />
    );
  }

  return agentApproved ? (
    <FaCheckCircle className="text-success" title="Agent Active" />
  ) : (
    <FaQuestionCircle className="text-warning" title="Agent Pending Approval" />
  );
};

const AgentListItem: React.SFC<AgentProps> = ({
  firstName,
  lastName,
  managers,
  email,
  id,
  marketplace,
  role,
  agentApproved,
  legacySync,
  status,
  editable,
}) => {
  const history = useHistory();
  return (
    <ListGroupItem
      className="mb-3 d-flex align-items-center cursor-pointer agent-list-item"
      tag={Row}
      style={{
        borderLeft: `5px solid ${
          getMarketplaceHighLight(marketplace!).backgroundColor
        }`,
      }}
      noGutters
      onClick={() => {
        if (editable) {
          history.push(`/agents/${id}/general`);
        }
      }}
    >
      <Col xs={3}>
        {getAgentStatus(agentApproved, !!legacySync, status)} {firstName}{' '}
        {lastName}
      </Col>
      <Col xs={3}>{email}</Col>
      <Col xs={2} className="d-flex flex-column text-center">
        <span>{managers?.length || 0}</span>
        <span>Manager{(managers?.length || 0) === 1 ? '' : 's'}</span>
      </Col>
      <Col xs={2} className="d-flex flex-column text-center align-items-center">
        <Badge
          pill
          color={getRoleColor(role!)}
          style={{
            fontSize: badgeFontSize,
          }}
        >
          {role}
        </Badge>
        <span>Role</span>
      </Col>
      <Col xs={2} className="d-flex flex-column text-center align-items-center">
        <Badge pill style={getMarketplaceHighLight(marketplace!)}>
          {marketplace}
        </Badge>
        <span>Marketplace</span>
      </Col>
    </ListGroupItem>
  );
};

export default AgentListItem;
