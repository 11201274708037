import React from 'react';
import {
  Button,
  ButtonGroup,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap';
import MoneyInput from '@/components/MoneyInput';
import { formatCurrencyForDisplay } from '@/utils';
import {
  getSpendingPeriodString,
  minutesToTime,
} from '@/areas/Agents/DetailView/Campaigns/utils';

const SpendingPeriod: React.SFC<any> = ({
  dailySpendLimit,
  dowSun,
  dowMon,
  dowTue,
  dowWed,
  dowThu,
  dowFri,
  dowSat,
  endTime,
  startTime,
  period,
  spendingPeriods,
  throttleNoMoreThan = 1,
  throttleLeadsPer = 60000,
}) => {
  const MIN_DAILY_SPEND = 100;

  const checkSpendLimit = dailySpendLimit < MIN_DAILY_SPEND;

  const isThrottleZero = throttleNoMoreThan === 0 || throttleLeadsPer === 0;

  const getLeadThrottlingInput = () => (
    <InputGroup style={{ paddingTop: 12 }}>
      <InputGroupAddon addonType="prepend">No More than</InputGroupAddon>
      <Input
        disabled
        min={1}
        max={60}
        step={1}
        type="number"
        value={throttleNoMoreThan}
      />
      <InputGroupAddon addonType="prepend">lead(s) per </InputGroupAddon>
      <Input
        disabled
        min={1}
        max={60}
        step={1}
        type="number"
        value={throttleLeadsPer / 60000 || ''}
      />
      <InputGroupAddon addonType="prepend">min </InputGroupAddon>
    </InputGroup>
  );

  return (
    <div className="mb-3 border-bottom border-secondary">
      <div>
        <FormGroup>
          <Label>{getSpendingPeriodString(spendingPeriods[period])}</Label>
          <InputGroup style={{ paddingTop: 12 }}>
            <InputGroupAddon addonType="prepend">$</InputGroupAddon>
            <MoneyInput
              minimum={MIN_DAILY_SPEND}
              disabled
              value={dailySpendLimit}
            />
          </InputGroup>
          <FormText
            color="danger"
            style={{
              height: '19px',
              position: 'relative',
            }}
          >
            <span
              style={{
                position: 'absolute',
                right: 0,
                whiteSpace: 'nowrap',
              }}
            >
              {checkSpendLimit
                ? `The minimum daily spend per period is ${formatCurrencyForDisplay(
                    MIN_DAILY_SPEND
                  )}`
                : ''}
            </span>
          </FormText>
        </FormGroup>
        <ButtonGroup className="d-flex pb-3" disabled>
          {[
            { day: 'Sun', boolean: dowSun },
            { day: 'Mon', boolean: dowMon },
            { day: 'Tue', boolean: dowTue },
            { day: 'Wed', boolean: dowWed },
            { day: 'Thu', boolean: dowThu },
            { day: 'Fri', boolean: dowFri },
            { day: 'Sat', boolean: dowSat },
          ].map(({ day, boolean }) => (
            <Button
              key={day}
              active={boolean}
              color={boolean ? 'success' : 'secondary'}
              outline={!boolean}
              disabled
            >
              {day}
            </Button>
          ))}
        </ButtonGroup>
        <InputGroup style={{ padding: '12px 0' }}>
          <InputGroupAddon addonType="prepend">from</InputGroupAddon>
          <Input disabled type="time" value={minutesToTime(startTime)} />
          <InputGroupAddon addonType="append" style={{ marginRight: '-1px' }}>
            to
          </InputGroupAddon>
          <Input disabled type="time" value={minutesToTime(endTime)} />
          <InputGroupAddon addonType="append">
            {startTime >= endTime ? 'next day' : 'that day'}
          </InputGroupAddon>
        </InputGroup>
        <FormGroup>
          <Label>Lead Throttling</Label>
          &nbsp;
          <input
            name="lead-throttling-checkbox"
            type="checkbox"
            checked={!isThrottleZero}
            disabled
          />
          {!isThrottleZero && getLeadThrottlingInput()}
        </FormGroup>
      </div>
    </div>
  );
};

export default SpendingPeriod;
