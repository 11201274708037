import React from 'react';
import { Button, ButtonProps } from 'reactstrap';
import { FaArrowLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

interface BackButtonProps extends ButtonProps {
  path?: string;
  color?: 'primary' | 'success';
  hideText?: boolean | false;
}

const BackButton: React.SFC<BackButtonProps> = ({
  color,
  path,
  hideText,
  ...props
}) => {
  const history = useHistory();

  return (
    <Button
      className="mb-2"
      color={color}
      onClick={() => (path ? history.push(path) : history.goBack())}
      {...props}
    >
      <FaArrowLeft />
      {hideText ? '' : ' Back'}
    </Button>
  );
};

BackButton.defaultProps = {
  color: 'primary',
};

export default BackButton;
