import { useNetwork } from '@/hooks';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdownProps,
} from 'reactstrap';
import { useWindowHeight } from '@react-hook/window-size';

export const priceVerification = {
  attributes: ['vendorPrice'],
  include: true,
  attributeType: 'number',
  type: 'VERIFY',
};

export const phoneVerification = {
  attributes: ['orgPhone'],
  include: true,
  attributeType: 'string',
  type: 'VERIFY',
};

export const emailVerification = {
  attributes: ['email'],
  include: true,
  attributeType: 'string',
  type: 'VERIFY',
};

export const postalCodeVerification = {
  attributes: ['postalCode'],
  include: true,
  attributeType: 'string',
  type: 'VERIFY',
};

export const regionVerification = {
  attributes: ['region'],
  include: true,
  attributeType: 'string',
  type: 'VERIFY',
};

export const tcpaVerification = {
  attributes: ['jornayaLeadID', 'trustedFormCertID', 'trustedFormLink'],
  include: true,
  attributeType: 'string',
  type: 'VERIFY',
};

export const filterCategories = [
  {
    label: 'Time',
    filter: 'attribute',
    options: [
      {
        label: 'Postal Code',
        value: {
          attribute: 'postalCode',
          type: 'TIME',
          include: true,
          min: 0,
          max: 0,
          bid: 100,
          bidType: 'percent',
          rejectType: 'reject',
        },
      },
    ],
  },
  {
    label: 'Range',
    filter: 'attribute',
    options: [
      {
        label: 'Income',
        value: {
          attribute: 'income',
          type: 'RANGE',
          include: true,
          min: 0,
          max: 0,
        },
      },
      {
        label: 'Household',
        value: {
          attribute: 'household',
          type: 'RANGE',
          include: true,
          min: 0,
          max: 0,
        },
      },
      {
        label: 'Age',
        value: {
          attribute: 'age',
          type: 'RANGE',
          include: true,
          min: 0,
          max: 0,
        },
      },
      {
        label: 'ML Range Score',
        value: {
          attribute: 'mlRangeScore',
          type: 'RANGE',
          include: true,
          min: 0,
          max: 0,
        },
      },
      {
        label: 'KPM',
        value: {
          attribute: 'activeProspectKpm',
          type: 'RANGE',
          include: true,
          min: 0,
          max: 0,
        },
      },
      {
        label: 'WPM',
        value: {
          attribute: 'activeProspectWpm',
          type: 'RANGE',
          include: true,
          min: 0,
          max: 0,
        },
      },
      {
        label: 'AP Age',
        value: {
          attribute: 'activeProspectAge',
          type: 'RANGE',
          include: true,
          min: 0,
          max: 0,
        },
      },
      {
        label: 'AP Event Duration',
        value: {
          attribute: 'activeProspectEventDuration',
          type: 'RANGE',
          include: true,
          min: 0,
          max: 0,
        },
      },
      {
        label: 'AP Days till Expiration',
        value: {
          attribute: 'activeProspectDaysToExpire',
          type: 'RANGE',
          include: true,
          min: 0,
          max: 0,
        },
      },
      {
        label: 'AP Minutes Since Creation',
        value: {
          attribute: 'activeProspectMinutesSinceCreation',
          type: 'RANGE',
          include: true,
          min: 0,
          max: 0,
        },
      },
    ],
  },
  {
    label: 'List',
    filter: 'attribute',
    options: [
      {
        label: 'Region',
        value: {
          attribute: 'region',
          level: '',
          type: 'STATIC',
          include: true,
          values: [],
        },
      },
      {
        label: 'Postal Code',
        value: {
          attribute: 'postalCode',
          level: '',
          type: 'STATIC',
          include: true,
          values: [],
        },
      },
      {
        label: 'Pub ID',
        value: {
          attribute: 'vendorPubID',
          level: '',
          type: 'STATIC',
          include: true,
          values: [],
        },
      },
      {
        label: 'Sub ID',
        value: {
          attribute: 'vendorSubID',
          level: '',
          type: 'STATIC',
          include: true,
          values: [],
        },
      },
      {
        label: 'Landing Page',
        value: {
          attribute: 'vendorLandingPage',
          level: '',
          type: 'STATIC',
          include: true,
          values: [],
        },
      },
      {
        label: 'Vendor Source',
        value: {
          attribute: 'vendorSource',
          level: '',
          type: 'STATIC',
          include: true,
          values: [],
        },
      },
      {
        label: 'Operating System',
        value: {
          attribute: 'activeProspectOperatingSystem',
          level: '',
          type: 'STATIC',
          include: true,
          values: [],
        },
      },
      {
        label: 'AP IP',
        value: {
          attribute: 'activeProspectIp',
          level: '',
          type: 'STATIC',
          include: true,
          values: [],
        },
      },
      {
        label: 'Device',
        value: {
          attribute: 'activeProspectDevice',
          level: '',
          type: 'STATIC',
          include: true,
          values: [],
        },
      },
      {
        label: 'AP Page Url',
        value: {
          attribute: 'activeProspectPageUrl',
          level: '',
          type: 'STATIC',
          include: true,
          values: [],
        },
      },
      {
        label: 'AP Parent Page Url',
        value: {
          attribute: 'activeProspectParentPageURL',
          level: '',
          type: 'STATIC',
          include: true,
          values: [],
        },
      },
      {
        label: 'AP Page Id',
        value: {
          attribute: 'activeProspectPageID',
          level: '',
          type: 'STATIC',
          include: true,
          values: [],
        },
      },
      {
        label: 'AP Input Method',
        value: {
          attribute: 'activeProspectFormInputMethod',
          level: '',
          type: 'STATIC',
          include: true,
          values: [],
        },
      },
      {
        label: 'AP Is Framed',
        value: {
          attribute: 'activeProspectFramed',
          level: '',
          type: 'STATIC',
          include: true,
          values: [],
        },
      },
      {
        label: 'AP Country Code',
        value: {
          attribute: 'activeProspectCountryCode',
          level: '',
          type: 'STATIC',
          include: true,
          values: [],
        },
      },
      {
        label: 'AP Browser',
        value: {
          attribute: 'activeProspectBrowser',
          level: '',
          type: 'STATIC',
          include: true,
          values: [],
        },
      },
      {
        label: 'AP Time Zone',
        value: {
          attribute: 'activeProspectTimeZone',
          level: '',
          type: 'STATIC',
          include: true,
          values: [],
        },
      },
      {
        label: 'AP Domain',
        value: {
          attribute: 'activeProspectDomain',
          level: '',
          type: 'STATIC',
          include: true,
          values: [],
        },
      },
    ],
  },
  {
    label: 'Verification',
    filter: 'attributes',
    options: [
      {
        label: 'Bad Words',
        value: {
          attributes: ['words'],
          attributeType: 'string',
          functionName: 'words',
          type: 'SYSTEM',
          include: false,
          rejectMessage: 'filtered',
        },
      },
      {
        label: 'Gibberish',
        value: {
          attributes: ['gibberish'],
          attributeType: 'string',
          functionName: 'gibberish',
          type: 'SYSTEM',
          include: false,
          rejectMessage: 'filtered',
        },
      },
      {
        label: 'Current Customer',
        value: {
          attributes: ['currentCustomer'],
          attributeType: 'string',
          functionName: 'currentCustomer',
          type: 'SYSTEM',
          include: false,
          rejectMessage: 'filtered',
        },
      },
      {
        label: 'Active Demand',
        value: {
          attributes: ['demand'],
          attributeType: 'string',
          functionName: 'demand',
          rejectMessage: 'busy',
          type: 'SYSTEM',
          include: true,
        },
      },
    ],
  },
  {
    label: 'Duplicates',
    filter: 'attribute',
    options: [
      {
        label: 'Phone',
        value: {
          attribute: 'orgPhone',
          level: 'corp',
          type: 'DYNAMIC',
          include: false,
          minimumRefresh: 0,
        },
      },
      {
        label: 'Email',
        value: {
          attribute: 'email',
          level: 'corp',
          type: 'DYNAMIC',
          include: false,
          minimumRefresh: 0,
        },
      },
      {
        label: 'TCPA',
        value: {
          attribute: 'tcpa',
          attributes: ['jornayaLeadID', 'trustedFormCertID', 'trustedFormLink'],
          level: 'corp',
          type: 'DYNAMIC',
          include: false,
          minimumRefresh: 0,
        },
      },
    ],
  },
];

interface FiltersDropdownProps extends UncontrolledButtonDropdownProps {
  push: (attr: any) => void;
}

const FiltersDropdown: React.FC<FiltersDropdownProps> = ({
  push,
  filters,
  ...props
}) => {
  const { network } = useNetwork();
  const onlyHeight = useWindowHeight();

  const filterOptions = useMemo(() => {
    if (network!.povertyLevels && network!.povertyLevels.length > 0) {
      return filterCategories.concat([
        {
          label: 'Multi Level',
          filter: 'attribute',
          options: network!.povertyLevels.map((pL) => ({
            value: {
              attribute: 'povertyLevel',
              resultAttribute: 'povertyLevel',
              type: 'MULTI',
              ruleID: pL.id,
              valuesInt: [],
              include: true,
            },
            label: pL.name,
          })),
        },
      ]);
    }
    return filterCategories;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network?.povertyLevels]);

  return (
    <UncontrolledButtonDropdown {...props}>
      <DropdownToggle style={{ height: 'fit-content' }} caret>
        Add Filter
      </DropdownToggle>
      <DropdownMenu
        style={{ maxHeight: onlyHeight * 0.7, overflowY: 'scroll' }}
      >
        {filterOptions.map((category: any) => (
          <React.Fragment key={category.label}>
            <DropdownItem
              header
              className={classNames('text-primary', {
                'border-top border-secondary mt-2': category.label !== 'Time',
              })}
            >
              {category.label}
            </DropdownItem>
            {(category.filter
              ? category.options.filter((opt: any) => {
                  let value = opt.value[category.filter];
                  if (Array.isArray(value)) {
                    value = value.join(',');
                  }
                  return !filters.find((f: any) => {
                    let filterValue = f[category.filter];
                    if (Array.isArray(filterValue)) {
                      filterValue = filterValue.join(',');
                    }
                    if (f.functionName) {
                      return f.functionName === opt.value.functionName;
                    }
                    return filterValue === value && f.type === opt.value.type;
                  });
                })
              : category.options
            ).map((option: any) => (
              <DropdownItem
                id={`filter-dropdown-${category.label
                  .replace(/\s+/g, '-')
                  .toLowerCase()}-${option.label
                  .replace(/\s+/g, '-')
                  .toLowerCase()}`}
                onClick={() => push(option.value)}
                key={option.label}
              >
                {option.label}
              </DropdownItem>
            ))}
          </React.Fragment>
        ))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export default FiltersDropdown;
