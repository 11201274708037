import React, { createContext, useContext, useEffect } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { useNetwork } from '@/hooks';

export const LoadingBarContext = createContext<any>({});

let loadingBarRef: any;

const complete = () => loadingBarRef.complete();

const start = () => loadingBarRef.continuousStart();

const progress = () => {
  return loadingBarRef?.state.progress;
};

const LoadingBarProvider: React.SFC = ({ children }) => {
  const { network, loading } = useNetwork();

  return (
    <LoadingBarContext.Provider value={{ complete, start, progress }}>
      <LoadingBar
        height={3}
        className="react-loading-bar"
        color="#1285ff"
        onRef={(ref: any) => {
          loadingBarRef = ref;
        }}
      />
      {!loading && network ? children : null}
    </LoadingBarContext.Provider>
  );
};

export default LoadingBarProvider;
