import gql from 'graphql-tag';
import PriceTierFragment from '../fragments/PriceTierFragment';

export default gql`
  mutation priceTierUpsertOneMutation($input: RelayUpsertOnePriceTierInput!) {
    priceTierUpsertOne(input: $input) {
      ...PriceTierFragment
    }
  }
  ${PriceTierFragment}
`;
