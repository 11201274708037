import { QueryHookOptions, useQuery } from '@apollo/react-hooks';

import { networksQuery } from '@/graphql/queries';
import useAdmin from './useAdmin';

export default (opts?: QueryHookOptions) => {
  const { role } = useAdmin();
  const internalAdmin = ['internal_admin', 'super_internal_admin'].includes(
    role
  );

  return useQuery(networksQuery, {
    ...opts,
    skip: !internalAdmin && role !== 'support',
  });
};
