import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { SelectField } from '@availity/select';
import { SelectFieldProps } from '@availity/select/typings/SelectField';
import { vendorManyQuery } from '@/graphql/queries';
import { useNetwork } from '@/hooks';

const VendorSelect: React.SFC<SelectFieldProps<any>> = ({ ...props }) => {
  const client = useApolloClient();
  const { network } = useNetwork();

  const loadOptions = (...args) => {
    const [inputValue, , additional = {}] = args;

    return client
      .query({
        query: vendorManyQuery,
        variables: {
          networkID: network?.networkID,
          ignoreAccessControl: additional.ignoreAccessControl,
        },
      })
      .then((response) => {
        const items = response.data.vendorMany;

        return {
          options: items,
          hasMore: false,
          additional: {
            ...additional,
          },
        };
      });
  };

  return (
    <SelectField
      pagination
      additional={{
        page: 1,
      }}
      debounceTimeout={350}
      delay={350}
      labelKey="vendorName"
      valueKey="vendorID"
      defaultOptions
      raw
      loadOptions={loadOptions}
      {...props}
    />
  );
};

export default VendorSelect;
