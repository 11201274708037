import gql from 'graphql-tag';

export default gql`
  query vendorDashboardQuery($filter: DashboardFilter) {
    dashboards {
      vendors(filter: $filter) {
        total {
          name
          description
          total
          rejected
          overflow
          sold
          unsold
          leadPrice
          totalLeadPrice
          vendorPrice
          totalVendorPrice
        }
        items {
          name
          description
          total
          rejected
          overflow
          sold
          unsold
          leadPrice
          totalLeadPrice
          vendorPrice
          totalVendorPrice
        }
      }
    }
  }
`;
