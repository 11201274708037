import React, { useEffect } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
} from 'reactstrap';
import { Form, Field } from '@availity/form';
import * as yup from 'yup';
import { useNotifications, usePageLoader } from '@/hooks';
import { useMutation } from 'react-apollo';
import { createVendorMutation } from '@/graphql/mutations';

const NewVendorModal: React.FC<Omit<ModalProps, 'toggle'>> = ({
  toggle,
  isOpen,
  ...props
}) => {
  const { create: createNotification } = useNotifications();
  const { complete } = usePageLoader();

  const [createVendor] = useMutation(createVendorMutation, {
    onCompleted: () => {
      createNotification('success', 'Successfully created Vendor.');
      toggle();
      complete();
    },
    onError: (error) => {
      createNotification('danger', error.message.replace('GraphQL error:', ''));
      complete();
    },
  });

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} {...props}>
        <Form
          initialValues={{
            vendorID: '',
            vendorName: '',
          }}
          validationSchema={yup.object().shape({
            vendorID: yup
              .string()
              .required('This field is required.')
              .matches(
                /^[a-zA-Z]*$/,
                'Only letters allowed. Use camelCase for multiple words'
              ),
            vendorName: yup
              .string()
              .required('This field is required.')
              .matches(/^[a-zA-Z ]*$/, 'Only letters and spaces allowed'),
          })}
          onSubmit={(values) =>
            createVendor({
              variables: {
                input: {
                  vendorName: values.vendorName,
                  vendorID: values.vendorID,
                },
              },
            })
          }
        >
          {({ resetForm }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              if (isOpen) {
                return () => resetForm();
              }
              // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [isOpen, resetForm]);

            return (
              <>
                <ModalHeader toggle={toggle}>Create New Vendor</ModalHeader>
                <ModalBody>
                  <Field
                    name="vendorName"
                    label="Vendor Name"
                    placeholder="Enter Name..."
                  />
                  <Field
                    name="vendorID"
                    label="Vendor Id"
                    placeholder="Enter Id..."
                  />
                </ModalBody>
                <ModalFooter>
                  <Button color="success" type="submit">
                    Create
                  </Button>
                </ModalFooter>
              </>
            );
          }}
        </Form>
      </Modal>
    </>
  );
};

export default NewVendorModal;
