import React, { useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardHeader,
} from 'reactstrap';

export interface PurchasePhoneProps {
  toggle: () => void;
  isOpen: boolean;
  onAccept: () => void;
  header: React.ReactNode;
  buttonText?: ConfirmButtonProp;
  dismiss?: React.ReactElement<any, typeof Button>;
}

interface ConfirmButtonProp {
  text: string;
  color?: string;
}

const ConfirmWarningModal: React.FC<PurchasePhoneProps> = ({
  toggle,
  isOpen,
  onAccept,
  header,
  children,
  buttonText,
  dismiss,
}) => {
  const onCompleted = () => {
    onAccept();
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader wrapTag={CardHeader} toggle={toggle}>
        {header}
      </ModalHeader>
      <ModalBody>
        <div className="d-flex">
          <span>{children}</span>
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          {dismiss &&
            React.cloneElement(dismiss, {
              onClick: toggle,
            })}
          {buttonText && (
            <Button color={buttonText.color} onClick={onCompleted}>
              {buttonText.text}
            </Button>
          )}
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

ConfirmWarningModal.defaultProps = {
  dismiss: <Button>Dismiss</Button>,
};

export default ConfirmWarningModal;
