import gql from 'graphql-tag';
import { AdminFragment } from '../fragments';

export default gql`
  query currentAdminQuery {
    currentAdmin {
      ...AdminFragment
    }
  }
  ${AdminFragment}
`;
