/* eslint-disable react/jsx-boolean-value */
/* eslint-disable promise/always-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable unicorn/prefer-add-event-listener */
import React, { useEffect } from 'react';
import * as yup from 'yup';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  CardHeader,
} from 'reactstrap';
import { useMutation } from '@apollo/react-hooks';
import { Field } from '@availity/form';
import LoadingButton from '@gopro16/button';
import { SelectField } from '@availity/select';
import { Formik } from 'formik';
import { updateAgentServiceMutation } from '@/graphql/mutations';
import { useNotifications } from '@/hooks';
import { AgentServiceFragment } from '@/graphql/fragments';

const LeadRefundModal: React.FunctionComponent<{
  toggle: () => void;
  isOpen: boolean;
  deny?: boolean;
  serviceCase?: {
    caseID: string;
    deny: boolean;
  };
}> = ({ isOpen, toggle, serviceCase }) => {
  const { create: createNotification } = useNotifications();

  const [updateAgentService, { loading: updating }] = useMutation(
    updateAgentServiceMutation,
    {
      update: (cache, { data: { agentServiceUpdateOne } }) => {
        cache.writeFragment({
          id: agentServiceUpdateOne.id,
          fragment: AgentServiceFragment,
          data: agentServiceUpdateOne,
        });
      },
      onCompleted: () => {
        createNotification('success', 'Successfully processed request.');
        toggle();
      },
      onError: (error) =>
        createNotification(
          'danger',
          error.message.replace('GraphQL error:', '')
        ),
    }
  );
  return (
    <Formik
      initialValues={{
        refundType: serviceCase?.deny ? 'DENY' : 'FULL',
        summary: '',
      }}
      onSubmit={async (values) => {
        await updateAgentService({
          variables: {
            input: {
              serviceCaseID: serviceCase!.caseID,
              refundType: values.refundType,
              summary: values.summary,
            },
          },
        });
      }}
      validationSchema={yup.object().shape({})}
    >
      {({ handleSubmit, resetForm }) => {
        useEffect(() => {
          if (serviceCase) {
            resetForm();
          }
        }, [serviceCase]);
        return (
          <Modal isOpen={isOpen} toggle={toggle}>
            <Form onSubmit={handleSubmit}>
              <ModalHeader wrapTag={CardHeader} toggle={toggle}>
                {serviceCase?.deny ? 'Deny' : 'Confirm'} Refund
              </ModalHeader>
              <ModalBody>
                {!serviceCase?.deny && (
                  <SelectField
                    name="refundType"
                    label="Refund Type"
                    options={[
                      { label: 'Partial', value: 'PARTIAL' },
                      {
                        label: 'Full',
                        value: 'FULL',
                      },
                    ]}
                  />
                )}
                <Field
                  name="summary"
                  label="Summary"
                  helpMessage="Optional memo to be included."
                  placeholder="Memo..."
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={toggle}>
                  Cancel
                </Button>
                <LoadingButton
                  color="success"
                  type="submit"
                  disabled={updating}
                  loading={updating}
                >
                  Submit
                </LoadingButton>
              </ModalFooter>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default LeadRefundModal;
