import { useQuery, useMutation } from '@apollo/react-hooks';
import { useRouteMatch } from 'react-router-dom';
import { Agent } from '@/types/Agent';
import { BaseMutationOptions } from '@apollo/react-common';
import { agentNodeQuery } from '@/graphql/queries';
import { AgentFragment } from '@/graphql/fragments';
import { updateOneUserMutation } from '@/graphql/mutations';
import { useNotifications, usePageLoader } from '@/hooks';
import { ExecutionResult } from 'apollo-boost';

interface AgentUpdateResponse {
  agentUpdateOne: Agent;
}

interface AgentUpdateVariables {
  input: Partial<Agent>;
}

interface AgentHookResponse {
  user?: Agent;
  loading: boolean;
  updating: boolean;
  update: (
    options?: BaseMutationOptions<AgentUpdateResponse, AgentUpdateVariables>
  ) => Promise<ExecutionResult>;
}

const useAgent = (): AgentHookResponse => {
  const match = useRouteMatch<{
    id: string;
  }>('/agents/:id');
  const { create: createNotification } = useNotifications();
  const { complete, start } = usePageLoader();
  const { data, loading } = useQuery<{
    node: Agent;
  }>(agentNodeQuery, {
    variables: {
      id: match!.params.id,
    },
  });

  const user = data?.node;

  const [updateProfile, { loading: updating }] = useMutation(
    updateOneUserMutation,
    {
      onCompleted: () => {
        createNotification('success', 'Successfully updated Agent.');
        complete();
      },
      update(cache, { data: { agentUpdateOne } }) {
        cache.writeFragment({
          id: agentUpdateOne.id,
          fragment: AgentFragment,
          data: agentUpdateOne,
        });
      },
      onError: (error) => {
        createNotification(
          'danger',
          error.message.replace('GraphQL error:', '')
        );
        complete();
      },
    }
  );

  return {
    user,
    update: (
      options?: BaseMutationOptions<AgentUpdateResponse, AgentUpdateVariables>
    ): Promise<ExecutionResult> => {
      start();
      return updateProfile(options);
    },
    loading,
    updating,
  };
};

export default useAgent;
