import React, { useState } from 'react';
import { ListGroup, Container, Row, Col } from 'reactstrap';
import { Pagination, PaginationContent } from '@availity/pagination';
import { SelectField } from '@availity/select';
import {
  PaginationControls,
  NoItemsMessage,
  SearchSummary,
  SearchControls,
} from '@/components';
import { useApolloClient } from '@apollo/react-hooks';
import { agentServicePaginationQuery } from '@/graphql/queries';
import { Formik } from 'formik';
import { useAdmin, useNetwork, usePageLoader } from '@/hooks';
import { LeadRefundModal } from '@/modals';
import RefundListItem from './RefundListItem';

const RefundsPage: React.FunctionComponent = () => {
  const client = useApolloClient();
  const { start, complete } = usePageLoader();
  const { network } = useNetwork();
  const { role } = useAdmin();
  const [selectedServiceCase, setServiceCase] = useState<
    { caseID: string; deny: boolean } | undefined
  >(undefined);

  if (role === 'admin' && !network?.refundsVisible) return null;

  return (
    <Formik
      initialValues={{
        searchText: '',
        status: 'pending',
        refundType: undefined,
        sort: {
          created: 'DESC',
        },
      }}
      onSubmit={() => {}}
    >
      {({ values }) => {
        const fetchItems = async (
          currentPage: number,
          itemsPerPage: number
        ): Promise<any> => {
          start();
          const response = await client.query<any>({
            query: agentServicePaginationQuery,
            variables: {
              perPage: itemsPerPage,
              page: currentPage,
              filter: {
                q: values.searchText,
                status: values.status,
                networkID: network?.networkID,
                refundType: values.refundType,
              },
              orderBy: values.sort,
            },
          });

          const currentPageData = response.data?.agentServicePagination.items;

          complete();
          return {
            totalCount: response.data.agentServicePagination.count,
            items: currentPageData,
          };
          // }
        };

        return (
          <Container className="h-100 d-flex flex-column">
            <h2 className="my-4">Lead Refunds</h2>
            <Pagination
              resetParams={[
                values.searchText,
                values.status,
                values.sort,
                values.refundType,
              ]}
              watchList={[selectedServiceCase]}
              itemsPerPage={25}
              items={fetchItems}
            >
              <SearchControls
                inputPlaceholder="Search by Case or Lead ID"
                advancedSearch={
                  <Row style={{ minWidth: 530 }}>
                    <Col xs={6}>
                      <SelectField
                        name="status"
                        label="Status"
                        isClearable
                        options={[
                          { label: 'Pending', value: 'pending' },
                          { label: 'Completed', value: 'completed' },
                        ]}
                      />
                    </Col>
                    <Col xs={6}>
                      <SelectField
                        name="refundType"
                        label="Refund Type"
                        isClearable
                        options={[
                          { label: 'Denied', value: 'DENY' },
                          { label: 'Partial', value: 'PARTIAL' },
                          { label: 'Full', value: 'FULL' },
                        ]}
                      />
                    </Col>
                  </Row>
                }
              />
              <SearchSummary className="d-flex justify-content-between py-3" />
              <PaginationContent
                itemKey="serviceCaseID"
                openRefundModal={(i: string, deny?: boolean) =>
                  setServiceCase({
                    caseID: i,
                    deny: deny || false,
                  })
                }
                component={RefundListItem}
                containerTag={ListGroup}
              />
              <NoItemsMessage className="d-flex justify-content-center align-items-center pt-2 pb-2">
                No items found for given search criteria. Try expanding your
                search.
              </NoItemsMessage>
              <div className="d-flex justify-content-end">
                <PaginationControls
                  listClassName="mb-5"
                  pageRange={3}
                  autoHide
                  marginPages={0}
                  directionLinks
                  endLinks
                />
              </div>
            </Pagination>
            <LeadRefundModal
              isOpen={!!selectedServiceCase}
              toggle={() => setServiceCase(undefined)}
              serviceCase={selectedServiceCase}
            />
          </Container>
        );
      }}
    </Formik>
  );
};

export default RefundsPage;
