import gql from 'graphql-tag';

const AnalyticsUrlFragment = gql`
  fragment AnalyticsUrlFragment on AnalyticsUrl {
    __typename
    url
    timestamp
  }
`;
export default AnalyticsUrlFragment;
