import usePriceTiers from '@/areas/PriceTiers/Search/usePriceTiers';
import { useMemo } from 'react';

const usePriceTierFromVendorLayer = (layerGlobalID?: string) => {
  const {
    vendorCampaigns,
    priceTiers,
    refetchVendorCampaigns,
  } = usePriceTiers();

  const assignedTiers = useMemo(() => {
    const vcs = vendorCampaigns?.filter(
      (vc) => vc.layerGlobalID === layerGlobalID
    );

    return priceTiers?.filter((t) =>
      t.mpVendorCampaignIDs.some((mvID) =>
        vcs.some((vc) => vc.mpVendorCampaignID === mvID)
      )
    );
  }, [vendorCampaigns, priceTiers, layerGlobalID]);

  return { tiers: assignedTiers, refetchVendorCampaigns };
};

export default usePriceTierFromVendorLayer;
