import React from 'react';
import { ForgotPassword } from 'aws-amplify-react';
import { Button, Card, CardTitle, Form } from 'reactstrap';
import LoadingButton from '@gopro16/button';
import BlockUI from 'react-block-ui';
import { Formik } from 'formik';
import { Field } from '@availity/form';
import * as yup from 'yup';
import { Auth } from 'aws-amplify';
import AuthHeader from './AuthHeader';

// const SignIn = ({ onValidSubmit, onInvalidSubmit }) => (
class CustomForgotPassword extends ForgotPassword {
  public constructor(props: any) {
    super(props);
    this._validAuthStates = ['forgotPassword'];
  }

  public showComponent(): JSX.Element {
    return (
      <AuthHeader cardTitle="Reset your password">
        {this.state.delivery ? (
          <Formik
            onSubmit={async (values) => {
              const { username } = this.state;
              try {
                await Auth.forgotPasswordSubmit(
                  username,
                  values.code,
                  values.password
                );

                this.changeState('signIn');
                this.setState({ delivery: null });
              } catch (error) {
                this.error(error);
              }
            }}
            initialValues={{
              code: '',
              password: '',
            }}
            validationSchema={yup.object().shape({
              code: yup.string().required('Please enter code'),
              password: yup.string().required('Please enter a new Password.'),
            })}
          >
            {({ isSubmitting, handleSubmit, handleReset }) => (
              <Form onSubmit={handleSubmit} onReset={handleReset}>
                <div className="mb-4">
                  A Code was sent to the email associated to this account.
                </div>
                <BlockUI blocking={isSubmitting}>
                  <Field
                    id="code"
                    name="code"
                    type="text"
                    onChange={this.handleInputChange}
                    placeholder="Enter your code"
                    label="Code"
                  />
                  <Field
                    name="password"
                    type="password"
                    onChange={this.handleInputChange}
                    placeholder="Password"
                    label="New password"
                  />
                </BlockUI>
                <LoadingButton
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  color="primary"
                  size="lg"
                  type="submit"
                  block
                >
                  Send Code
                </LoadingButton>
              </Form>
            )}
          </Formik>
        ) : (
          <Formik
            onSubmit={async (values) => {
              try {
                const data = await Auth.forgotPassword(values.username);

                this.setState({
                  delivery: data.CodeDeliveryDetails,
                  username: values.username,
                });
              } catch (error) {
                this.error(error);
              }
            }}
            initialValues={{
              username: '',
            }}
            validationSchema={yup.object().shape({
              username: yup.string().required('Please enter an email.'),
            })}
          >
            {({ isSubmitting, handleSubmit, handleReset }) => (
              <Form onSubmit={handleSubmit} onReset={handleReset}>
                <BlockUI blocking={isSubmitting}>
                  <Field
                    id="username"
                    name="username"
                    type="email"
                    onChange={this.handleInputChange}
                    placeholder="Enter your username"
                    label="Username"
                  />
                </BlockUI>
                <LoadingButton
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  color="primary"
                  size="lg"
                  type="submit"
                  block
                >
                  Send Code
                </LoadingButton>
              </Form>
            )}
          </Formik>
        )}
        <div className="d-flex flex-column pt-2">
          <span>
            <Button color="link" onClick={() => this.changeState('signIn')}>
              Back to Sign In
            </Button>
          </span>
        </div>
      </AuthHeader>
    );
  }
}

export default CustomForgotPassword;
