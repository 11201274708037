import gql from 'graphql-tag';
import { VendorCampaignFragment } from '@/graphql/fragments';

export default gql`
  query vendorCampaignManyQuery($networkID: String, $layerID: String) {
    vendorCampaignMany(networkID: $networkID, layerID: $layerID) {
      ...VendorCampaignFragment
    }
  }
  ${VendorCampaignFragment}
`;
