import React, { useState } from 'react';
import { Input, InputProps } from 'reactstrap';

const MoneyInput: React.FC<
  Omit<InputProps, 'onChange'> & {
    minimum?: number;
    onChange?: (value: number) => void;
    value: number;
  }
> = ({ minimum, onChange, value, style, noCents, ...extraProps }) => {
  const valueFn = (val: number) => {
    if (noCents) {
      return val.toString();
    }
    return (val / 100).toFixed(2);
  };
  const [displayValue, updateDisplayValue] = useState<string>(valueFn(value));
  const [useControlledValue, setUseControlledValue] = useState(true);

  const setDisplayValue = (nextDisplayValue: string): void => {
    let nextvalue = displayValue;
    if (
      nextDisplayValue !== displayValue &&
      /^-?\d*(?:\.\d*)?$/.test(nextDisplayValue)
    ) {
      if (nextDisplayValue === '') {
        nextvalue = '';
        if (onChange) {
          onChange(0);
        }
      } else {
        const [dollars, cents] = nextDisplayValue.split('.');

        if (!noCents && typeof cents === 'string' && cents.length > 2) {
          const previousCents = displayValue.split('.')[1];

          if (cents.slice(1) === previousCents) {
            nextvalue = `${dollars}.${cents[0]}`;
          } else {
            nextvalue = `${dollars}.${cents.slice(0, 2)}`;
          }
        } else {
          nextvalue = nextDisplayValue;
        }

        const nextValue = noCents
          ? parseInt(nextvalue, 10)
          : Math.round(parseFloat(nextvalue) * 100);

        if (Number.isInteger(nextValue) && onChange) {
          onChange(nextValue);
        }
      }
      updateDisplayValue(nextvalue);
    }
  };

  return (
    <Input
      {...extraProps}
      onBlur={() => {
        setUseControlledValue(true);
        setDisplayValue(valueFn(value));
      }}
      onChange={(event) => setDisplayValue(event.target.value)}
      onFocus={() => {
        setUseControlledValue(false);
        setDisplayValue(valueFn(value));
      }}
      pattern="[0-9]"
      style={
        minimum && value < minimum
          ? { ...style, borderColor: '#dc3545' }
          : style
      }
      type="number"
      value={useControlledValue ? valueFn(value) : displayValue}
    />
  );
};

export default MoneyInput;
