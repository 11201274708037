/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import {
  Table,
  ButtonGroup,
  Button,
  Row,
  Col,
  Label,
  FormText,
  FormGroup,
} from 'reactstrap';
import chunk from 'lodash.chunk';
import { FaHashtag, FaFlask, FaTrash } from 'react-icons/fa';
import { Form, Field, Input } from '@availity/form';
import { FieldArray } from 'formik';
import omit from 'lodash.omit';
import * as yup from 'yup';
import { uuid } from 'uuidv4';
import { CopyButton } from '@/components';
import useAgent from '../useAgent';

interface SalesCRMProfileProps {
  crmID: string;
  profiles: any[];
  fields: any;
  iSalesUserName: string;
}

const ISalesCRMProfile: React.FC<SalesCRMProfileProps> = ({
  profiles = [],
  fields,
  iSalesUserName,
}) => {
  const { user, updating, update } = useAgent();

  return (
    <Form
      validationSchema={yup.object().shape({
        iSalesUserName: yup.string(),
        profiles: yup.array().of(
          yup
            .object()
            .shape({
              crmFields: yup.object().shape(
                fields.reduce((acc: any, field: any) => {
                  acc[field.name] = yup.string().isRequired(field.required);

                  return acc;
                }, {} as any)
              ),
            })
            .test(
              'duplicate-field',
              'Token and SID already in use.',
              (
                val: any,
                ctx: yup.TestContext<Record<string, any>>
              ): boolean => {
                const uniqueId = Object.values(val.crmFields).join('@');
                return !ctx.parent.find((profile) => {
                  if (profile.id === val.id) {
                    return false;
                  }
                  const profileID = Object.values(profile.crmFields).join('@');
                  return profileID === uniqueId;
                });
              }
            )
        ),
      })}
      initialValues={{
        profiles: profiles || [],
        iSalesUserName: iSalesUserName || '',
      }}
      onSubmit={(values) => {
        update({
          variables: {
            input: {
              id: user!.id,
              agentID: user!.agentID,
              networkID: user!.networkID,
              crmProfiles: {
                vanillaSoftDirect: {
                  ...user!.crmProfiles!.vanillaSoftDirect,
                  label: 'VanillaSoft',
                  profiles: user!.crmProfiles!.vanillaSoftDirect?.profiles?.map(
                    (p) => ({
                      ...omit(p, ['__typename', 'profileID']),
                      id: p.profileID || p.id,
                    })
                  ),
                },
                convoso: {
                  ...user!.crmProfiles!.convoso,
                  label: 'Convoso',
                  profiles: user!.crmProfiles!.convoso?.profiles?.map((p) => ({
                    ...omit(p, ['__typename', 'profileID']),
                    id: p.profileID || p.id,
                  })),
                },
                iSalesCRM: {
                  ...user!.crmProfiles!.iSalesCRM,
                  iSalesUserName: values.iSalesUserName.trim(),
                  label: 'ISalesCRM',
                  profiles: values.profiles?.map((p) => ({
                    ...omit(p, ['__typename', 'profileID']),
                    id: p.profileID || p.id,
                  })),
                },
              },
            },
          },
        });
      }}
    >
      {({ values, resetForm, errors }) => {
        useEffect(() => {
          resetForm();
        }, [user!.agentID]);

        return (
          <div>
            <div className="mb-3">
              <FormGroup>
                <Label>ISalesCRM Email</Label>
                <Input type="email" name="iSalesUserName" />
                <FormText>
                  Enter the email you use to log into ISalesCRM
                </FormText>
              </FormGroup>
            </div>
            <Table className="border-bottom border-secondary">
              <thead>
                <tr>
                  <th colSpan={2} className="border-0">
                    Profiles
                  </th>
                </tr>
              </thead>
              <tbody>
                <FieldArray
                  name="profiles"
                  render={(arrayHelpers) => (
                    <>
                      {values.profiles?.map((profile, profileIdx) => (
                        <tr key={profile.id}>
                          <td className="d-flex flex-column">
                            <span>
                              <FaHashtag className="mb-2" />
                              <h4 className="d-inline">{profileIdx + 1}</h4>
                            </span>
                            <div>
                              <ButtonGroup vertical>
                                <Button
                                  color="info"
                                  disabled
                                  title="Test profile (COMING SOON)"
                                >
                                  <FaFlask />
                                </Button>
                                <Button
                                  color="danger"
                                  title="Delete Profile"
                                  id={`delete-profile-${profileIdx}`}
                                  onClick={() =>
                                    arrayHelpers.remove(profileIdx)
                                  }
                                >
                                  <FaTrash />
                                </Button>
                              </ButtonGroup>
                            </div>
                          </td>
                          <td>
                            <Row noGutters>
                              <Col xs={12} className="mb-2">
                                <small className="text-muted">{`${
                                  user!.agentID
                                }@iSalesCRM@agent@${profile.profileID}`}</small>
                                <CopyButton
                                  content={`${user!.agentID}@iSalesCRM@agent@${
                                    profile.profileID
                                  }`}
                                  className="ml-3 float-right"
                                />
                              </Col>
                              <Col xs={12}>
                                <Field
                                  label="Description"
                                  placeholder="A descriptive name for this profile"
                                  name={`profiles.${profileIdx}.description`}
                                />
                              </Col>
                              {chunk(fields, 2).map((chunk: any) =>
                                chunk.map((subChunk: any) => {
                                  return (
                                    <Col
                                      key={`profiles.${profileIdx}.crmFields.${subChunk.name}`}
                                      xs={chunk.length === 2 ? 6 : 12}
                                    >
                                      <Field
                                        label={subChunk.label}
                                        placeholder={subChunk.placeholder}
                                        type={subChunk.type}
                                        name={`profiles.${profileIdx}.crmFields.${subChunk.name}`}
                                      />
                                    </Col>
                                  );
                                })
                              )}
                              <div className="text-danger">
                                {typeof errors?.profiles?.[profileIdx] ===
                                'string'
                                  ? errors.profiles[profileIdx]
                                  : undefined}
                              </div>
                            </Row>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={2}>
                          <Button
                            color="success"
                            className="w-100"
                            onClick={() =>
                              arrayHelpers.push({
                                crmFields: fields.reduce(
                                  (acc: any, field: any) => ({
                                    ...acc,
                                    [field.name]: '',
                                  }),
                                  {}
                                ),
                                id: `iSalesCRM-${uuid()}`,
                              })
                            }
                          >
                            Add New Profile
                          </Button>
                        </td>
                      </tr>
                    </>
                  )}
                />
              </tbody>
            </Table>
            <Button
              className="float-right"
              color="primary"
              type="submit"
              disabled={updating}
            >
              Save Changes
            </Button>
            <Button className="mr-1 float-right" color="secondary" type="reset">
              Reset Changes
            </Button>
          </div>
        );
      }}
    </Form>
  );
};

export default ISalesCRMProfile;
