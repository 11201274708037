import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Row,
  Col,
} from 'reactstrap';
import { useNetwork } from '@/hooks';
import { EditCompanyListsModal } from '@/modals';
import { useQuery } from '@apollo/react-hooks';
import { networkDetailsQuery } from '@/graphql/queries';
import classNames from 'classnames';

const NetworksListsTab: React.FC = () => {
  const [listModal, setListModal] = useState(false);
  const [listType, setListType] = useState('');
  const toggleListModal = (): void => setListModal((o) => !o);

  const { network } = useNetwork();

  const { data, loading, refetch } = useQuery(networkDetailsQuery, {
    variables: { id: network?.id },
  });
  const list = data?.currentNetwork?.companyLists;
  const postalCodeDisplayValues = list?.postalCode?.values.replaceAll(
    '\n',
    ', '
  );

  if (loading && !data) return null;

  return (
    <div>
      <Card className="m-3">
        <CardHeader className="d-flex align-items-middle">
          <CardTitle
            tag="h5"
            className="py-2 h4 mb-0 text-uppercase d-flex align-items-center"
          >
            Company Lists
          </CardTitle>
        </CardHeader>
        <CardBody tag={Row} noGutters>
          <Col xs={12}>
            <Card className="bg-secondary py-2 px-4">
              <Row className="d-flex align-items-center">
                <Col xs={2}>
                  <div className="bg-primary rounded text-center font-weight-bold py-1">
                    Postal Codes:
                  </div>
                </Col>
                <Col className="d-flex justify-content-center">
                  <div>
                    {postalCodeDisplayValues}
                    <span
                      className={classNames('ml-2', {
                        'd-none': list?.postalCode?.total - 25 <= 0,
                      })}
                    >
                      ...and {list?.postalCode?.total - 25} more.
                    </span>
                  </div>
                </Col>
                <Col xs={1}>
                  <Button
                    color="dark"
                    onClick={() => {
                      toggleListModal();
                      setListType('postalCode');
                    }}
                  >
                    Edit
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} className="mt-3">
            <Card className="bg-secondary py-2 px-4">
              <Row className="d-flex align-items-center">
                <Col xs={2}>
                  <div className="bg-primary rounded text-center font-weight-bold py-1">
                    Regions:
                  </div>
                </Col>
                <Col className="d-flex justify-content-center">
                  <div>{list?.region?.replaceAll('\n', ', ')}</div>
                </Col>
                <Col xs={1}>
                  <Button
                    color="dark"
                    onClick={() => {
                      toggleListModal();
                      setListType('region');
                    }}
                  >
                    Edit
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </CardBody>
      </Card>
      <EditCompanyListsModal
        isOpen={listModal}
        toggle={toggleListModal}
        list={list}
        listType={listType}
        refetch={refetch}
      />
    </div>
  );
};

export default NetworksListsTab;
