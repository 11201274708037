import gql from 'graphql-tag';
import VendorLayerFragment from '../fragments/VendorLayerDetailFragment';

export default gql`
  mutation vendorLayerUpsertOneMutation($input: CreateVendorLayerInput) {
    vendorLayerUpsertOne(input: $input) {
      ...VendorLayerDetailFragment
    }
  }
  ${VendorLayerFragment}
`;
