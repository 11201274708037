import gql from 'graphql-tag';
import { AgentServiceFragment } from '../fragments';

export default gql`
  mutation updateAgentService($input: LeadRefundAction) {
    agentServiceUpdateOne(input: $input) {
      ...AgentServiceFragment
    }
  }
  ${AgentServiceFragment}
`;
