import React, { ReactElement, useContext, useEffect } from 'react';
import {
  CopyButton,
  DebouncedInput,
  FormikSwitch,
  MoneyInput,
  SwitchButton,
} from '@/components';
import { Field } from '@availity/form';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { FaExclamationCircle, FaQuestionCircle, FaTrash } from 'react-icons/fa';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Label,
} from 'reactstrap';
import { AttributesContext } from '@/context';
import { SelectField } from '@availity/select';
import { useNetwork } from '@/hooks';

interface CategoryMapFieldProps {
  index: number;
  mpVendorCampaignID: string;
  apBidEnhancmentEnabled: boolean;
  allowMLPriceOverride: boolean;
  remove: () => void;
}

interface CategoryFieldProps {
  fieldIndex: number;
  index: number;
  fieldName: string;
  mpVendorCampaignID?: string;
  remove: () => void;
  lastIndex?: boolean;
  errors?: any;
}

const CopyRemoveButtons: React.FC<any> = ({
  mpVendorCampaignID,
  lastIndex,
  remove,
  selectAddon,
}) => (
  <>
    {mpVendorCampaignID && lastIndex ? (
      <div style={{ zIndex: 0, ...(selectAddon && { marginTop: 31 }) }}>
        <CopyButton color="dark" content={mpVendorCampaignID} />
        <Button color="danger" onClick={() => remove()}>
          <FaTrash />
        </Button>
      </div>
    ) : // eslint-disable-next-line unicorn/no-nested-ternary
    lastIndex ? (
      <div style={{ zIndex: 0, ...(selectAddon && { marginTop: 31 }) }}>
        <Button color="danger" onClick={() => remove()}>
          <FaTrash />
        </Button>
      </div>
    ) : null}
  </>
);

const CategoryField: React.FC<CategoryFieldProps> = ({
  fieldIndex,
  index,
  fieldName,
  mpVendorCampaignID,
  remove,
  lastIndex,
  errors,
}) => {
  const [{ value: attributeRanges }] = useField(`attributeRanges`);
  const { getAttribute } = useContext(AttributesContext);
  const attribute = getAttribute?.(fieldName);
  const { network } = useNetwork();

  const lastField = (mpVendorCampaignID && lastIndex) || lastIndex;
  const fieldError =
    errors.vendorCampaigns?.[0]?.categoryFields?.[fieldIndex] || '';
  const selectFieldValue = (att: string, rangeType?: string): ReactElement => {
    let options;
    if (att === 'state')
      options = network?.allowedStates?.map((state) => ({
        label: state,
        value: state,
      }));
    if (att === 'boolean')
      options = [
        { label: 'True', value: 'true' },
        { label: 'False', value: 'false' },
      ];
    if (att === 'range') {
      const lowestBound = attributeRanges?.[rangeType as string]?.lowestBound;
      const highestBound = attributeRanges?.[rangeType as string]?.highestBound;
      const nextSegment = attributeRanges?.[rangeType as string]?.nextSegment;
      const rangeValues = [`Less than ${lowestBound}`];
      for (let step = lowestBound; step <= highestBound; step += nextSegment) {
        let highBound = step + nextSegment - 1;
        if (highBound > highestBound) highBound = highestBound;
        rangeValues.push(`${step} to ${highBound}`);
      }
      rangeValues.push(`Greater than ${highestBound}`);
      options = rangeValues.map((value) => ({ label: value, value }));
    }

    if (att === 'string' || att === 'integer') {
      return (
        <>
          <Col
            className={classNames({
              'd-flex': lastField,
            })}
          >
            <div style={{ ...(lastField && { width: 145 }) }}>
              <span className="mb-4">
                {attribute?.sourceAttribute || `Field ${fieldIndex + 1}`}{' '}
                {att === 'string' && (
                  <>
                    <FaExclamationCircle
                      className="cursor-pointer text-warning"
                      id={`text-field-warning-tooltip-${index}-${fieldIndex}`}
                    />
                    <UncontrolledTooltip
                      style={{ textAlign: 'left' }}
                      target={`text-field-warning-tooltip-${index}-${fieldIndex}`}
                    >
                      <span className="text-warning">Reminder: </span>
                      <span>
                        Any leads that do not meet the criteria of all available
                        categories will go to the default category with name
                        matching the lead source description. If the value below
                        is not an exact match, the default category will be
                        used.
                      </span>
                    </UncontrolledTooltip>
                  </>
                )}
              </span>
              {att === 'integer' ? (
                <DebouncedInput
                  id={`vendorCampaigns.${index}.categoryFields.${fieldIndex}.value`}
                  name={`vendorCampaigns.${index}.categoryFields.${fieldIndex}`}
                  type="number"
                  min={1}
                  className="mt-2"
                  placeholder="Select..."
                />
              ) : (
                <DebouncedInput
                  id={`vendorCampaigns.${index}.categoryFields.${fieldIndex}.value`}
                  name={`vendorCampaigns.${index}.categoryFields.${fieldIndex}`}
                  className="mt-2"
                  placeholder="Input Text..."
                />
              )}
            </div>
            <CopyRemoveButtons
              mpVendorCampaignID={mpVendorCampaignID}
              lastIndex={lastIndex}
              remove={remove}
              selectAddon
            />
          </Col>
          {fieldError && (
            <small className="text-danger ml-3 w-100">{fieldError}</small>
          )}
        </>
      );
    }

    return (
      <Col
        className={classNames({
          'd-flex': lastField,
        })}
      >
        <div style={{ ...(lastField && { width: 145 }) }}>
          <SelectField
            name={`vendorCampaigns.${index}.categoryFields.${fieldIndex}`}
            placeholder="Select..."
            label={attribute?.sourceAttribute || `Field ${fieldIndex + 1}`}
            labelKey="label"
            valueKey="value"
            options={[].concat((options as []) || [])}
          />
          {}
        </div>
        <CopyRemoveButtons
          mpVendorCampaignID={mpVendorCampaignID}
          lastIndex={lastIndex}
          remove={remove}
          selectAddon
        />
      </Col>
    );
  };

  if (attribute?.sourceAttribute === 'state') {
    return <>{selectFieldValue('state')}</>;
  }

  switch (attribute?.attributeType) {
    case 'integerRange':
      return (
        <>
          {selectFieldValue(
            'range',
            attribute.internalAttribute.replace('Range', '')
          )}
        </>
      );
    case 'boolean':
    case 'booleanAsString':
      return <>{selectFieldValue('boolean')}</>;
    case 'integer':
      return <>{selectFieldValue('integer')}</>;
    case 'string':
    default:
      return <>{selectFieldValue('string')}</>;
  }
};

const CategoryMapField: React.FC<CategoryMapFieldProps> = ({
  index,
  mpVendorCampaignID,
  apBidEnhancmentEnabled,
  allowMLPriceOverride,
  remove,
}) => {
  const [{ value: fieldNames }] = useField('vendorSourceCampaignMapArray');
  const [{ value: customPricingEnabled }] = useField(
    `vendorCampaigns.${index}.customPricingEnabled`
  );
  const [{ value: percentEnabled }] = useField(
    `vendorCampaigns.${index}.defaultPercentageEnabled`
  );
  const [{ value: price }] = useField(`vendorCampaigns.${index}.defaultPrice`);
  const [{ value: dynamicPricingEnabled }] = useField(
    `vendorCampaigns.${index}.dynamicPricingEnabled`
  );
  const [{ value: apBidEnhancementCategoryOveride }] = useField(
    `vendorCampaigns.${index}.apBidEnhancementCategoryOveride`
  );
  const [{ value: overrideMLPrice }] = useField(
    `vendorCampaigns.${index}.overrideMLPrice`
  );

  const [{ value: overrideMaxMLPrice }] = useField(
    `vendorCampaigns.${index}.overrideMaxMLPrice`
  );

  const { setFieldValue, errors } = useFormikContext();

  useEffect(() => {
    if (!dynamicPricingEnabled) {
      setFieldValue(`vendorCampaigns.${index}.defaultPercentageEnabled`, false);
    }
  }, [dynamicPricingEnabled]);

  useEffect(() => {
    if (!apBidEnhancementCategoryOveride) {
      setFieldValue(
        `vendorCampaigns.${index}.apBidEnhancementCategoryOveride`,
        false
      );
    }
  }, [apBidEnhancementCategoryOveride]);

  useEffect(() => {
    if (!overrideMLPrice) {
      setFieldValue(`vendorCampaigns.${index}.overrideMLPrice`, false);
    }
  }, [overrideMLPrice]);

  useEffect(() => {
    if (!overrideMaxMLPrice) {
      setFieldValue(`vendorCampaigns.${index}.overrideMaxMLPrice`, false);
    }
  }, [overrideMaxMLPrice]);

  return (
    <>
      <Row noGutters>
        {fieldNames?.map((fieldName: string, fieldIndex: number) => (
          <Col
            xs={3}
            key={`field-${index}-${fieldIndex}-${mpVendorCampaignID}`}
          >
            <CategoryField
              index={index}
              fieldName={fieldName}
              fieldIndex={fieldIndex}
              lastIndex={fieldIndex === fieldNames.length - 1}
              mpVendorCampaignID={mpVendorCampaignID}
              remove={remove}
              errors={errors}
            />
          </Col>
        ))}
        {allowMLPriceOverride ? (
          <>
            <Col xs={2}>
              <Label>Override ML Price</Label>
              <SwitchButton
                width={120}
                checked={overrideMLPrice}
                onlabel="On"
                offlabel="Off"
                onstyle="success"
                offstyle="primary"
                onChange={() => {
                  setFieldValue(
                    `vendorCampaigns.${index}.overrideMLPrice`,
                    !overrideMLPrice
                  );
                }}
              />
              <FaQuestionCircle
                className="ml-2 cursor-pointer text-primary"
                id="attributes-tooltip-overrideMLPrice"
              />
              <UncontrolledTooltip
                placement="right"
                target="attributes-tooltip-overrideMLPrice"
                style={{ textAlign: 'left' }}
              >
                Override the ml bid price
              </UncontrolledTooltip>
            </Col>
            <Col xs={2}>
              <Label>Override Max ML Price</Label>
              <SwitchButton
                width={120}
                checked={overrideMaxMLPrice}
                onlabel="On"
                offlabel="Off"
                onstyle="success"
                offstyle="primary"
                onChange={() => {
                  setFieldValue(
                    `vendorCampaigns.${index}.overrideMaxMLPrice`,
                    !overrideMaxMLPrice
                  );
                }}
              />
              <FaQuestionCircle
                className="ml-2 cursor-pointer text-primary"
                id="attributes-tooltip-overrideMaxMLPrice"
              />
              <UncontrolledTooltip
                placement="right"
                target="attributes-tooltip-overrideMaxMLPrice"
                style={{ textAlign: 'left' }}
              >
                Override the ml bid price if greater than max
              </UncontrolledTooltip>
            </Col>
          </>
        ) : null}
        {apBidEnhancmentEnabled ? (
          <Col xs={2}>
            <Label>Prevent Bid Enhancement</Label>
            <SwitchButton
              width={120}
              checked={apBidEnhancementCategoryOveride}
              onlabel="On"
              offlabel="Off"
              onstyle="success"
              offstyle="primary"
              onChange={() => {
                setFieldValue(
                  `vendorCampaigns.${index}.apBidEnhancementCategoryOveride`,
                  !apBidEnhancementCategoryOveride
                );
              }}
            />
            <FaQuestionCircle
              className="ml-2 cursor-pointer text-primary"
              id="attributes-tooltip-apBidEnhancementCategoryOveride"
            />
            <UncontrolledTooltip
              placement="right"
              target="attributes-tooltip-apBidEnhancementCategoryOveride"
              style={{ textAlign: 'left' }}
            >
              Override and do not use any Bid Enhancement
            </UncontrolledTooltip>
          </Col>
        ) : null}
      </Row>
      <Row noGutters className="mt-2 ml-3">
        <Col xs={2.6} className="d-flex align-items-center">
          <div className="d-flex">
            <span className="text-light font-weight-bold">CUSTOM PRICING</span>
            <FaQuestionCircle
              className="text-primary cursor-pointer ml-1 mt-1"
              id={`pricing-tooltip-${index}`}
            />
            <UncontrolledTooltip
              target={`pricing-tooltip-${index}`}
              style={{ textAlign: 'left' }}
            >
              When custom pricing is enabled, it allows for the category to
              override the default lead source price. If the percentage is
              selected, the category price will adjust the default lead source
              price by the percentage.
            </UncontrolledTooltip>
            <FormikSwitch
              className="ml-2"
              name={`vendorCampaigns.${index}.customPricingEnabled`}
            />
          </div>
        </Col>
      </Row>
      <Row
        className={classNames('mx-3', {
          'd-none': !customPricingEnabled,
        })}
      >
        <Col className="d-flex" xs={1.5}>
          {percentEnabled ? (
            <Field
              name={`vendorCampaigns.${index}.defaultPercentage`}
              type="number"
              style={{ maxWidth: '100px' }}
              step={1}
            />
          ) : (
            <div>
              <MoneyInput
                id={`${index}-default-price-money-input`}
                onChange={(v: number) =>
                  setFieldValue(`vendorCampaigns.${index}.defaultPrice`, v)
                }
                value={price || 0}
                style={{ maxWidth: 100 }}
                className="mb-3"
              />
              <small className="text-danger">
                {errors?.vendorCampaigns?.[index]?.defaultPrice}
              </small>
            </div>
          )}
          <UncontrolledDropdown
            id={`${index}-price-type-group-dropdown`}
            className={classNames({ 'd-none': !dynamicPricingEnabled })}
          >
            <DropdownToggle
              id={`${index}-price-type-dropdown-toggle`}
              caret
              className="bg-dark"
              style={{ height: 37, width: 50 }}
            >
              {percentEnabled ? '%' : '$'}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                id={`${index}-price-type-dropdown-dollar`}
                active={!percentEnabled}
                onClick={() =>
                  setFieldValue(
                    `vendorCampaigns.${index}.defaultPercentageEnabled`,
                    false
                  )
                }
              >
                $
              </DropdownItem>
              <DropdownItem
                id={`${index}-price-type-dropdown-percent`}
                active={percentEnabled}
                onClick={() =>
                  setFieldValue(
                    `vendorCampaigns.${index}.defaultPercentageEnabled`,
                    true
                  )
                }
              >
                %
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
        <Col>
          <Row>
            <Col xs={2}>
              <SwitchButton
                width={120}
                checked={dynamicPricingEnabled}
                onlabel="Dynamic"
                offlabel="Fixed"
                onstyle="success"
                offstyle="primary"
                onChange={() => {
                  setFieldValue(
                    `vendorCampaigns.${index}.dynamicPricingEnabled`,
                    !dynamicPricingEnabled
                  );
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CategoryMapField;
