import React from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalProps,
  ModalHeader,
  Button,
} from 'reactstrap';
import { Agent } from '@/types/Agent';
import { useMutation } from '@apollo/react-hooks';
import { updateOneUserMutation } from '@/graphql/mutations';
import { useHistory } from 'react-router-dom';
import { useNotifications } from '@/hooks';

export interface AgentsConfirmDeleteModalProps extends ModalProps {
  user: Agent;
}

const AgentsConfirmDeleteModal: React.SFC<AgentsConfirmDeleteModalProps> = ({
  user,
  ...props
}) => {
  const history = useHistory();
  const { create: createNotification } = useNotifications();
  const [deleteUser, { loading: updating }] = useMutation(
    updateOneUserMutation,
    {
      variables: {
        input: {
          agentID: user?.agentID,
          networkID: user?.networkID,
          profileID: user?.profileID,
          subID: user?.subID,
          id: user?.id,
          status: 'deleted',
          agentApproved: false,
        },
      },
      update: (cache) => {
        cache.writeData({
          id: user.id,
          data: {
            status: 'deleted',
          },
        });
      },
      onCompleted: () => {
        createNotification('success', 'Successfully deleted Agent.');
        history.push('/agents');
      },
      onError: () => {
        createNotification('danger', 'Failed to delete agent.');
      },
    }
  );

  return (
    <Modal {...props}>
      <ModalHeader>Delete User Confirmation</ModalHeader>
      <ModalBody>
        Deleting this user will auto pause all of his/her campaigns. You can
        still find this user in the search page by filtering by deleted agents.
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <Button color="secondary" onClick={() => props.toggle()}>
          Cancel
        </Button>
        <Button color="danger" onClick={() => deleteUser()} disabled={updating}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AgentsConfirmDeleteModal;
