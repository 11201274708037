import React from 'react';
import { Card } from 'reactstrap';
import { useDrag, useDrop } from 'react-dnd';
import classNames from 'classnames';
import { useMutation } from '@apollo/react-hooks';
import { queueAddTierMutation } from '@/graphql/mutations';
import { useNotifications } from '@/hooks';

export const DroppableTier: React.FC<any> = ({ name, priceTierID }) => {
  const [{ opacity }, drag] = useDrag<any, any, any>(
    () => ({
      type: 'TIER',
      item: { name, priceTierID },
      //   collect: (monitor) => ({
      //     opacity: monitor.isDragging() ? 0.4 : 1,
      //   }),
    }),
    [name]
  );
  return (
    <div
      className="badge badge-primary mx-2 mb-2 cursor-pointer"
      style={{ fontSize: 14 }}
      ref={drag}
    >
      {name}
    </div>
  );
};

const TierDropZone: React.FC<{
  tiers: any[];
  queueID: string;
  updatingAssignments: boolean;
  addTierToQueue: any;
}> = ({ tiers, addTierToQueue, queueID, updatingAssignments }) => {
  const [{ isOver, canDrop }, drop] = useDrop(
    {
      accept: 'TIER',
      canDrop: (item) => {
        return (
          !updatingAssignments &&
          !tiers.find((t) => t.priceTierID === item.priceTierID)
        );
      },
      drop: (e) => {
        addTierToQueue({
          variables: {
            input: {
              queueID,
              tierID: e.priceTierID,
            },
          },
        });
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    },
    [updatingAssignments, tiers]
  );

  const isActive = isOver && canDrop;

  return (
    <div
      ref={drop}
      className={classNames(
        'border my-3 p-3 rounded border-primary',
        isActive && 'bg-warning'
      )}
    >
      {tiers?.map((tier: any) => (
        <DroppableTier key={tier.priceTierID} {...tier} />
      ))}
    </div>
  );
};

export default TierDropZone;
