import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Input, Badge, Col, Row, ListGroupItem } from 'reactstrap';
import { Formik } from 'formik';
import moment from 'moment';
import { useNotifications } from '@/hooks';
import { toggleQueueMutation, updateQueueMutation } from '@/graphql/mutations';
import {
  serverStatusMapping,
  deploymentStatusMapping,
  latestBadge,
} from '@/utils/infastructureUtils';
import { CopyButton, SwitchButton } from '@/components';
import { CustomPriceTier } from '../PriceTiers/Search/usePriceTiers';
import TierDropZone from './TierDropZone';

type InfastructureRowProps = {
  id: string;
  instanceID: string;
  fixedCampaignName: string;
  lastCompletionEpoch: number;
  logs: string;
  status: string;
  loopDelay: number;
  cycleDuration: number;
  selectedQueues: string[];
  version: string;
  DEPLOYMENT_STATUS: string;
  SERVER_STATUS: string;
  addQueue: (queue: any) => void;
  removeQueue: (instanceId: string) => void;
  latestVersion: boolean;
  tiers: CustomPriceTier[];
  addTierToQueue: any;
};
const InfastructureRow: React.SFC<InfastructureRowProps> = ({
  instanceID,
  fixedCampaignName,
  lastCompletionEpoch,
  addTierToQueue,
  logs,
  status,
  tiers,
  loopDelay: _loopDelay,
  cycleDuration = 0,
  id,
  selectedQueues = [],
  version = '',
  DEPLOYMENT_STATUS,
  updatingAssignments,
  SERVER_STATUS = '',
  latestVersion = false,
  addQueue = () => {},
  removeQueue = () => [],
}) => {
  const { create } = useNotifications();

  const [togglePause, { loading: toggling }] = useMutation(
    toggleQueueMutation,
    {
      update: (_cache, { data: { toggleQueue } }) => {
        const { success } = toggleQueue;

        if (!success) {
          create('danger', 'Failed to toggle queue', 3000);
        } else {
          _cache.writeData({
            id,
            data: {
              status: status === 'ON' ? 'OFF' : 'ON',
            },
          });
        }
      },
      onCompleted: () => create('success', 'Successfully toggled queue', 3000),
      onError: () => create('danger', 'Failed to toggle queue', 3000),
    }
  );

  const [updateQueue] = useMutation(updateQueueMutation, {
    update: (_cache, { data: { updateQueue } }) => {
      const { success } = updateQueue;

      if (!success) {
        create('danger', 'Failed to update queue', 3000);
      }
    },
    onCompleted: () => create('success', 'Successfully updated queue', 3000),
    onError: () => create('danger', 'Failed to update queue', 3000),
  });

  const loopDelay = _loopDelay || 0;

  const toggleCheckbox = () => {
    if (
      selectedQueues.some(
        ({ instanceID: _instanceId }: any) => _instanceId === instanceID
      )
    ) {
      removeQueue(instanceID);
    } else {
      addQueue({
        instanceID,
        fixedCampaignName,
        version,
        DEPLOYMENT_STATUS,
        SERVER_STATUS,
        id,
      });
    }
  };

  return (
    <ListGroupItem className="mb-3">
      <Formik
        initialValues={{
          logs: logs === 'ON',
          status: status === 'ON',
          loopDelay: loopDelay / 1000,
        }}
        onSubmit={({ logs, status, loopDelay }) => {
          updateQueue({
            variables: {
              input: {
                id,
                status: status ? 'ON' : 'OFF',
                logs: logs ? 'ON' : 'OFF',
                loopDelay: loopDelay * 1000,
              },
            },
          });
        }}
      >
        {({ values, setFieldValue, resetForm, submitForm }) => {
          const isChange =
            values.logs !== (logs === 'ON') ||
            values.status !== (status === 'ON') ||
            values.loopDelay * 1000 !== loopDelay;
          return (
            <Row noGutters>
              <Col xs={1} className="d-flex flex-column text-center">
                <span className="h5">
                  <Input
                    style={{ width: 20, height: 20 }}
                    type="checkbox"
                    className="cursor-pointer"
                    value={instanceID}
                    onClick={() => {
                      // If instance is selected
                      toggleCheckbox();
                    }}
                    checked={selectedQueues.some(
                      ({ instanceID: _instanceId }: any) =>
                        _instanceId === instanceID
                    )}
                  />
                  <small />
                </span>
              </Col>
              <Col xs={2} className="d-flex flex-column text-center">
                <span className="h5">{instanceID}</span>
                <small>Instance ID</small>
              </Col>
              <Col xs={1} className="d-flex flex-column text-center">
                <span className="h5">
                  {latestVersion ? (
                    latestBadge
                  ) : (
                    <Badge
                      style={{
                        fontSize: '.9rem',
                      }}
                      color={`${version ? 'warning' : 'danger'}`}
                      title="Queue Version"
                      className="ml-2"
                    >
                      {version ? `v${version}` : 'unknown'}
                    </Badge>
                  )}
                </span>
                <small>Version</small>
              </Col>
              <Col xs={1} className="d-flex flex-column text-center">
                <span className="h5">
                  <SwitchButton
                    width={70}
                    checked={status === 'ON'}
                    disabled={toggling}
                    onlabel="On"
                    offlabel="Off"
                    onstyle="success"
                    offstyle="primary"
                    onChange={() => {
                      togglePause({
                        variables: {
                          input: {
                            ids: [id],
                            paused: status === 'ON',
                          },
                        },
                      });
                    }}
                  />
                </span>
              </Col>
              <Col xs={1} className="d-flex flex-column text-center">
                <span className="h5">{tiers?.length || 1}</span>
                <small>Tier{tiers?.length > 1 ? 's' : ''}</small>
              </Col>
              <Col xs={1} className="d-flex flex-column text-center">
                <span className="h5">
                  {(cycleDuration / 1000).toFixed(2)} seconds
                </span>
                <small>Cycle Time</small>
              </Col>
              <Col xs={2} className="d-flex flex-column text-center">
                <span className="h5">
                  {lastCompletionEpoch !== 0
                    ? moment(lastCompletionEpoch).fromNow()
                    : 'N/A'}
                </span>
                <small>Last Loop</small>
              </Col>
              <Col xs={1} className="d-flex flex-column text-center">
                <span className="h5">{serverStatusMapping(SERVER_STATUS)}</span>
                <small>Server Status</small>
              </Col>
              <Col xs={1} className="d-flex flex-column text-center">
                <span className="h5">
                  {deploymentStatusMapping(DEPLOYMENT_STATUS)}
                </span>
                <small>Deploy Status</small>
              </Col>
              <Col xs={1}>
                <CopyButton
                  id={`${instanceID}-copy-button`}
                  content={instanceID}
                  className="float-right"
                />
              </Col>
            </Row>
          );
        }}
      </Formik>
      <TierDropZone
        updatingAssignments={updatingAssignments}
        queueID={instanceID}
        tiers={tiers}
        addTierToQueue={addTierToQueue}
      />
    </ListGroupItem>
  );
};

export default InfastructureRow;
