import gql from 'graphql-tag';
import AgentFragment from './AgentFragment';
import LeadFragment from './LeadFragment';

const AgentServiceFragment = gql`
  fragment AgentServiceFragment on AgentService {
    id
    __typename
    serviceCaseID
    agentID
    leadID
    networkID
    leadPhone
    modified
    refundOption
    refundReason
    refundType
    soldDate
    status
    emailValid
    created
    type
    amount
    summary
    lead {
      ...LeadFragment
    }
    agent {
      ...AgentFragment
    }
    updatedBy {
      ...AgentFragment
    }
  }
  ${LeadFragment}
  ${AgentFragment}
`;

export default AgentServiceFragment;
