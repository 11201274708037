/* eslint-disable import/no-cycle */
/* eslint-disable no-useless-computed-key */
/* eslint-disable no-empty */
/* eslint-disable no-case-declarations */
import { ReportFragment } from '@/graphql/fragments';
import { reportManyQuery } from '@/graphql/queries';
import { useApolloClient, useSubscription } from '@apollo/react-hooks';
import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { reportNotificationSubscription } from '@/graphql/subscriptions';
import { uuid } from 'uuidv4';
import { Alert } from 'reactstrap';
import { Vars } from '@/utils';
import { CurrentUserContext } from './CurrentUserContext';
import { NetworkContext } from './NetworkContext';

export interface Notification {
  color: string;
  dismiss: () => void;
  id: string;
  message: string;
}

type ReportData = {
  url: string;
  fileName: string;
  type: string;
  id: string;
  message: string;
  cleanName: string;
  creationDate: number;
  status: string;
};

export interface TabNotification {
  report: boolean;
}

export interface NotificationContextType {
  create: (
    color: string,
    message: string,
    timeout?: number,
    _id?: string
  ) => void;
  notifications: Notification[];
  tabNotification: TabNotification;
  setTabNotification: (obj: TabNotification) => void;
}

export const NotificationContext = createContext<NotificationContextType>({
  create: () => {},
  notifications: [],
  tabNotification: {
    report: false,
  },
  setTabNotification: () => {},
});

const NotificationProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [tabNotification, setTabNotification] = useState<TabNotification>({
    report: false,
  });

  const { agentID } = useContext(CurrentUserContext);
  const { network } = useContext(NetworkContext);
  const client = useApolloClient();
  const create = useCallback(
    (color: string, message: string, timeout = 5000, _id?: string): void => {
      if (color === 'error') {
        color = 'danger';
      }
      const id = `${_id ? `${_id}-` : ``}${uuid()}`;

      const dismiss = (): void =>
        setNotifications((currentNotifications) =>
          currentNotifications.filter((n) => n.id !== id)
        );

      setNotifications(
        notifications.concat([
          {
            color,
            dismiss,
            id,
            message,
          },
        ])
      );
      setTimeout(() => {
        dismiss();
      }, timeout);
    },
    [notifications, setNotifications]
  );

  const onSubscriptionData = ({
    subscriptionData: { data: subdata },
  }: any): any => {
    const { notification = {} } = subdata || {};

    const { data = { message: {} } } = notification as {
      notificationID: string;
      data: any;
      timeout: number;
      action: string;
    };

    if (agentID && network?.networkID) {
      if (data.message.requestType === 'REPORT') {
        const {
          status,
          fileName,
          url,
          id,
          creationDate,
          cleanName,
          message,
          type,
        } = data.message as ReportData;

        switch (type) {
          case 'homeOfficeCPA':
          case 'vendorRefunds':
          case 'lead':
            if (status === 'GENERATING') {
              try {
                const { reportMany: _reports } =
                  client.cache.readQuery({
                    query: reportManyQuery,
                    variables: {
                      folderID: network?.networkID,
                      reportFolder: 'network',
                    },
                  }) || ({} as any);

                if (_reports) {
                  client.cache.writeQuery({
                    query: reportManyQuery,
                    variables: {
                      folderID: network?.networkID,
                      reportFolder: 'network',
                    },
                    data: {
                      reportMany: [
                        {
                          status,
                          fileName,
                          url,
                          id,
                          creationDate,
                          cleanName,
                          ['__typename']: 'Report',
                        },
                        ..._reports,
                      ],
                    },
                  });
                }
              } catch {
                client.cache.writeQuery({
                  query: reportManyQuery,
                  variables: {
                    folderID: network?.networkID,
                    reportFolder: 'network',
                  },
                  data: {
                    reportMany: [
                      {
                        status,
                        fileName,
                        url,
                        id,
                        creationDate,
                        cleanName,
                        ['__typename']: 'Report',
                      },
                    ],
                  },
                });
              }
              data.message = `Currently generating report.`;
              data.color = 'success';
              break;
            }

            data.message = message;
            data.color = 'success';

            setTabNotification({
              ...tabNotification,
              report: true,
            });
            break;
          case 'failure':
            data.message = 'Failed to generate report';
            data.color = 'danger';
            break;
          default:
            console.log(
              `${type} type hasn't been set up for notifications yet.`
            );
            return;
        }

        try {
          const exists = client.cache.readFragment({
            id,
            fragment: ReportFragment,
          });

          if (exists) {
            client.cache.writeData({
              id,
              data: {
                status,
              },
            });
          }
        } catch {
          console.log('Failed to update cache.');
        }
        client.queryManager.broadcastQueries();
      }
    }

    if (data) {
      create(data.color, data.message, 3000);
    }
  };

  useSubscription<{
    notification: Notification;
  }>(reportNotificationSubscription, {
    onSubscriptionData,
    variables: {
      networkID: network?.networkID,
    },
  });

  return (
    <NotificationContext.Provider
      value={{ create, notifications, tabNotification, setTabNotification }}
    >
      <div
        style={{
          position: 'fixed',
          top: 20,
          right: 16,
          zIndex: 1051, // Any lower (even 1050) and the notifications will be hidden under modals
        }}
      >
        {notifications.map((n) => (
          <Alert id={n.id} color={n.color} key={n.id} toggle={n.dismiss}>
            {n.message}
          </Alert>
        ))}
      </div>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
