import React from 'react';
import { useQuery } from 'react-apollo';
import { SelectField } from '@availity/select';
import { SelectFieldProps } from '@availity/select/typings/SelectField';
import { useNetwork } from '@/hooks';
import { priceTierManyQuery } from '@/graphql/queries';
import { formatCurrencyForDisplay } from '@/utils';

interface LTPriceTierSelectProps extends SelectFieldProps<any> {
  priceTierID: string;
  id?: string;
}

const LiveTransferPriceTierSelect: React.FC<LTPriceTierSelectProps> = ({
  priceTierID,
  ...props
}) => {
  const { network } = useNetwork();
  const { data: priceTierData, loading: priceTierLoading } = useQuery(
    priceTierManyQuery,
    {
      variables: {
        networkID: network?.networkID,
        liveTransfers: true,
      },
    }
  );

  if (priceTierLoading) return null;

  const { priceTierMany = [] } = priceTierData;

  return (
    <SelectField
      debounceTimeout={350}
      delay={350}
      getOptionLabel={(tier: any) => {
        return `${tier.name} - ${formatCurrencyForDisplay(
          tier.liveTransferPrice
        )}`;
      }}
      valueKey="priceTierID"
      options={priceTierMany || []}
      {...props}
    />
  );
};

export default LiveTransferPriceTierSelect;
