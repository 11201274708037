import React from 'react';
import { Field, Form } from '@availity/form';
import * as yup from 'yup';
import { Button, Col, Row, Spinner } from 'reactstrap';
import { SelectField } from '@availity/select';
import { useDestination, useDestinations } from '@/hooks';
import { uuid } from 'uuidv4';
import KeyValueArrayField from './KeyValueArrayField';
import useAgent from '../../useAgent';

const methodTypes = [
  {
    label: 'POST',
    value: 'POST',
  },
  {
    label: 'GET',
    value: 'GET',
  },
  {
    label: 'PUT',
    value: 'PUT',
  },
];

const contentTypes = [
  {
    label: 'JSON',
    value: 'application/json',
  },
  {
    label: 'XML',
    value: 'application/xml',
  },
  {
    label: 'TEXT',
    value: 'text/plain',
  },
  {
    label: 'PARAMS',
    value: 'params',
  },
  {
    label: 'FORM URL',
    value: 'application/x-www-form-urlencoded',
  },
];

interface CustomAPIProfileProps {
  destinationID?: string;
}

const CustomAPIProfile: React.FC<CustomAPIProfileProps> = ({
  destinationID,
}) => {
  const { user } = useAgent();
  const { loading, update, destination } = useDestination(
    user!.agentID,
    destinationID
  );
  const { destinations } = useDestinations(user?.agentID);

  if (loading) {
    return (
      <div>
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <Form
      validationSchema={yup.object().shape({
        url: yup
          .string()
          .test(
            'duplicate',
            'URL destination already exists.',
            function testDuplicate(url: string) {
              return !destinations
                .filter((n) => n.destinationType === 'api')
                .map((n) => n.destination)
                .includes(url);
            }
          ),
      })}
      initialValues={{
        headers: destination?.headers || [],
        params: destination?.params || [],
        body: destination?.body || [],
        name: destination?.destinationName || '',
        url: destination?.destination || '',
        method: destination?.method || '',
        contentType: destination?.contentType || '',
        attributesID: destination?.attributesID || '',
      }}
      // eslint-disable-next-line no-void
      onSubmit={(values) =>
        update({
          variables: {
            input: {
              destination: {
                agentID: user?.agentID,
                destinationType: 'api',
                status: 'active',
                destination: values.url,
                destID: destinationID === 'new' ? uuid() : destinationID,
                destinationName: values.name,
                body: values.body,
                headers: values.headers,
                params: values.params,
                method: values.method,
                contentType: values.contentType,
                attributesID: values.attributesID,
              },
            },
          },
        })
      }
    >
      <Row>
        <Col xs={12}>
          <Field name="name" label="Name" placeholder="Enter a Name..." />
        </Col>
        <Col xs={12}>
          <Field
            name="url"
            label="URL"
            placeholder="https://mycustomapi.com/crm/contacts"
            validate={(e) => {
              if (
                destinations
                  .filter((n) => n.destinationType === 'api' || 'url')
                  .map((n) => n.destination)
                  .includes(e)
              ) {
                return 'URL destination already exists.';
              }
            }}
          />
        </Col>
        <Col xs={6}>
          <SelectField
            name="method"
            label="Method"
            placeholder="Method"
            options={methodTypes}
          />
        </Col>
        <Col xs={6}>
          <SelectField
            name="contentType"
            label="Content Type"
            isClearable
            placeholder="Content Type"
            options={contentTypes}
          />
        </Col>
        <Col xs={12}>
          <KeyValueArrayField name="headers" label="Headers" />
        </Col>
        <Col xs={12}>
          <KeyValueArrayField name="params" label="Query Params" />
        </Col>
        <Col xs={12}>
          <KeyValueArrayField name="body" label="Body" />
        </Col>
        <Col xs={12}>
          <Field
            name="attributesID"
            label="Attributes"
            placeholder="Leave empty to use default...."
          />
        </Col>
      </Row>
      <Button
        color="primary"
        className="float-right"
        disabled={false}
        type="submit"
      >
        {destinationID === 'new' ? 'Create' : 'Save Changes'}
      </Button>
      <Button color="secondary" className="mr-1 float-right" type="reset">
        {destinationID === 'new' ? 'Reset' : 'Reset Changes'}
      </Button>
    </Form>
  );
};

export default CustomAPIProfile;
