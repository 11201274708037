import React from 'react';
import { Badge } from 'reactstrap';
import { FaTimesCircle } from 'react-icons/fa';

const resultAttributeMapping = {
  acaEligible: 'ACA Eligible',
  callWindowSegment: 'Call Window',
  povertyLevel: 'Poverty Level',
  activeProspectClaim_claimCert_true_storeAnalytics_false:
    'Active Prospect Claim',
  activeProspect_storeAnalytics_true: 'Active Prospect Analytics',
  activeProspect_storeAnalytics_false: 'Active Prospect',
  hoScoring_priceOverride_true: 'HO Score Price Override',
  hoScoring_priceOverride_false: 'HO Score',
};

interface AddonProps {
  index: number;
  attributes?: string[];
  resultAttribute: string;
  functionName?: string;
  remove: () => void;
  className?: string;
  type: string;
  settings: any;
}

const EligibleAddon: React.FC<AddonProps> = ({
  remove,
  resultAttribute,
  settings = {},
}) => {
  console.log(settings);
  let lookupKeySuffix = '';
  if (settings) {
    const settingsKeys = Object.keys(settings) || [];
    lookupKeySuffix =
      settingsKeys.sort().reduce((temp, key) => {
        if (
          settings[key] === undefined ||
          settings[key] === null ||
          key === 'ruleID'
        ) {
          return temp;
        }
        return `${temp}_${key}_${settings[key]}`;
      }, '') || '';
  }
  console.log(`${resultAttribute}${lookupKeySuffix}`);
  return (
    <Badge
      color="primary"
      className="d-flex justify-content-between align-items-center mr-2 mb-2"
      id="aca-addon-action"
    >
      <span className="mr-2 h5 mb-0">
        {resultAttributeMapping[`${resultAttribute}${lookupKeySuffix}`]}
      </span>
      <FaTimesCircle className="cursor-pointer h5 mb-0" onClick={remove} />
    </Badge>
  );
};

export default EligibleAddon;
