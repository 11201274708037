import gql from 'graphql-tag';
import { LeadFragment } from '../fragments';

export default gql`
  query paginationQuery(
    $perPage: Int
    $page: Int
    $filter: LeadCollectionFilters
    $orderBy: LeadOrderInput
  ) {
    leadPagination(
      perPage: $perPage
      page: $page
      filter: $filter
      orderBy: $orderBy
    ) {
      count
      elasticQuery
      items {
        ...LeadFragment
      }
      pageInfo {
        pageCount
        currentPage
      }
    }
  }
  ${LeadFragment}
`;
