import React, { createContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { attributesQuery } from '@/graphql/queries';
import { Attribute } from '../types';

export const AttributesContext = createContext<{
  pingAttributes?: Attribute[];
  postAttributes?: Attribute[];
  getAttribute?: (sourceAttribute: string) => Attribute | null;
  loading: boolean;
}>({
  pingAttributes: undefined,
  postAttributes: undefined,
  loading: true,
  getAttribute: () => null,
});

const AttributesProvider: React.FC = ({ children }) => {
  const { data, loading, error, client } = useQuery(attributesQuery);

  // If this is the user's first time loading the app we dont want to show the nav bar before
  // we know if they have access or not
  // if (loading) {
  //   return null;
  // }

  const getAttribute = (attribute: string): Attribute | null =>
    !data
      ? null
      : data?.attributes?.attributes?.find(
          (att: Attribute) =>
            att?.sourceAttribute === attribute ||
            att?.internalAttribute === attribute
        );

  return (
    <AttributesContext.Provider
      value={{
        pingAttributes: data?.attributes.attributes.filter(
          (f?: Attribute) => f?.attributeCategory !== 'contact'
        ),
        postAttributes: data?.attributes.attributes,
        loading,
        getAttribute,
      }}
    >
      {loading ? null : children}
    </AttributesContext.Provider>
  );
};

export default AttributesProvider;
