import gql from 'graphql-tag';
import { VendorFragment } from '../fragments';

export default gql`
  query vendorManyQuery($networkID: String, $ignoreAccessControl: Boolean) {
    vendorMany(
      networkID: $networkID
      ignoreAccessControl: $ignoreAccessControl
    ) {
      ...VendorFragment
    }
  }
  ${VendorFragment}
`;
