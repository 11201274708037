import React from 'react';
import { Row, Col } from 'reactstrap';
import { FieldArray, useField } from 'formik';
import MultiLevelFilter from './MultiLevelFilter';
import StaticListFilter from './StaticListFilter';
import VerifyFilter from './VerifyFilter';
import RangeFilter from './RangeFilter';
import TimeFilter from './TimeFilter';
import FiltersDropdown, {
  tcpaVerification,
  emailVerification,
  phoneVerification,
  priceVerification,
  regionVerification,
  filterCategories,
} from './FiltersDropdown';
import DuplicateFilter from './DuplicateFilter';

type FilterType = 'STATIC' | 'RANGE' | 'DYNAMIC' | 'MULTI' | 'TIME';

const FilterRow: React.FC<{
  type: FilterType;
  index: number;
  remove: () => void;
}> = ({ type, ...props }) => {
  let Tag;
  if (type === 'RANGE') {
    Tag = RangeFilter;
  } else if (type === 'STATIC') {
    Tag = StaticListFilter;
  } else if (type === 'MULTI') {
    Tag = MultiLevelFilter;
  } else if (type === 'TIME') {
    Tag = TimeFilter;
  } else if (type === 'DYNAMIC') {
    Tag = DuplicateFilter;
  } else {
    return null;
  }
  return <Tag {...props} />;
};

const FiltersTab: React.FC<{
  network?: any;
}> = ({ network }) => {
  const [{ value: filters }] = useField('filters');

  const labelMappings: any = {};

  filterCategories.map((t) => {
    return t.options.forEach((b: any) => {
      labelMappings[b.value.attribute] = b.label;
    });
  });

  return (
    <FieldArray
      name="filters"
      render={(arrayHelpers) => (
        <Row className="mt-3">
          <Col xs={12} className="mb-3 d-flex">
            <FiltersDropdown filters={filters} push={arrayHelpers.push} />
            <div className="d-flex align-items-center flex-wrap ml-2">
              {filters.map(
                (filter: any, index: number) =>
                  ['SYSTEM'].includes(filter.type) && (
                    <VerifyFilter
                      key={`${filter.type}-${filter.attribute}-${index}`}
                      index={index}
                      {...filter}
                      label={labelMappings[filter.attribute]}
                      className="mb-2"
                      remove={() => arrayHelpers.remove(index)}
                    />
                  )
              )}
            </div>
          </Col>
          {filters.map(
            (filter: any, index: number) =>
              filter.type !== 'ADDON' && (
                <FilterRow
                  key={`${filter.type}-${filter.attribute}-${index}`}
                  index={index}
                  {...filter}
                  label={labelMappings[filter.attribute]}
                  className="mb-2"
                  remove={() => arrayHelpers.remove(index)}
                  duplicateLevels={network.duplicateLevels}
                />
              )
          )}
        </Row>
      )}
    />
  );
};
export {
  tcpaVerification,
  emailVerification,
  phoneVerification,
  priceVerification,
  regionVerification,
};

export default FiltersTab;
