import React from 'react';
import { FormGroup, Label, UncontrolledTooltip } from 'reactstrap';
import { useField } from 'formik';
import { FaQuestionCircle } from 'react-icons/fa';
import classnames from 'classnames';

interface HelpMessageProps {
  label?: string;
  name: string;
  className?: string;
  helpMessage?: string;
}

const LabelWithToolTip: React.FC<HelpMessageProps> = ({
  label,
  name,
  helpMessage,
  className,
}) => {
  return (
    <>
      {label && (
        <Label className={classnames('mr-3', className)}>
          {label}
          {helpMessage && (
            <FaQuestionCircle
              className="ml-1"
              id={`tooltip-${name.replace(/[^a-zA-Z 0-9]+/g, '')}`}
            />
          )}
        </Label>
      )}
      {label && helpMessage && (
        <UncontrolledTooltip
          placement="top"
          target={`tooltip-${name.replace(/[^a-zA-Z 0-9]+/g, '')}`}
        >
          {helpMessage}
        </UncontrolledTooltip>
      )}
    </>
  );
};

export default LabelWithToolTip;
