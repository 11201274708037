/* eslint-disable no-unused-expressions */
/* eslint-disable promise/always-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable unicorn/prefer-add-event-listener */
import React, { useCallback, useState, useEffect } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Progress,
  CardHeader,
} from 'reactstrap';
import { Storage } from 'aws-amplify';
import Dropzone from 'react-dropzone';
import { FaUpload } from 'react-icons/fa';
import { useNotifications, useNetwork } from '@/hooks';
import { Vars } from '@/utils';
import { useFormikContext } from 'formik';

const Mp3UploadModal: React.SFC<{
  close: () => void;
  isOpen: boolean;
  layerID: string;
  fieldName: string;
}> = ({ close = () => {}, layerID, fieldName, isOpen }: any) => {
  const [[fileName], setFileName] = useState(['', null]);

  const [progress, setProgress] = useState(0);

  const [downloadLink, setDownloadLink] = useState('');

  const [isClicked, setIsClicked] = useState(false);

  const { values, setFieldValue } = useFormikContext();

  const options = {
    level: 'public',
    bucket: `live-transfer-urls`,
    region: 'us-east-1',
  };

  useEffect(() => {
    setProgress(0);
  }, [isOpen]);
  const { create: createNotification } = useNotifications();

  const { network } = useNetwork();

  const onDrop = useCallback((files) => {
    setIsClicked(false);
    setProgress(0);
    setTimeout(() => {
      files.forEach(async (file: any) => {
        setFileName([fileName, file.name]);

        try {
          const result: any = await Storage.vault.put(
            `${Vars.env}/networks/${network?.networkID}/${layerID}/${fieldName}/${file.name}`,
            file,
            {
              ...options,
              contentType: 'audio/mp3',
              progressCallback(progress: any) {
                setProgress(progress.loaded / progress.total);
              },
            }
          );

          // get url and set fieldname
          let url = await Storage.vault.get(result.key, {
            ...options,
          });
          if (url.toString().includes('?')) {
            const link = url.toString().split('?');
            url = link[0];
          }
          setDownloadLink(url.toString());
        } catch (error) {
          setIsClicked(false);
          createNotification('danger', 'Error Saving', 2000);
        }
      });
    }, 1000);
  }, []);
  return (
    <Modal isOpen={isOpen} toggle={close}>
      <ModalHeader wrapTag={CardHeader} toggle={close}>
        Upload MP3
      </ModalHeader>
      <ModalBody>
        <Dropzone onDrop={onDrop} maxFiles={1} accept={['.mp3']}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <section>
              <div
                {...getRootProps({
                  className: isDragActive ? 'file-drop-active' : 'file-drop',
                })}
              >
                <input data-testid="file-picker" {...getInputProps()} />
                <p>
                  <strong>Drag and Drop</strong>
                </p>
                <Button color="primary">
                  <div className="d-flex justify-content-center">
                    Upload File &nbsp;
                    <FaUpload />
                  </div>
                </Button>
              </div>
            </section>
          )}
        </Dropzone>
        <div className="w-100 mt-3">
          <Progress color="success" value={progress * 100}>
            {progress === 1 ? `Uploaded ${fileName}` : ''}
          </Progress>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={close}>
          Cancel
        </Button>
        <Button
          color="success"
          disabled={progress !== 1 || isClicked}
          onClick={async () => {
            setIsClicked(true);
            setFieldValue(fieldName, downloadLink);
            createNotification('success', 'Successfully uploaded mp3', 2000);
            close();
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Mp3UploadModal;
