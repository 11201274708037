import gql from 'graphql-tag';
import { AnalyticsUrlFragment } from '@/graphql/fragments';

export default gql`
  query analyticsUrlQuery(
    $dashboardCode: String!
    $networkID: String!
    $startDate: String!
    $endDate: String!
    $vendorID: String
    $layerID: String
    $mpVendorCampaignName: String
    $managerID: String
    $domain: String
  ) {
    analyticsUrl(
      dashboardCode: $dashboardCode
      networkID: $networkID
      startDate: $startDate
      endDate: $endDate
      vendorID: $vendorID
      layerID: $layerID
      mpVendorCampaignName: $mpVendorCampaignName
      managerID: $managerID
      domain: $domain
    ) {
      ...AnalyticsUrlFragment
    }
  }
  ${AnalyticsUrlFragment}
`;
