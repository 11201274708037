import React, { useMemo } from 'react';
import { Card, CardTitle, Row, Col } from 'reactstrap';
import { SelectField } from '@availity/select';
import { useField } from 'formik';
import { FaTrash } from 'react-icons/fa';
import { useNetwork, useRequiredAttributes } from '@/hooks';
import { ButtonToggle } from '@/components';

interface MultiLevelFilterProps {
  attribute: string;
  ruleID: string;
  index: number;
  options: any[];
  remove: () => void;
  className?: string;
}

const MultiLevelFilter: React.FC<MultiLevelFilterProps> = ({
  attribute,
  index,
  ruleID,
  remove,
  className,
}) => {
  const { network } = useNetwork();

  const povertyLevel = useMemo(() => {
    return network!.povertyLevels.find((pL) => pL.id === ruleID);
  }, [network.povertyLevels, ruleID]);

  const [{ value: skipMissing }, , { setValue: setSkipMissing }] = useField(
    `filters.${index}.skipMissing`
  );

  useRequiredAttributes(['household', 'income'], !skipMissing);

  return (
    <Col xs={12} className={className}>
      <Card body className="h-100">
        <CardTitle
          tag="h4"
          className="lead text-uppercase d-flex justify-content-between align-items-center"
        >
          <span>{povertyLevel.name} Filter</span>
          <FaTrash className="text-danger cursor-pointer" onClick={remove} />
        </CardTitle>
        <Row noGutters id="multi-level-filter-row-">
          <Col xs={7}>
            <SelectField
              id="multi-level-filter-values"
              name={`filters.${index}.valuesInt`}
              label="Options"
              isMulti
              options={
                [...new Array(povertyLevel.levels).keys()].map((i) => ({
                  label: `${povertyLevel.fieldNames[i]} (${i})`,
                  value: i,
                })) || []
              }
            />
          </Col>
          <Col xs={2} className="ml-4">
            <SelectField
              id="multi-level-filter-row-include"
              label="Type"
              name={`filters.${index}.include`}
              options={[
                {
                  label: 'Include',
                  value: true,
                },
                {
                  label: 'Exclude',
                  value: false,
                },
              ]}
            />
          </Col>
          <Col xs={2} className="ml-4">
            <div className="mb-2">Skip if Missing</div>
            <div id={`${attribute}-skip-missing`}>
              <ButtonToggle
                key={`${attribute}-skip-missing`}
                active={skipMissing}
                onToggle={(value: any) => setSkipMissing(value)}
              />
            </div>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default MultiLevelFilter;
