import React, { useState } from 'react';
import { Container, ListGroup } from 'reactstrap';
import { useQuery } from '@apollo/react-hooks';
import { campaignManyQuery } from '@/graphql/queries';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment';
import { CampaignsSpendingPeriodModal } from '@/modals';
import CampaignListItem from './CampaignListItem';
import { localizeSpendingPeriod } from './utils';

const CampaignsTab: React.SFC<RouteComponentProps<{
  id: string;
}>> = ({ match }) => {
  const [selectedCampaignId, setSelectedCampaignId] = useState<null | string>(
    null
  );
  const { data, loading } = useQuery(campaignManyQuery, {
    variables: {
      agentID: match.params.id,
    },
  });

  const campaigns = data?.campaignMany || [];

  const selectedCampaign = campaigns.find((c) => c.id === selectedCampaignId);

  return (
    <Container>
      <ListGroup>
        {campaigns.map((c: any) => (
          <CampaignListItem
            toggleSpendingPeriodModal={() => setSelectedCampaignId(c.id)}
            key={c.id}
            {...c}
          />
        ))}
      </ListGroup>
      {!loading && campaigns.length === 0 && <div>Agent has no campaigns.</div>}
      <CampaignsSpendingPeriodModal
        isOpen={!!selectedCampaignId}
        campaignName={selectedCampaign?.campaignName}
        spendingPeriods={selectedCampaign?.spendingPeriods?.map((sp) =>
          localizeSpendingPeriod(sp, moment().utcOffset())
        )}
        toggle={() => setSelectedCampaignId(null)}
      />
    </Container>
  );
};

export default CampaignsTab;
