import React from 'react';
import { Row, Col } from 'reactstrap';
import { useFormikContext } from 'formik';
import {
  QualityCard,
  RejectLeadsCard,
  ConnectionCard,
  BidEnhancementCard,
} from './components';

const GeneralTab: React.FC<{
  vendorLayer?: any;
}> = ({ vendorLayer }) => {
  const isNew = !vendorLayer;
  const { values } = useFormikContext();

  return (
    <Row className="mt-3">
      <Col xs={12}>
        <ConnectionCard isNew={isNew} />
      </Col>
      <Col xs={12}>
        <BidEnhancementCard isNew={isNew} />
      </Col>
      <Col
        xs={{
          size: values.filters.length > 0 ? 3 : 12,
        }}
      >
        <QualityCard />
      </Col>
      <Col xs={9}>
        <RejectLeadsCard />
      </Col>
    </Row>
  );
};

export default GeneralTab;
