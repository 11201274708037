import gql from 'graphql-tag';
import { NetworkFragment } from '../fragments';

export default gql`
  query currentNetworkQuery($id: ID) {
    currentNetwork(id: $id) {
      ...NetworkFragment
    }
  }
  ${NetworkFragment}
`;
