import React, { useEffect, useState } from 'react';
import { Container, ListGroup, Button } from 'reactstrap';
import { useNetwork, usePageLoader } from '@/hooks';
import { RouteComponentProps } from 'react-router-dom';
import { BulkTierAssignAllModal, BulkTierAssignModal } from '@/modals';
import { FaSync } from 'react-icons/fa';
import usePriceTiers from './usePriceTiers';
import PriceTierRow from './PriceTierRow';

const PriceTiersPage: React.FunctionComponent<RouteComponentProps> = ({
  history,
}) => {
  const { network } = useNetwork();
  const { priceTiers, loading, refetch } = usePriceTiers();
  const [tierID, setTierID] = useState('');
  const [paginationModal, setPaginationModal] = useState(false);
  const togglePaginationModal = (): void => setPaginationModal((o) => !o);
  const [assignAllModal, setAssignAllModal] = useState(false);
  const toggleAssignAllModal = (): void => setAssignAllModal((o) => !o);
  const [unassignAllModal, setUnassignAllModal] = useState(false);
  const toggleUnassignAllModal = (): void => setUnassignAllModal((o) => !o);
  const { start, complete } = usePageLoader();

  // if we dont have a overflow price tier ID then this is not configured yet
  useEffect(() => {
    if (!network?.overflowPriceTierID) {
      history.push('/');
    }
  }, [network]);

  useEffect(() => {
    if (!loading) {
      refetch();
    }
  }, []);

  useEffect(() => {
    if (loading) {
      start();
      return () => complete();
    }

    return () => {};
  }, [loading]);

  return (
    <Container fluid style={{ maxWidth: 1500 }}>
      <div className="mt-5  d-flex align-items-center justify-content-between">
        <h3 className="my-0">Price Tiers</h3>
        <div>
          <Button
            size="lg"
            color="primary"
            className="mr-3"
            onClick={() => {
              refetch({
                networkID: network?.networkID,
              });
            }}
          >
            <div className="d-flex align-items-center">
              <FaSync />
            </div>
          </Button>
          <Button
            id="price-tier-create-button"
            color="primary"
            size="lg"
            onClick={() => history.push('/price-tiers/new')}
          >
            Create Price Tier
          </Button>
        </div>
      </div>

      <ListGroup className="mt-5">
        {priceTiers
          ?.sort((t) =>
            t.priceTierID === network?.overflowPriceTierID ? 1 : -1
          )
          ?.map((tier) => (
            <PriceTierRow
              key={tier.priceTierID}
              tier={tier}
              loading={loading}
              priceTiers={priceTiers}
              togglePaginationModal={togglePaginationModal}
              setTierID={setTierID}
              toggleAssignAllModal={toggleAssignAllModal}
              toggleUnassignAllModal={toggleUnassignAllModal}
            />
          ))}
        {priceTiers?.length === 0 && <h4>No Price Tiers</h4>}
      </ListGroup>
      {tierID !== '' ? (
        <>
          <BulkTierAssignModal
            onClosed={() => setTierID('')}
            isOpen={paginationModal}
            toggle={togglePaginationModal}
            id={tierID}
          />
          <BulkTierAssignAllModal
            onClosed={() => setTierID('')}
            isOpen={assignAllModal}
            toggle={toggleAssignAllModal}
            id={tierID}
          />
          <BulkTierAssignAllModal
            onClosed={() => setTierID('')}
            isOpen={unassignAllModal}
            toggle={toggleUnassignAllModal}
            id={tierID}
            unassign
          />
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default PriceTiersPage;
