import gql from 'graphql-tag';
import { NetworkFragment } from '../fragments';

export default gql`
  query networksQuery {
    networkMany {
      ...NetworkFragment
    }
  }
  ${NetworkFragment}
`;
