import React, { useState, useMemo } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  FormGroup,
  Label,
  InputGroupAddon,
  InputGroup,
  Col,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  InputGroupButtonDropdown,
  InputGroupText,
  FormFeedback,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';
import { ErrorMessage, useFormikContext } from 'formik';
import { Field, Input } from '@availity/form';
import { SelectField } from '@availity/select';
import { MoneyInput, ButtonToggle } from '@/components';
import { PriceTier } from '@sonic-web-dev/types';
import { FaQuestionCircle, FaSync } from 'react-icons/fa';
import { useAdmin, useNetwork } from '@/hooks';
import { formatCurrencyForDisplay } from '@/utils';
import useAgent from '@/areas/Agents/DetailView/useAgent';
import classNames from 'classnames';
import usePriceTier from '../usePriceTier';

interface Fee {
  amount: number;
  type: 'fixed' | 'dynamic' | 'aged' | 'overflow';
  agentFundedPercent: number;
}

const allowedFilters = [
  { label: 'Region', value: 'region' },
  { label: 'Income', value: 'incomeInt' },
  { label: 'Household', value: 'householdInt' },
  { label: 'KPM', value: 'activeProspectKpm' },
  { label: 'WPM', value: 'activeProspectWpm' },
  { label: 'AP Age', value: 'activeProspectAge' },
  { label: 'AP Event Duration', value: 'activeProspectEventDuration' },
  { label: 'AP Days till Expiration', value: 'activeProspectDaysToExpire' },
  {
    label: 'AP Minutes Since Creation',
    value: '  activeProspectMinutesSinceCreation',
  },
];

const requiredDestinations = [
  { label: 'None', value: 'none' },
  { label: 'Vanilla Soft', value: 'vSoft' },
  { label: 'iSales', value: 'iSales' },
  { label: 'Email', value: 'email' },
  { label: 'API', value: 'api' },
];

const getTierType = (values: any): string => {
  switch (true) {
    case values.liveTransfers:
      return 'Live Transfers';
    case values.isAged:
      return 'Aged';
    case values.isOverflow:
      return 'Overflow';
    default:
      return 'Data Only';
  }
};

const getFee = (tier: any, fees: Fee[] = []): Fee => {
  const processingFee = fees.find((f) => {
    if (tier.isAged && f.type === 'aged') return true;
    if (tier.dynamicPrice && f.type === 'dynamic') return true;
    if (tier.isOverflow && f.type === 'overflow') return true;
    if (f.type === 'fixed') return true;
    return false;
  }) || {
    amount: 0,
    type: 'fixed',
    agentFundedPercent: 0,
  };

  const hasMLFee = fees.find((f) => {
    return f.type === 'ml';
  });

  if (hasMLFee && !tier.isAged) {
    processingFee.amount += hasMLFee.amount;
  }

  return processingFee;
};

const doesntLoop = (
  priceTierID: string,
  priceTiers: PriceTier[],
  fallbackID?: string
): boolean => {
  if (!fallbackID) {
    return true;
  }

  if (fallbackID === priceTierID) {
    return false;
  }

  return doesntLoop(
    priceTierID,
    priceTiers,
    priceTiers.find((t) => t.priceTierID === fallbackID)?.fallbackPriceTierID
  );
};

const General: React.FC = () => {
  const { network } = useNetwork();
  const { priceTiers, tier } = usePriceTier();
  const { role } = useAdmin();

  const [openFallbackIncrement, setOpenFallbackIncrement] = useState(false);
  const toggleOpenFallbackIncrement = (): void =>
    setOpenFallbackIncrement((o) => !o);
  const { setFieldValue, values } = useFormikContext<any>();
  const [openTierTypeAction, setOpenTierTypeAction] = useState(false);
  const toggleOpenTierTypeAction = (): void => setOpenTierTypeAction((o) => !o);

  const attributePrefix = 'general-information-card';

  const processingFee = useMemo(() => getFee(values, network?.fees), [
    network,
    tier,
    values,
  ]);

  return (
    <>
      <Card className="mb-3">
        <CardHeader className="d-flex justify-content-between align-items-center">
          <CardTitle tag="h5" className="py-2 h4 mb-0">
            General Information
          </CardTitle>
          {tier && (
            <span className="text-muted">Tier Type: {getTierType(values)}</span>
          )}
          <span className="text-muted">{tier?.priceTierID}</span>
          {!tier && (
            <>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="border-0">
                      Tier Type:
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputGroupButtonDropdown
                    addonType="prepend"
                    isOpen={openTierTypeAction}
                    toggle={() => toggleOpenTierTypeAction()}
                  >
                    <DropdownToggle
                      caret
                      style={{ zIndex: 'auto' }}
                      color="primary"
                      id="liveTransferTierToggle"
                    >
                      {getTierType(values)}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          setFieldValue('liveTransfers', false);
                          setFieldValue('isAged', false);
                          setFieldValue('dynamicPrice', false);
                          setFieldValue('isOverflow', false);
                        }}
                      >
                        Data Only
                      </DropdownItem>
                      <DropdownItem
                        id="liveTransfers"
                        onClick={() => {
                          if (!values.liveTransfers) {
                            setFieldValue(
                              'liveTransfers',
                              !values.liveTransfers
                            );
                            setFieldValue('isAged', false);
                            setFieldValue('dynamicPrice', false);
                            setFieldValue('isOverflow', false);
                          }
                        }}
                      >
                        Live Transfer
                      </DropdownItem>
                      <DropdownItem
                        id="isAged"
                        onClick={() => {
                          if (!values.isAged) {
                            setFieldValue('isAged', !values.isAged);
                            setFieldValue('liveTransfers', false);
                            setFieldValue('dynamicPrice', false);
                            setFieldValue('isOverflow', false);
                          }
                        }}
                      >
                        Aged
                      </DropdownItem>
                      {role === 'super_internal_admin' && (
                        <DropdownItem
                          onClick={() => {
                            if (!values.isOverflow) {
                              setFieldValue('isOverflow', !values.isOverflow);
                              setFieldValue('liveTransfers', false);
                              setFieldValue('isAged', false);
                              setFieldValue('dynamicPrice', false);
                            }
                          }}
                        >
                          Overflow
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </InputGroupButtonDropdown>
                </InputGroup>
              </FormGroup>
              <UncontrolledTooltip
                placement="top"
                target="liveTransferTierToggle"
              >
                You will not be able change this setting once this tier is
                created.
              </UncontrolledTooltip>
            </>
          )}
        </CardHeader>
        <CardBody noGutters>
          <Row>
            <Col xs={4}>
              <Field
                name="name"
                type="text"
                placeholder="Tier Name..."
                label="Name"
                disabled={!!tier}
              />
            </Col>
            <Col
              xs={{
                size: 8,
                // offset:
                //   (!initialValues.dynamicPrice && values.dynamicPrice) ||
                //   values.liveTransfers
                //     ? 0
                //     : 1,
              }}
            >
              <Field
                name="description"
                type="text"
                placeholder="Description"
                label="Description"
              />
            </Col>
          </Row>

          <Row>
            <Col
              xs={{
                size: values.dynamicPrice ? 6 : 4,
                //  offset: 1,
              }}
            >
              <FormGroup style={{ margin: 0 }}>
                {!values?.liveTransfers ? (
                  <Label for="price">
                    {values.dynamicPrice ? (
                      <span>
                        Dynamic Price{' '}
                        <small className="text-muted">
                          (Percentage of Vendor Price / Min and Max Allowed
                          Price)
                        </small>
                        <br />
                        <sub>
                          {`Agent price with fees: ${formatCurrencyForDisplay(
                            ((values.minPrice *
                              values?.agentResponsiblePercentage) /
                              100 || 0) +
                              (processingFee.amount *
                                processingFee.agentFundedPercent) /
                                100
                          )} - ${formatCurrencyForDisplay(
                            ((values.maxPrice *
                              values?.agentResponsiblePercentage) /
                              100 || 0) +
                              (processingFee.amount *
                                processingFee.agentFundedPercent) /
                                100
                          )}`}
                        </sub>
                      </span>
                    ) : (
                      <span>
                        {' '}
                        Price <br />
                        <sub>
                          {`Agent price with fees: ${formatCurrencyForDisplay(
                            values.price +
                              (processingFee.amount *
                                processingFee.agentFundedPercent) /
                                100
                          )}`}{' '}
                        </sub>{' '}
                      </span>
                    )}
                  </Label>
                ) : (
                  <Label for="price">
                    <span>Data Only Price</span>
                  </Label>
                )}
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    {values.dynamicPrice ? '%' : '$'}
                  </InputGroupAddon>
                  {values.dynamicPrice ? (
                    <>
                      <Input
                        name="agentResponsiblePercentage"
                        min={0}
                        type="number"
                        defaultValue="100"
                        placeholder="Percentage of Vendor Price"
                      />
                      <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                      <InputGroupAddon addonType="prepend">Min</InputGroupAddon>
                      <MoneyInput
                        id={`${attributePrefix}-${
                          values.dynamicPrice ? 'dynamic-price' : 'price'
                        }`}
                        minimum={0}
                        onChange={(newPrice: number) =>
                          setFieldValue('minPrice', newPrice)
                        }
                        value={values.minPrice}
                      />
                      <InputGroupAddon addonType="prepend">Max</InputGroupAddon>
                      <MoneyInput
                        id={`${attributePrefix}-${
                          values.dynamicPrice ? 'dynamic-price' : 'price'
                        }`}
                        minimum={0}
                        onChange={(newPrice: number) =>
                          setFieldValue('maxPrice', newPrice)
                        }
                        value={values.maxPrice}
                      />
                    </>
                  ) : (
                    <MoneyInput
                      id={`${attributePrefix}-${
                        values.dynamicPrice ? 'dynamic-price' : 'price'
                      }`}
                      minimum={0}
                      onChange={(newPrice: number) =>
                        setFieldValue('price', newPrice)
                      }
                      value={values.price}
                    />
                  )}
                  {!values.dynamicPrice && (
                    <InputGroupAddon addonType="append">USD</InputGroupAddon>
                  )}
                  {network!.allowDynamicPrice && !values.liveTransfers && (
                    <InputGroupAddon addonType="append">
                      <Button
                        id="dynamic-price-toggle"
                        color={values.dynamicPrice ? 'warning' : 'primary'}
                        onClick={() =>
                          setFieldValue('dynamicPrice', !values.dynamicPrice)
                        }
                      >
                        <FaSync />
                      </Button>
                      <UncontrolledTooltip
                        target="dynamic-price-toggle"
                        trigger="hover"
                      >
                        Toggle Dynamic Pricing using the vendor price to sell
                        the lead.
                      </UncontrolledTooltip>
                    </InputGroupAddon>
                  )}
                </InputGroup>
              </FormGroup>
            </Col>
            {/* {!initialValues.dynamicPrice && values.dynamicPrice && (
            <Col xs={{ size: 4, offset: 0 }}>
              <MoneyField
                name="initialPrice"
                label="Initial Tier Price"
                min={1}
                size="md"
              />
            </Col>
          )} */}
            {values.liveTransfers && (
              <Col
                xs={{
                  size: 3,
                  offset: 0,
                }}
              >
                <FormGroup>
                  <Label for="liveTransferPrice">
                    <span>Live Transfer Price</span>
                  </Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                    <MoneyInput
                      id={`${attributePrefix}-liveTransfer-price`}
                      minimum={0}
                      onChange={(newLTPrice: number) =>
                        setFieldValue('liveTransferPrice', newLTPrice)
                      }
                      value={values.liveTransferPrice}
                    />
                    <InputGroupAddon addonType="append">USD</InputGroupAddon>
                    <ErrorMessage
                      name="liveTransferPrice"
                      component={(props) => (
                        <div {...props} className="text-danger" />
                      )}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            )}

            {values.liveTransfers && (
              <Col
                xs={{
                  size: 5,
                  // offset: 1,
                }}
              >
                <FormGroup>
                  <Label for="chargeDelayThreshold">
                    Charge Delay Threshold
                  </Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">After</InputGroupAddon>
                    <Input
                      name="chargeDelayThreshold"
                      type="number"
                      style={{ maxWidth: 100 }}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText className="border-0">
                        seconds, charge the full live transfer price.
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <ErrorMessage
                    name="chargeDelayThreshold"
                    component={(props) => (
                      <div {...props} className="text-danger" />
                    )}
                  />
                </FormGroup>
              </Col>
            )}
            {tier?.priceTierID !== network?.overflowPriceTierID &&
              !values?.isOverflow && (
                <Col
                  xs={{
                    size: values.dynamicPrice ? 6 : 8,
                  }}
                >
                  <FormGroup>
                    <Label for="fallbackTier">
                      <span>
                        {' '}
                        Fallback Tier <br />
                        <sub>
                          {`Length of time a lead can age before no longer eligible for the tier`}{' '}
                        </sub>{' '}
                      </span>
                    </Label>
                    <InputGroup>
                      <Input
                        name="fallbackAmount"
                        type="number"
                        style={{ maxWidth: 100 }}
                      />
                      <InputGroupButtonDropdown
                        addonType="append"
                        isOpen={openFallbackIncrement}
                        toggle={() => toggleOpenFallbackIncrement()}
                      >
                        <DropdownToggle caret>
                          {values.fallbackIncrement}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() =>
                              setFieldValue('fallbackIncrement', 'seconds')
                            }
                          >
                            seconds
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              setFieldValue('fallbackIncrement', 'minutes')
                            }
                          >
                            minutes
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              setFieldValue('fallbackIncrement', 'hours')
                            }
                          >
                            hours
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              setFieldValue('fallbackIncrement', 'days')
                            }
                          >
                            days
                          </DropdownItem>
                        </DropdownMenu>
                      </InputGroupButtonDropdown>
                      <InputGroupAddon addonType="append">
                        <InputGroupText className="border-0">
                          unsold, sell as
                        </InputGroupText>
                      </InputGroupAddon>
                      <SelectField
                        id={`${attributePrefix}-fallback-tier-dropdown`}
                        groupClass="mb-0 w-25"
                        placeholder={
                          priceTiers?.find(
                            (t) => t.priceTierID === values.fallbackPriceTierID
                          )!.name
                        }
                        name="fallbackPriceTierName"
                        labelKey="fallbackPriceTierID"
                        menuPortalTarget={document.body} // important
                        valueKey="fallbackPriceTierID"
                        options={priceTiers?.map((o: any) => {
                          return {
                            priceTierID: o.priceTierID,
                            name: o.name,
                          };
                        })}
                        onChange={({ name, priceTierID }: any) => {
                          setFieldValue('fallbackPriceTierID', priceTierID);
                          setFieldValue('fallbackPriceTier', name);
                        }}
                        getOptionLabel={(opt: any) => {
                          return opt.name || '';
                        }}
                      />
                      <FormFeedback>
                        <ErrorMessage name="fallbackTier" />
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
              )}
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default General;

// <Col xs={4}>
//   <SelectField
//     groupClass="mt-2"
//     id={`${attributePrefix}-required-destination-dropdown`}
//     name="requiredDestination"
//     label="Required Destination"
//     options={requiredDestinations}
//     placeholder="Required Destination"
//   />
// </Col>
