import gql from 'graphql-tag';
import { AnalyticsAgentFragment } from '../fragments';

export default gql`
  query analyticsAgentsQuery($networkID: String!) {
    analyticsAgents(networkID: $networkID) {
      agents {
        ...AnalyticsAgentFragment
      }
    }
  }
  ${AnalyticsAgentFragment}
`;
