import gql from 'graphql-tag';

export default gql`
  query leadAggregationQuery(
    $fields: [String!]
    $filter: LeadCollectionFilters
  ) {
    leadAggregation(fields: $fields, filter: $filter) {
      field
      counts {
        key
        totals
      }
    }
  }
`;
