import React from 'react';
import classnames from 'classnames';
import { Nav, NavItem, Badge } from 'reactstrap';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';

interface SecondaryNavItemProps {
  label: string;
  path: string;
}

const SecondaryNav: React.SFC<{
  navItems: SecondaryNavItemProps[];
}> = ({ navItems }) => {
  const history = useHistory();
  const match = useRouteMatch<{
    id: string;
    path: string;
  }>('/agents/:id/:path');

  const relayId = match!.params.id;

  return (
    <Nav
      className="px-2 py-4"
      navbar
      vertical
      style={{
        backgroundColor: 'rgb(49,49,49)',
        width: 168,
      }}
    >
      {navItems.map((item) => {
        const active = match?.params.path === item.path;

        return (
          <NavItem
            key={item.path}
            className={classnames('cursor-pointer mb-2 text-left', {
              'bg-primary': active,
              'bg-transparent': !active,
            })}
            style={{
              fontSize: 15,
            }}
            onClick={() => history.push(`/agents/${relayId}/${item.path}`)}
            tag={Badge}
          >
            {item.label}
          </NavItem>
        );
      })}
    </Nav>
  );
};

export default SecondaryNav;
