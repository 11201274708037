/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import {
  Table,
  ButtonGroup,
  Button,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormText,
} from 'reactstrap';
import { SelectField } from '@availity/select';
import { SelectFieldProps } from '@availity/select/typings/SelectField';
import chunk from 'lodash.chunk';
import { FaHashtag, FaFlask, FaTrash, FaFont } from 'react-icons/fa';
import { Form, Field, Input, Feedback } from '@availity/form';
import { FieldArray } from 'formik';
import { uuid } from 'uuidv4';
import * as yup from 'yup';
import omit from 'lodash.omit';
import { CopyButton } from '@/components';
import useAgent from '../useAgent';

interface CrmUrlSelectProps extends SelectFieldProps<any> {
  crmUrlID: string;
  description: string;
}

interface VanillaSoftCRMProfileProps {
  crmID: string;
  role: string;
  profiles: any[];
  fields: any;
  crmCampaign: string;
  crmUrl: string;
  crmUrlIDs: CrmUrlSelectProps[];
  crmUrlID: string;
}

const VanillaSoftCRMProfile: React.FunctionComponent<VanillaSoftCRMProfileProps> = ({
  profiles = [],
  fields,
  crmCampaign,
  crmUrl,
  crmUrlIDs = [],
  crmUrlID = '',
}) => {
  const { user, updating, update } = useAgent();

  return (
    <Form
      validationSchema={yup.object().shape({
        crmCampaign: yup.string(),
        crmUrl: yup.string(),
        profiles: yup.array().of(
          yup
            .object()
            .shape({
              crmFields: yup.object().shape(
                fields.reduce((acc: any, field: any) => {
                  acc[field.name] = yup.string().isRequired(field.required);

                  return acc;
                }, {} as any)
              ),
            })
            .test(
              'duplicate-field',
              'User ID and Team already in use.',
              (
                val: any,
                ctx: yup.TestContext<Record<string, any>>
              ): boolean => {
                const uniqueId = Object.values(val.crmFields).join('@');
                return !ctx.parent.find((profile) => {
                  if (profile.id === val.id) {
                    return false;
                  }
                  const profileID = Object.values(profile.crmFields).join('@');
                  return profileID === uniqueId;
                });
              }
            )
        ),
        crmUrlID: yup.string().nullable(),
      })}
      initialValues={{
        profiles: profiles || [],
        crmCampaign: crmCampaign || '',
        crmUrl: crmUrl || '',
        crmUrlID: crmUrlID || '',
        // crmUrlIDs: crmUrlIDs || []
      }}
      onSubmit={(values) => {
        console.log(values);
        update({
          variables: {
            input: {
              id: user!.id,
              networkID: user!.networkID,
              agentID: user!.agentID,
              crmProfiles: {
                iSalesCRM: {
                  ...user!.crmProfiles!.iSalesCRM,
                  label: 'ISalesCRM',
                  profiles: user!.crmProfiles!.iSalesCRM?.profiles?.map(
                    (p) => ({
                      ...omit(p, ['__typename', 'profileID']),
                      id: p.profileID || p.id,
                    })
                  ),
                },
                convoso: {
                  ...user!.crmProfiles!.convoso,
                  label: 'Convoso',
                  profiles: user!.crmProfiles!.convoso?.profiles?.map((p) => ({
                    ...omit(p, ['__typename', 'profileID']),
                    id: p.profileID || p.id,
                  })),
                },
                vanillaSoftDirect: {
                  ...user!.crmProfiles!.vanillaSoftDirect,
                  crmCampaign: values.crmCampaign.trim(),
                  crmUrl: user?.organization ? '' : values.crmUrl,
                  crmUrlID: values?.crmUrlID,
                  label: 'VanillaSoft',
                  profiles: values.profiles?.map((p) => ({
                    ...omit(p, ['__typename', 'profileID']),
                    id: p.profileID || p.id,
                  })),
                },
              },
            },
          },
        });
      }}
    >
      {({ values, resetForm, errors }) => {
        useEffect(() => {
          resetForm();
        }, [user!.agentID]);

        return (
          <div>
            {user!.marketplace === 'sonic' && (
              <div className="mb-3">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    Vsoft Url
                  </InputGroupAddon>
                  <Input
                    disabled={!!user?.organization}
                    name="crmUrl"
                    type="text"
                    placeholder={
                      user?.organization?.availibleCRMs?.vanillaSoftDirect
                        ?.crmUrl ||
                      'https://new.vanillasoft.net/web/post.aspx?id=123456'
                    }
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <FaFont title="text" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Feedback name="crmUrl" />
                </InputGroup>
                {user?.organizationID && (
                  <FormText>Url inherited from Organization</FormText>
                )}
              </div>
            )}

            <div className="mb-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">Campaign</InputGroupAddon>
                <Input
                  name="crmCampaign"
                  type="text"
                  placeholder="Jon Smith Team"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <FaFont title="text" />
                  </InputGroupText>
                </InputGroupAddon>
                <Feedback name="crmCampaign" />
              </InputGroup>
            </div>
            <Table className="border-bottom border-secondary">
              <thead>
                {crmUrlIDs && crmUrlIDs.length > 0 ? (
                  <tr>
                    <th colSpan={10} className="border-0">
                      <p>CRM Connection</p>

                      <SelectField
                        name="crmUrlID"
                        valueKey="crmUrlID"
                        labelKey="description"
                        placeholder="Select Vsoft API Destination"
                        options={crmUrlIDs}
                      />

                      <small className="text-muted">
                        All leads will be delivered to this vanillasoft project
                        inbound webhook
                      </small>
                    </th>
                  </tr>
                ) : null}
                <tr>
                  <th colSpan={2} className="border-0">
                    Profiles
                  </th>
                </tr>
              </thead>
              <tbody>
                <FieldArray
                  name="profiles"
                  render={(arrayHelpers) => (
                    <>
                      {values.profiles?.map((profile, profileIdx) => (
                        <tr key={profile.id}>
                          <td className="d-flex flex-column">
                            <span>
                              <FaHashtag className="mb-2" />
                              <h4 className="d-inline">{profileIdx + 1}</h4>
                            </span>
                            <div>
                              <ButtonGroup vertical>
                                <Button
                                  color="info"
                                  disabled
                                  title="Test profile (COMING SOON)"
                                >
                                  <FaFlask />
                                </Button>
                                <Button
                                  color="danger"
                                  title="Delete Profile"
                                  id={`delete-profile-${profileIdx}`}
                                  onClick={() =>
                                    arrayHelpers.remove(profileIdx)
                                  }
                                >
                                  <FaTrash />
                                </Button>
                              </ButtonGroup>
                            </div>
                          </td>
                          <td>
                            <Row noGutters>
                              <Col xs={12} className="mb-2">
                                <small className="text-muted">{`${
                                  user!.agentID
                                }@vanillaSoftDirect@agent@${
                                  profile.profileID
                                }`}</small>
                                <CopyButton
                                  content={`${
                                    user!.agentID
                                  }@vanillaSoftDirect@agent@${
                                    profile.profileID
                                  }`}
                                  className="ml-3 float-right"
                                />
                              </Col>
                              <Col xs={12}>
                                <Field
                                  label="Description"
                                  placeholder="A descriptive name for this profile"
                                  name={`profiles.${profileIdx}.description`}
                                />
                              </Col>
                              {chunk(fields, 2).map((chunk: any) =>
                                chunk.map((subChunk: any) => {
                                  return (
                                    <Col
                                      key={`profiles.${profileIdx}.crmFields.${subChunk.name}`}
                                      xs={chunk.length === 2 ? 6 : 12}
                                    >
                                      <Field
                                        label={subChunk.label}
                                        placeholder={subChunk.placeholder}
                                        type={subChunk.type}
                                        name={`profiles.${profileIdx}.crmFields.${subChunk.name}`}
                                      />
                                    </Col>
                                  );
                                })
                              )}
                              <div className="text-danger">
                                {typeof errors?.profiles?.[profileIdx] ===
                                'string'
                                  ? errors.profiles[profileIdx]
                                  : undefined}
                              </div>
                            </Row>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={2}>
                          <Button
                            color="success"
                            className="w-100"
                            onClick={() =>
                              arrayHelpers.push({
                                crmExcludeUserID: false,
                                crmFields: fields.reduce(
                                  (acc: any, field: any) => ({
                                    ...acc,
                                    [field.name]: '',
                                  }),
                                  {}
                                ),
                                id: `vanillaSoftDirect-${uuid()}`, // TODO - maybe just use uuid here
                              })
                            }
                          >
                            Add New Profile
                          </Button>
                        </td>
                      </tr>
                    </>
                  )}
                />
              </tbody>
            </Table>
            <Button
              className="float-right"
              color="primary"
              type="submit"
              disabled={updating}
            >
              Save Changes
            </Button>
            <Button className="mr-1 float-right" color="secondary" type="reset">
              Reset Changes
            </Button>
          </div>
        );
      }}
    </Form>
  );
};

export default VanillaSoftCRMProfile;
