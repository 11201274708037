import React, { useContext } from 'react';
import { Auth } from 'aws-amplify';
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import classnames from 'classnames';
import { FaBars, FaCaretDown, FaSearch } from 'react-icons/fa';
import { useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import { useAdmin, useNetwork, useNetworks, useNotifications } from '@/hooks';
import { agentByIdQuery } from '@/graphql/queries';
import { Network } from '@sonic-web-dev/types';
import { Form, Input } from '@availity/form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import CopyButton from '../CopyButton';

interface User {
  name: string;
  email: string;
  agentID: string;
  role: 'admin' | 'internal_admin' | 'super_internal_admin' | 'support';
}

interface TopNavProps {
  toggleCollapse: () => void;
}

const TopNav: React.FunctionComponent<TopNavProps> = ({ toggleCollapse }) => {
  const client = useApolloClient();
  const { create: createNotification } = useNotifications();
  const history = useHistory();
  const { network, changeNetwork } = useNetwork();
  const { role, email, name, agentID } = useAdmin();

  const internalAdmin = ['internal_admin', 'super_internal_admin'].includes(
    role
  );

  const [fetchAgent] = useLazyQuery(agentByIdQuery, {
    onCompleted: (data) => {
      if (!data.agentById) {
        createNotification('warning', 'Agent Not Found.');
      } else {
        changeNetwork(data.agentById.network.id, false);
        history.push(`/agents/${data.agentById.id}/general`);
      }
    },
    fetchPolicy: 'network-only',
    onError: () => createNotification('danger', 'Failed to fetch agent.'),
  });
  const { data } = useNetworks();

  return (
    <div
      className="border-bottom border-secondary py-3 px-3 d-flex justify-content-between align-items-center"
      style={{
        height: 77,
      }}
    >
      <div className="d-flex align-items-center">
        <Button color="primary" size="sm" onClick={toggleCollapse}>
          <FaBars size={20} />
        </Button>
        <UncontrolledDropdown
          inNavbar
          disabled={!internalAdmin && role !== 'support'}
        >
          <DropdownToggle
            tag="h4"
            className={classnames('mb-0 ml-3', {
              'cursor-pointer': internalAdmin || role === 'support',
            })}
          >
            {network?.networkName || 'Loading Network'}
            {(internalAdmin || role === 'support') && (
              <FaCaretDown className="ml-2" />
            )}
          </DropdownToggle>
          {data && (
            <DropdownMenu>
              {data?.networkMany.map((n: Network) => (
                <DropdownItem
                  active={n.id === network?.id}
                  onClick={() => changeNetwork(n.id)}
                  key={n.id}
                >
                  {n.networkName}
                </DropdownItem>
              ))}
            </DropdownMenu>
          )}
        </UncontrolledDropdown>
        {(internalAdmin || role === 'support') && (
          <>
            <CopyButton content={network?.networkID!} className="ml-3" />
            <Form
              initialValues={{ quickSearch: '' }}
              validationSchema={yup.object().shape({
                quickSearch: yup.string().min(1),
              })}
              onSubmit={async (values, { resetForm }) => {
                fetchAgent({
                  variables: {
                    id: values.quickSearch,
                  },
                });
                resetForm();
              }}
            >
              <InputGroup className="ml-5">
                <Input
                  name="quickSearch"
                  placeholder="Search by Agent ID or Email"
                />
                <InputGroupAddon addonType="append">
                  <Button type="submit">
                    <FaSearch />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Form>
          </>
        )}
      </div>
      <UncontrolledDropdown inNavbar>
        <DropdownToggle tag="div" className="d-flex cursor-pointer">
          <div className="d-flex flex-column align-items-end text-light font-weight-bold">
            <span>{email}</span>
            <span>Hello, {name}</span>
          </div>
          <FaCaretDown className="ml-2 mt-1" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem
            onClick={() => {
              fetchAgent({
                variables: {
                  id: agentID,
                },
              });
            }}
          >
            Profile
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              Auth.signOut();
              window.localStorage.removeItem('admin-network-id');
              client.cache.reset();
            }}
          >
            Logout
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default TopNav;
