/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalProps,
  ModalHeader,
  Button,
  Badge,
  Row,
  Col,
} from 'reactstrap';
import { useMutation } from '@apollo/react-hooks';
import { deleteQueueMutation } from '@/graphql/mutations';
import { queueManyQuery } from '@/graphql/queries';
import { useNotifications, useNetwork } from '@/hooks';
import { FaTimes } from 'react-icons/fa';
import {
  deploymentStatusMapping,
  serverStatusMapping,
  latestBadge,
} from '@/utils/infastructureUtils';

interface SelectedQueue {
  instanceID: string;
  fixedCampaignName: string;
  DEPLOYMENT_STATUS: string;
  SERVER_STATUS: string;
  version: string;
}
export interface QueueTerminateModalProps extends ModalProps {
  close: () => any;
  selectedQueues: SelectedQueue[];
  isOpen: boolean;
  removeQueue: (instanceId: string) => void;
  clearQueues: () => void;
  versions: string[];
}

const QueueTerminateModal: React.FunctionComponent<QueueTerminateModalProps> = ({
  close,
  isOpen,
  selectedQueues,
  removeQueue,
  versions = [],
  clearQueues = () => {},
}) => {
  const { create } = useNotifications();

  const {
    network: { marketplace = 'usha' },
  } = useNetwork() as any;

  const [deleteQueueFn] = useMutation(deleteQueueMutation, {
    update: (cache, { data: { deleteQueue } }) => {
      const {
        queueMany: { queues = [] },
      } = cache.readQuery({
        query: queueManyQuery,
        variables: {
          marketplace,
        },
      }) as any;

      cache.writeQuery({
        query: queueManyQuery,
        variables: {
          marketplace,
        },
        data: {
          queueMany: {
            queues: queues.filter((q: any) => !deleteQueue.ids.includes(q.id)),
          },
        },
      });
      selectedQueues.forEach(({ instanceID }) => removeQueue(instanceID));
    },
    onCompleted: () => create('success', 'Successfully deleted queue', 3000),
    onError: () => create('danger', 'Failed to delete queue', 3000),
  });

  if (selectedQueues.length === 0) {
    close();
  }

  const deleteQueues = () => {
    deleteQueueFn({
      variables: {
        input: {
          ids: selectedQueues.map(({ id }) => id),
        },
      },
    });
    clearQueues();
    close();
  };

  return (
    <Modal isOpen={isOpen} toggle={close}>
      <ModalHeader toggle={close}>Queue Termination</ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column">
          Selected Queues
          <div
            style={{
              minHeight: 100,
              maxHeight: 200,
              overflowY: 'scroll',
            }}
          >
            {selectedQueues.map(
              ({
                instanceID,
                fixedCampaignName,
                DEPLOYMENT_STATUS,
                SERVER_STATUS,
                version = '0.0.0',
              }) => (
                <div
                  key={instanceID}
                  style={{
                    height: 100,
                    overflowX: 'hidden',
                    paddingLeft: 5,
                    cursor: 'pointer',
                  }}
                  className="bg-light rounded position-relative mt-1"
                >
                  <div
                    className="position-absolute"
                    style={{ right: 5, zIndex: 999 }}
                    onClick={() => {
                      removeQueue(instanceID);
                    }}
                  >
                    <FaTimes color="dark" />
                  </div>
                  <Row className="h-100">
                    <Col
                      xs={7}
                      className="h-100 d-flex flex-column justify-content-around text-secondary"
                    >
                      <div>
                        <span className="font-weight-bold">Instance Id</span>:{' '}
                        {instanceID}
                      </div>
                      <div>
                        <span className="font-weight-bold">Tier Name</span>:{' '}
                        {fixedCampaignName}
                      </div>
                      <div>
                        <span className="font-weight-bold">Version</span>:
                        {version === versions.slice(-1)[0] ? (
                          latestBadge
                        ) : (
                          <Badge
                            style={{
                              fontSize: '.9rem',
                            }}
                            color="warning"
                            title="Queue is running latest version"
                            className="ml-2"
                          >
                            v{version}
                          </Badge>
                        )}
                      </div>
                    </Col>
                    <Col xs={5}>
                      <div
                        className="h-100 w-100 d-flex flex-column justify-content-around"
                        style={{ paddingRight: 10 }}
                      >
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <div>Last Deployment:</div>
                          <div>
                            {deploymentStatusMapping(DEPLOYMENT_STATUS)}
                          </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <div>Server Status:</div>
                          <div>{serverStatusMapping(SERVER_STATUS)}</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <Button color="secondary" onClick={close}>
          Cancel
        </Button>
        <Button color="danger" onClick={deleteQueues}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default QueueTerminateModal;
