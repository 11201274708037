import classNames from 'classnames';
import { useFormikContext, getIn } from 'formik';
import React, { useMemo } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { NavItem, NavLink } from 'reactstrap';

interface TabHeaderProps {
  name: string;
  activeTab: string;
  fields: string[];
  onClick: (name: string) => void;
}

const TabHeader: React.FC<TabHeaderProps> = ({
  name,
  children,
  activeTab,
  onClick,
  fields,
}) => {
  const { errors, submitCount } = useFormikContext();

  const hasErrors = useMemo(() => {
    if (submitCount === 0) {
      return false;
    }

    return fields.some((f: string) => {
      const error = getIn(errors, f);

      return !!error;
    });
  }, [errors, fields, submitCount]);

  return (
    <NavItem className="cursor-pointer">
      <NavLink
        onClick={() => onClick(name)}
        active={activeTab === name}
        className={classNames('d-flex align-items-center px-4', {
          'text-danger': hasErrors,
        })}
      >
        {children} {hasErrors && <FaInfoCircle className="ml-2" />}
      </NavLink>
    </NavItem>
  );
};

export default TabHeader;
