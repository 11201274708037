import gql from 'graphql-tag';
import { NetworkFragment } from '../fragments';

export default gql`
  mutation updateNetwork($input: UpdateOneNetworkInput) {
    __typename
    networkUpdateOne(input: $input) {
      ...NetworkFragment
    }
  }
  ${NetworkFragment}
`;
