import gql from 'graphql-tag';
import { ReportFragment } from '../fragments';

export default gql`
  query reportManyQuery($reportFolder: String, $folderID: String) {
    reportMany(reportFolder: $reportFolder, folderID: $folderID) {
      ...ReportFragment
    }
  }
  ${ReportFragment}
`;
