import gql from 'graphql-tag';
// import { LeadFragment } from '../fragments';

export default gql`
  query GetLeads(
    $limit: Int
    $offset: Int
    $sort: JSON
    $vendorID: String
    $layerID: String
    $mpVendorCampaignID: String
    $region: String
    $start: String
    $end: String
    $leadType: String
    $networkID: String
    $leadStatus: String
    $dateType: String
  ) {
    getLeads(
      limit: $limit
      offset: $offset
      sort: $sort
      vendorID: $vendorID
      layerID: $layerID
      mpVendorCampaignID: $mpVendorCampaignID
      region: $region
      start: $start
      end: $end
      leadType: $leadType
      networkID: $networkID
      leadStatus: $leadStatus
      dateType: $dateType
    ) {
      totalCount
      qry
      qryParams
      leads {
        leadID
        agentID
        firstName
        lastName
        created
        email
        campaignName
        mpVendorCampaignName
        mpVendorCampaignID
        corpLeadID
        price
        buyerPrice
        soldDate
        primaryPhone
        region
        vendorName
        leadType
        postalCode
        income
        age
        household
        trustedFormCertID
        jornayaLeadID
        networkID
        vendorLeadID
        layerID
        vendorLeadID
        layerDescription
        categoryDescription
        agentEmail
      }
    }
  }
`;
