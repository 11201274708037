import React, { useContext } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalProps,
  ModalHeader,
  Button,
  FormGroup,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardHeader,
} from 'reactstrap';
import omit from 'lodash.omit';
import { Agent } from '@/types/Agent';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { updateOneUserMutation } from '@/graphql/mutations';
import { useHistory } from 'react-router-dom';
import { useNotifications } from '@/hooks';
import { Form } from '@availity/form';
import { FaArrowRight } from 'react-icons/fa';
import { networksQuery } from '@/graphql/queries';
import { CurrentUserContext } from '@/context';

export interface AgentsConfirmDeleteModalProps
  extends Omit<ModalProps, 'toggle'> {
  user: Agent;
  toggle: () => void;
}

const AgentsConfirmDeleteModal: React.SFC<AgentsConfirmDeleteModalProps> = ({
  user,
  ...props
}) => {
  const { role } = useContext(CurrentUserContext);
  const internalAdmin = ['internal_admin', 'super_internal_admin'].includes(
    role
  );
  const { data: networkData } = useQuery(networksQuery, {
    fetchPolicy: 'cache-only',
    skip: !internalAdmin && role !== 'support',
  });

  const networks = networkData?.networkMany || [];

  const history = useHistory();
  const { create: createNotification } = useNotifications();
  const [changeUserRegion, { loading: updating }] = useMutation(
    updateOneUserMutation,
    {
      update: (cache, { data }) => {
        cache.writeData({
          id: user.id,
          data: omit(data.agentUpdateOne, ['crmProfiles']),
        });
      },
      onCompleted: () => {
        createNotification('success', 'Successfully changed Agent Region.');
        history.push('/agents');
      },
      onError: () => {
        createNotification('danger', 'Failed to change Agent Region.');
      },
    }
  );
  return (
    <Modal {...props}>
      <Form
        initialValues={{
          marketplace: user.marketplace,
          networkName: user.network.networkName,
          networkID: undefined,
          companyID: undefined,
        }}
        onSubmit={(values) => {
          changeUserRegion({
            variables: {
              input: {
                marketplace: values.marketplace,
                companyID: values.companyID,
                networkID: values.networkID,
                agentID: user?.agentID,
                subID: user?.subID,
                id: user?.id,
              },
            },
          });
        }}
      >
        {({ values, setValues }) => {
          const changeRegion = (network: any): void => {
            // TODO - We can abstract this to serverside
            setValues({
              ...values,
              companyID: network.companyID,
              marketplace: network.marketplace,
              networkID: network.networkID,
              networkName: network.networkName,
            });
          };
          return (
            <>
              <ModalHeader wrapTag={CardHeader}>
                Change Agent Region
              </ModalHeader>
              <ModalBody>
                Changing the Agent&apos;s marketplace will pause and delete all
                of their active campaigns. After, you will not be make changes
                to this user as they are under a different region.
                <div className="mt-3 d-flex align-items-center justify-content-center">
                  <FormGroup className="mb-0">
                    <Label>From</Label>
                    <UncontrolledDropdown disabled>
                      <DropdownToggle>
                        {user.network.networkName!}
                      </DropdownToggle>
                    </UncontrolledDropdown>
                  </FormGroup>
                  <FaArrowRight className="mx-3 mt-4" />
                  <FormGroup className="mb-0">
                    <Label>To</Label>
                    <UncontrolledDropdown>
                      <DropdownToggle caret>
                        {values.networkID ? values.networkName! : 'New Region'}
                      </DropdownToggle>
                      <DropdownMenu>
                        {networks
                          .filter((networkID) => networkID !== user.networkID)
                          .map((network, index) => (
                            <DropdownItem
                              key={index}
                              onClick={() => changeRegion(network)}
                              active={values.companyID === network.marketplace}
                            >
                              {network.networkName}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </FormGroup>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-end">
                <Button color="secondary" onClick={() => props.toggle()}>
                  Cancel
                </Button>
                <Button
                  color="danger"
                  type="submit"
                  disabled={!values.companyID || updating}
                >
                  Change
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default AgentsConfirmDeleteModal;
