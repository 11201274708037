import React, { useState } from 'react';
import {
  Container,
  Button,
  ListGroup,
  ListGroupItem,
  Col,
  Row,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { NewVendorModal } from '@/modals';
import { CopyButton } from '@/components';
import { useQuery } from 'react-apollo';
import { vendorManyQuery } from '@/graphql/queries';
import { RouteComponentProps } from 'react-router';
import classNames from 'classnames';

const VendorsListPage: React.FC<RouteComponentProps> = ({ history }) => {
  const [newVendorModalOpen, setNewVendorModalOpen] = useState(false);
  const [vendorAssignModalOpen, setVendorAssignModalOpen] = useState(false);
  const toggleVendorAssignModal = (): void =>
    setVendorAssignModalOpen((o) => !o);
  const [vendorID, setVendorID] = useState('');

  const { data, loading } = useQuery(vendorManyQuery, {
    variables: { ignoreAccessControl: true },
  });
  const vendors = data?.vendorMany || [];

  return (
    <>
      <Container>
        <div className="mt-5 d-flex align-items-center justify-content-between">
          <h3 className="my-0">Vendors</h3>
          <Button
            color="primary"
            size="lg"
            onClick={() => setNewVendorModalOpen(true)}
          >
            Create Vendor
          </Button>
        </div>

        <ListGroup className="mt-5">
          {vendors?.map((vendor: any) => (
            <ListGroupItem
              id={`${vendor.vendorID}-${vendor.id}`}
              tag={Row}
              noGutters
              key={vendor.id}
              className="mb-3 d-flex align-items-center justify-content-between"
            >
              <Col xs={1}>
                <Button
                  id={`${vendor.id}-edit-button`}
                  color="secondary"
                  className="align-items-center"
                  disabled={loading}
                  onClick={() => history.push(`/vendors/${vendor.id}/general`)}
                >
                  Edit
                </Button>
              </Col>
              <Col xs={3} className="d-flex flex-column text-center">
                <span className="h5">{vendor.vendorID}</span>
                <small>Vendor ID</small>
              </Col>
              <Col xs={3} className="d-flex flex-column text-center">
                <span className="h5">{vendor.vendorName}</span>
                <small>Name</small>
              </Col>
              <Col xs={1}>
                <CopyButton
                  id={`${vendor.vendorID}-copy-button`}
                  content={vendor.vendorID}
                  className="float-right"
                />
              </Col>
            </ListGroupItem>
          ))}
          {vendors?.length === 0 && <h4>No Price Tiers</h4>}
        </ListGroup>
      </Container>
      <NewVendorModal
        toggle={() => setNewVendorModalOpen((o) => !o)}
        isOpen={newVendorModalOpen}
      />
    </>
  );
};

export default VendorsListPage;
