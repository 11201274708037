import React, { useEffect, useState } from 'react';
import { Field } from '@availity/form';
import { useFormikContext } from 'formik';
import Button from '@/components/LoadingButton';
import { FaTrash, FaExclamationTriangle } from 'react-icons/fa';
import { useLazyQuery } from 'react-apollo';
import brokerPhoneRateLimitQuery from '@/graphql/queries/brokerPhoneRateLimit';
import { LabelWithToolTip } from '@/components';
import ConfirmWarningModal from '../../../../../../modals/ConfirmWarningModal';
import PhoneInputField from './PhoneInputField';

const PhoneDropdown: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = (): void => setIsModalOpen((o) => !o);
  const [isRateLimited, setIsRateLimited] = useState(false);
  const { values, setFieldValue } = useFormikContext<{
    layerID: string;
    liveTransferPhoneNumber: string;
    phoneLookUpValue: string;
  }>();
  const [rateLimit, { data }] = useLazyQuery(brokerPhoneRateLimitQuery, {
    variables: {
      layerID: values.layerID,
    },
    onCompleted: () => {
      setIsRateLimited(data.brokerPhoneRateLimit);
    },
    fetchPolicy: 'no-cache',
  });

  if (values.liveTransferPhoneNumber) {
    return (
      <>
        <LabelWithToolTip
          name="liveTransferPhoneNumber"
          label="Live Transfer Phone"
          helpMessage="Phone number that the lead will be calling to."
        />
        <Field
          name="liveTransferPhoneNumber"
          disabled
          append={
            <>
              <Button
                color="danger"
                onClick={() => {
                  rateLimit();
                  toggleModal();
                }}
              >
                <FaTrash size={18} />
              </Button>
            </>
          }
        />

        <ConfirmWarningModal
          isOpen={isModalOpen}
          toggle={toggleModal}
          onAccept={() => {
            if (!isRateLimited) {
              setFieldValue('liveTransferPhoneNumber', '');
            }
          }}
          header={
            <div className="d-flex">
              <FaExclamationTriangle
                className="text-danger align-self"
                size="28"
              />
              <span style={{ paddingLeft: 10 }}>
                {isRateLimited ? `Rate Limited` : `Delete Assigned Phone`}
              </span>
            </div>
          }
          buttonText={
            isRateLimited ? undefined : { text: 'Delete', color: 'danger' }
          }
        >
          {isRateLimited
            ? `In order to prevent this feature from being spammed, we limit changing a recently assigned phone number to once an hour. 
            Please try again later.`
            : `Warning: This action will delete your current assigned number. You
          will have to find a new number before saving.`}
        </ConfirmWarningModal>
      </>
    );
  }

  return (
    <>
      <PhoneInputField />
    </>
  );
};

export default PhoneDropdown;
