import React, { useEffect, useState } from 'react';
import { SelectField } from '@availity/select';
import { FaInfoCircle } from 'react-icons/fa';
import {
  Card,
  CardTitle,
  Row,
  Col,
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupText,
} from 'reactstrap';
import { MoneyField, SwitchButton } from '@/components';
import { useAdmin, useNetwork } from '@/hooks';
import { useQuery } from '@apollo/react-hooks';
import { Field, Input } from '@availity/form';
import { vendorManyQuery } from '@/graphql/queries';
import { useFormikContext } from 'formik';

const BidEnhancmentCard: React.FC<{ isNew: boolean }> = ({ isNew }) => {
  const { network } = useNetwork();
  const { values, setFieldValue, setFieldTouched }: any = useFormikContext();
  const createSettings = (): any => {
    return (
      <>
        <>
          <Col xs={2}>
            <Label>Dynamic/Override</Label>
            <div id="ap-ehancement-dynamic-switch-button">
              <SwitchButton
                width={120}
                checked={!!values.apSettings.apBidEnhancmentDynamic}
                onlabel="Dynamic"
                offlabel="Override"
                onstyle="success"
                offstyle="primary"
                onChange={
                  () =>
                    setFieldValue(
                      'apSettings.apBidEnhancmentDynamic',
                      !values.apSettings.apBidEnhancmentDynamic
                    )
                  // setAPSettings({...apSettings, apBidEnhancmentDynamic: !apSettings.apBidEnhancmentDynamic})
                }
              />
            </div>
          </Col>
          {values.apSettings.apBidEnhancmentDynamic ? (
            <Col xs={2}>
              <Label>Fixed or Percent</Label>
              <div id="ap-ehancement-percantage-switch-button">
                <SwitchButton
                  width={120}
                  checked={!!values.apSettings.apPercentageEnabled}
                  onlabel="Percent"
                  offlabel="Fixed"
                  onstyle="success"
                  offstyle="primary"
                  onChange={() => {
                    setFieldValue(
                      'apSettings.apPercentageEnabled',
                      !values.apSettings.apPercentageEnabled
                    );
                    // setAPSettings({...apSettings, apPercentageEnabled: !apSettings.apPercentageEnabled})
                  }}
                />
              </div>
            </Col>
          ) : null}

          <Col xs="4" className="ml-2">
            {!values.apSettings.apPercentageEnabled ? (
              <MoneyField
                id="apenhanced-bid-dollar"
                name="apSettings.apBidPrice"
                label={
                  values.apSettings.apBidEnhancmentDynamic
                    ? 'Change price by $'
                    : 'Override Price too $'
                }
                size="md"
              />
            ) : (
              // <Field
              //   id="apenhanced-bid-dollar"
              //   name="apSettings.apBidPrice"
              //   label={
              //     values.apBidEnhancmentDynamic
              //       ? 'Change price by $'
              //       : 'Override Price too $'
              //   }
              //   type="number"
              //   onBlur={({ target: { value } }) => {
              //     setFieldValue(
              //       'apSettings.apBidPercentage',
              //       Math.round(parseFloat(value) * 100)
              //     );
              //     setFieldTouched('apBidPercentage', true, true);
              //     // setAPSettings({...apSettings, apBidPrice: value})
              //   }}
              //   precision={2}
              //   step={0.1}
              //   prepend="$"
              //   // helpMessage="If no price is sent with a lead sent through this campaign, this is the price that will be given to it."
              // />
              <Field
                id="apenhanced-bid-percent"
                name="apSettings.apBidPercentage"
                label="Change price by %"
                type="number"
                onBlur={({ target: { value } }) => {
                  setFieldValue(
                    'apSettings.apBidPercentage',
                    parseInt(value, 10)
                  );
                  setFieldTouched('apBidPercentage', true, true);
                  // setAPSettings({...apSettings, apBidPercentage: value})
                }}
                precision={0}
                step={1}
                prepend="%"
                // helpMessage="If no price is sent with a lead sent through this campaign, this is the price that will be given to it."
              />
            )}
          </Col>
        </>
        <Row style={{ marginTop: 20, padding: 20 }}>
          <Col>
            <Field
              id="ap-wpm-min"
              name="apSettings.apWPMMin"
              label="Min WPM"
              type="number"
              onBlur={({ target: { value } }) => {
                setFieldValue('apSettings.apWPMMin', parseInt(value, 10));
                setFieldTouched('apWPMMin', true, true);
                // setAPSettings({...apSettings, apWPMMin: value})
              }}
              prepend=">"
              helpMessage="Words Per Minute"
            />
          </Col>
          <Col>
            <Field
              id="ap-wpm-max"
              name="apSettings.apWPMMax"
              label="Max WPM"
              type="number"
              onBlur={({ target: { value } }) => {
                setFieldValue('apSettings.apWPMMax', parseInt(value, 10));
                setFieldTouched('apWPMMax', true, true);
                // setAPSettings({...apSettings, apWPMMax: value})
              }}
              prepend="<"
              helpMessage="Words Per Minute"
            />
          </Col>
          <Col>
            <Field
              id="ap-kpm-min"
              name="apSettings.apKPMMin"
              label="Min KPM"
              type="number"
              onBlur={({ target: { value } }) => {
                setFieldValue('apSettings.apKPMMin', parseInt(value, 10));
                setFieldTouched('apKPMMin', true, true);
                // setAPSettings({...apSettings, apKPMMin: value})
              }}
              prepend="<"
              helpMessage="Keys Per Minute"
            />
          </Col>
          <Col>
            <Field
              id="ap-kpm-max"
              name="apSettings.apKPMMax"
              label="Max KPM"
              type="number"
              onBlur={({ target: { value } }) => {
                setFieldValue('apSettings.apKPMMax', parseInt(value, 10));
                setFieldTouched('apKPMMax', true, true);
                // setAPSettings({...apSettings, apKPMMax: value})
              }}
              prepend="<"
              helpMessage="Keys Per Minute"
            />
          </Col>
        </Row>
      </>
    );
  };

  // override duplicate checks allow adjustment
  // override active demand check allow adjustment
  // override all other filters allow adjustment

  return (
    <Card body className="mb-3">
      <Row>
        <Col xs={2}>
          <CardTitle tag="h4" className="lead text-uppercase">
            API Type
          </CardTitle>
          <div id="api-type-switch-button">
            <SwitchButton
              width={120}
              checked={values.apiType === 'pingPost'}
              onlabel="Ping Post"
              offlabel="Direct Post"
              onstyle="success"
              offstyle="primary"
              onChange={() => {
                setFieldValue(
                  'apiType',
                  values.apiType === 'direct' ? 'pingPost' : 'direct'
                );
              }}
            />
          </div>
        </Col>
        {values.apiType === 'pingPost' && (
          <>
            <Col xs={2}>
              <CardTitle tag="h4" className="lead text-uppercase">
                Bid Enabled
              </CardTitle>
              <div id="bid-enabled-switch-button">
                <SwitchButton
                  width={120}
                  checked={!!values.pingPostEnabled}
                  onlabel="Enabled"
                  offlabel="Disabled"
                  onstyle="success"
                  offstyle="primary"
                  onChange={() =>
                    setFieldValue('pingPostEnabled', !values.pingPostEnabled)
                  }
                />
              </div>
            </Col>
            {/* removed for now as we are not using bid calc */}
            {/* {network?.allowDynamicBidding ? (
              <Col xs={2}>
                <CardTitle tag="h4" className="lead text-uppercase">
                  Bid Type
                </CardTitle>
                <div id="bid-type-switch-button">
                  <SwitchButton
                    width={120}
                    checked={values.pingPostType === 'dynamic'}
                    onlabel="Dynamic"
                    offlabel="Fixed"
                    onstyle="success"
                    offstyle="primary"
                    onChange={() => {
                      setFieldValue(
                        'pingPostType',
                        values.pingPostType === 'fixed' ? 'dynamic' : 'fixed'
                      );
                    }}
                  />
                </div>
              </Col>
            ) : null} */}
            <Col xs="2">
              <MoneyField
                id="lead-source-money-field"
                name="fixedBidPrice"
                label="Ping Post Bid Price"
                size="md"
              />
            </Col>
          </>
        )}
        {values.apiType === 'pingPost' ? null : (
          <Col xs="8" className="ml-2">
            <Field
              id="lead-source-vendor-price"
              name="vendorPrice"
              label="Default Lead Price"
              type="number"
              onBlur={({ target: { value } }) => {
                setFieldValue('vendorPrice', parseFloat(value).toFixed(2));
                setFieldTouched('vendorPrice', true, true);
              }}
              prepend="$"
              helpMessage="If no price is sent with a lead sent through this campaign, this is the price that will be given to it."
            />
            {values.vendor?.flags?.sendsPricesWithLeads && (
              <p className="text-muted d-flex align-items-center">
                <FaInfoCircle className="text-warning mr-2" />{' '}
                {values.vendor!.vendorName} is known to send prices with leads.
                In this case, the default lead price will be overwritten.
              </p>
            )}
          </Col>
        )}
      </Row>
      {values.apiType === 'pingPost' && (
        <Row className="mt-5">
          <Col xs="2">
            <CardTitle tag="h4" className="lead text-uppercase">
              Budget
            </CardTitle>
            <SwitchButton
              width={120}
              checked={values.hasBudget}
              onlabel="Budget ON"
              offlabel="Budget OFF"
              onstyle="success"
              offstyle="primary"
              onChange={() => {
                if (values.hasBudget) {
                  setFieldValue('maxSpendPerHour', 0);
                  setFieldValue('maxSpend', 0);
                }
                setFieldValue('hasBudget', !values.hasBudget);
              }}
            />
          </Col>
          {values.hasBudget && (
            <>
              <Col xs="2">
                <MoneyField
                  id="max-spend-money-field"
                  name="maxSpend"
                  label="Max Spend"
                  size="md"
                />
              </Col>
              <Col xs="2">
                <Field
                  id="max-spend-Hour"
                  name="maxSpendPerHour"
                  label="Spend Interval (Hours)"
                  type="number"
                  onBlur={({ target: { value } }) => {
                    setFieldValue('maxSpendPerHour', parseInt(value, 10));
                    setFieldTouched('maxSpendPerHour', true, true);
                    // setAPSettings({...apSettings, apWPMMax: value})
                  }}
                  helpMessage="Max Spend Per Hour"
                />
              </Col>
            </>
          )}
        </Row>
      )}

      {values.apiType === 'pingPost' && (
        <Row className="mt-5">
          <Col xs={2}>
            <Label>Bid Enhancment</Label>
            <div id="api-type-switch-button">
              <SwitchButton
                width={120}
                checked={!!values.apBidEnhancmentEnabled}
                onlabel="On"
                offlabel="Off"
                onstyle="success"
                offstyle="primary"
                onChange={() => {
                  setFieldValue(
                    'apBidEnhancmentEnabled',
                    !values.apBidEnhancmentEnabled
                  );
                }}
              />
            </div>
          </Col>
          {values.apBidEnhancmentEnabled ? createSettings() : null}
        </Row>
      )}
    </Card>
  );
};

export default BidEnhancmentCard;
