import React from 'react';
import { FaSync } from 'react-icons/fa';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

interface ButtonToggleProps {
  active: boolean;
  loading?: boolean;
  onToggle: () => void;
}
const ButtonToggle: React.FC<ButtonToggleProps> = ({
  active,
  loading,
  onToggle,
}) => {
  if (loading) {
    return <FaSync className="loading-sync" size={24} />;
  }

  return (
    <BootstrapSwitchButton
      width={70}
      checked={active}
      onlabel="On"
      offlabel="Off"
      onstyle="success"
      offstyle="danger"
      onChange={onToggle}
    />
  );
};

export default ButtonToggle;
