import React, { useState, useEffect } from 'react';
import { ListGroupItem, Row, Col, Button, Badge } from 'reactstrap';
import classnames from 'classnames';
import { Agent, Lead } from '@sonic-web-dev/types';
import moment from 'moment';
import {
  FaCopy,
  FaCheck,
  FaCross,
  FaCheckCircle,
  FaTimesCircle,
  FaQuestionCircle,
} from 'react-icons/fa';
import { formatCurrencyForDisplay } from '@/utils';
import { CopyButton } from '@/components';

interface RefundListItemProps {
  serviceCaseID: string;
  agentID: string;
  leadID: string;
  networkID: string;
  leadPhone: string;
  modified: string;
  refundOption: string;
  refundReason: string;
  refundType: string;
  soldDate: string;
  status: string;
  emailValid?: boolean;
  created: string;
  type: string;
  amount: number;
  summary: string;
  agent: Agent;
  updatedBy: Agent;
  lead: Lead;
  openRefundModal: (serviceCaseID: string, deny?: boolean) => void;
}

const getEmailStatusBadge = (emailValid?: boolean) => {
  if (emailValid === null || emailValid === undefined) {
    return (
      <FaQuestionCircle className="text-white" title="Email not validated." />
    );
  }

  return emailValid ? (
    <FaCheckCircle className="text-success" title="Email verified" />
  ) : (
    <FaTimesCircle className="text-danger" title="Bad email." />
  );
};

const RefundListItem: React.FunctionComponent<RefundListItemProps> = ({
  agentID,
  agent,
  lead,
  refundOption,
  refundReason,
  created,
  serviceCaseID,
  leadID,
  status,
  refundType,
  emailValid,
  type,
  updatedBy,
  summary,
  modified,
  openRefundModal,
  amount,
}) => (
  <ListGroupItem
    className={classnames(
      'mb-3 d-flex align-items-center',
      'border-4 border-top-0 border-bottom-0 border-right-0',
      {
        'border-danger': refundType === 'DENY',
        'border-primary': status === 'pending',
        'border-success': refundType === 'FULL',
        'border-warning': refundType === 'PARTIAL',
      }
    )}
    tag={Row}
    noGutters
  >
    <Col xs={6} className="mb-2">
      Case {serviceCaseID}
    </Col>
    <Col xs={status === 'pending' ? 6 : 3} className="mb-2">
      Created {moment(created).format('MM/DD/YY hh:mm A')}
    </Col>
    {status === 'completed' && (
      <Col xs={3}>
        Processed by {updatedBy.firstName} {updatedBy.lastName}
      </Col>
    )}
    <Col xs={3}>
      <h4>
        Agent <CopyButton content="agentID" className="ml-3" />
      </h4>
      <div>
        {agent.firstName} {agent.lastName}
      </div>
      <div>{agent.phone}</div>
      <div>{agent.email}</div>
    </Col>
    <Col xs={3}>
      <h4>
        Lead <CopyButton className="ml-3" content={leadID} />
      </h4>
      <div>
        Price:{' '}
        <span className="text-success">
          {formatCurrencyForDisplay(lead.price! * 100)}
        </span>
      </div>
      <div>{lead.orgPhone}</div>
      <div>
        {lead.email} {getEmailStatusBadge(emailValid)}
      </div>
    </Col>
    <Col xs={3}>
      <h4>Refund Details</h4>
      <div>{refundOption}</div>
      <div>{refundReason}</div>
      <div>{type}</div>
    </Col>
    {status === 'completed' ? (
      <Col xs={3}>
        <h4>Result</h4>
        <div>Processed on {moment(modified).format('MM/DD/YY hh:mm A')}</div>
        <div>
          {refundType === 'DENY' ? (
            <span className="text-danger">Denied</span>
          ) : (
            <>
              Refunded{' '}
              <span className="text-warning">
                {formatCurrencyForDisplay(amount)}
              </span>
            </>
          )}
        </div>
        <div>Summary: {summary || 'N/A'}</div>
      </Col>
    ) : (
      <Col xs={3} className="d-flex flex-column text-center align-items-center">
        <Button className="w-100 my-1" color="primary" disabled>
          Dial
        </Button>
        <Button
          className="w-100 my-1"
          color="warning"
          onClick={() => openRefundModal(serviceCaseID)}
        >
          Refund
        </Button>
        <Button
          className="w-100 my-1"
          color="danger"
          onClick={() => openRefundModal(serviceCaseID, true)}
        >
          Deny
        </Button>
      </Col>
    )}
  </ListGroupItem>
);

export default RefundListItem;
