import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { vendorDashboardQuery } from '@/graphql/queries';
import { useNetwork } from '@/hooks';
import { toUTCDate } from '@/utils';
import DashboardItem from './DashboardItem';

const LeadSourceItem: React.SFC<any> = ({
  created,
  vendorID,
  leadType,
  dateType,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);
  const { network } = useNetwork();
  const toggleExpanded = () => setExpanded((o) => !o);

  console.log(props);

  const start = toUTCDate(created.gte, true);
  const end = toUTCDate(created.lte, false);

  const { data, loading } = useQuery(vendorDashboardQuery, {
    skip: !expanded,
    pollInterval: 300000,
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        networkID: network?.networkID,
        vendorID,
        mpVendorCampaignID: props.name,
        key: 'region',
        start,
        end,
        leadType,
        dateType,
      },
    },
  });

  const regions = data?.dashboards?.vendors.items || [];

  return (
    <DashboardItem
      toggleExpanded={toggleExpanded}
      expanded={expanded && !loading}
      expanding={loading}
      {...props}
    >
      <div className="mt-3">
        <h6 className="pl-3">States</h6>
        {regions.map((region: any) => (
          <DashboardItem
            color="warning"
            className="mt-3 mx-3 border-top border-dark"
            key={`${vendorID}-${props.name}-${region.name}`}
            openExportsModal={props.openExportsModal}
            queryParams={{
              ...props.queryParams,
              r: region.name,
            }}
            // queryParams={{
            //   r: region.name,
            //   v: vendorID,
            //   vl: props.name,
            //   fr: created.gte,
            //   to: created.lte,
            //   lt: leadType,
            // }}
            {...region}
          />
        ))}
      </div>
    </DashboardItem>
  );
};

const LayerItem: React.SFC<any> = ({
  created,
  leadType,
  dateType,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);
  const { network } = useNetwork();
  const toggleExpanded = () => setExpanded((o) => !o);
  // TODO - we can do better cacheing where we track all the queries and their poll intervals
  // and then just refetch all at one time. Or if the user hasnt done anything on the dashboard
  // Then lets just wait until they are back to refetch

  console.log(props);

  const start = toUTCDate(created.gte, true);
  const end = toUTCDate(created.lte, false);

  const { data, loading } = useQuery(vendorDashboardQuery, {
    skip: !expanded,
    pollInterval: 300000,
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        networkID: network?.networkID,
        layerID: props.name,
        key: 'mpVendorCampaignID',
        start,
        end,
        leadType,
        dateType,
      },
    },
  });

  const vendorCampaigns = data?.dashboards?.vendors.items || [];

  return (
    <DashboardItem
      toggleExpanded={toggleExpanded}
      expanded={expanded && !loading}
      expanding={loading}
      layerID={props.name}
      dateType={dateType}
      // queryParams={{
      //   v: props.name,
      //   fr: created.gte,
      //   to: created.lte,
      //   lt: leadType,
      // }}
      queryParams={{
        ...props.queryParams,
        vl: props.name,
      }}
      {...props}
    >
      <div className="mt-3">
        <h5 className="pl-3">Categories</h5>
        {vendorCampaigns.map((vc: any) => (
          <div className="pl-3 pr-3">
            <LeadSourceItem
              color="info"
              className="mt-3"
              key={`${props.name}-${vc.name}`}
              {...vc}
              openExportsModal={props.openExportsModal}
              created={created}
              leadType={leadType}
              vendorID={props.vendorID}
              layerID={props.name}
              dateType={dateType}
              // queryParams={{
              //   v: props.vendorID,
              //   vl: props.name,
              //   vc: vc.name,
              //   fr: created.gte,
              //   to: created.lte,
              //   lt: leadType,
              // }}
              queryParams={{
                ...props.queryParams,
                vl: props.name,
                vc: vc.name,
              }}
              displayName={vc.description
                .split('_')
                .splice(
                  vc.name.startsWith('usha_') ||
                    vc.name.startsWith('jf_') ||
                    vc.name.startsWith('jb_') ||
                    vc.name.startsWith('cg_') ||
                    vc.name.startsWith('next_') ||
                    vc.name.startsWith('aged_') ||
                    vc.name.startsWith('quick_') ||
                    vc.name.startsWith('mass_') ||
                    vc.name.startsWith('trademarc_') ||
                    vc.name.startsWith('calendly_') ||
                    vc.name.startsWith('old_') ||
                    (vc.name.startsWith('quote') &&
                      !vc.name.startsWith('quoteStorm') &&
                      !vc.name.startsWith('quoteHound') &&
                      !vc.name.startsWith('quotewizard'))
                    ? 2
                    : 1
                )
                .join(' ')}
            />
          </div>
        ))}
      </div>
    </DashboardItem>
  );
};

const VendorItem: React.SFC<any> = ({
  created,
  leadType,
  dateType,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);
  const { network } = useNetwork();
  const toggleExpanded = () => setExpanded((o) => !o);
  // TODO - we can do better cacheing where we track all the queries and their poll intervals
  // and then just refetch all at one time. Or if the user hasnt done anything on the dashboard
  // Then lets just wait until they are back to refetch

  console.log(props);

  const start = toUTCDate(created.gte, true);
  const end = toUTCDate(created.lte, false);

  const { data, loading } = useQuery(vendorDashboardQuery, {
    skip: !expanded,
    pollInterval: 300000,
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        networkID: network?.networkID,
        vendorID: props.name,
        key: 'layerID',
        start,
        end,
        leadType,
        dateType,
      },
    },
  });

  const vendorCampaigns = data?.dashboards?.vendors.items || [];

  return (
    <DashboardItem
      toggleExpanded={toggleExpanded}
      expanded={expanded && !loading}
      expanding={loading}
      vendorID={props.name}
      queryParams={{
        v: props.name,
        fr: created.gte,
        to: created.lte,
        lt: leadType,
        dt: dateType,
      }}
      {...props}
    >
      <div className="mt-3">
        <h4 className="pl-3">Lead Sources</h4>
        {vendorCampaigns.map((vc: any) => (
          <LayerItem
            color="secondary"
            className="mt-3"
            key={`${props.name}-${vc.name}`}
            vendorID={props.name}
            {...vc}
            openExportsModal={props.openExportsModal}
            created={created}
            leadType={leadType}
            dateType={dateType}
            queryParams={{
              v: props.name,
              vl: vc.name,
              fr: created.gte,
              to: created.lte,
              lt: leadType,
              dt: dateType,
            }}
            displayName={vc.description
              .split('_')
              .splice(
                vc.name.startsWith('usha_') ||
                  vc.name.startsWith('jf_') ||
                  vc.name.startsWith('jb_') ||
                  vc.name.startsWith('cg_') ||
                  vc.name.startsWith('next_') ||
                  vc.name.startsWith('aged_') ||
                  vc.name.startsWith('quick_') ||
                  vc.name.startsWith('mass_') ||
                  vc.name.startsWith('trademarc_') ||
                  vc.name.startsWith('calendly_') ||
                  vc.name.startsWith('old_') ||
                  (vc.name.startsWith('quote') &&
                    !vc.name.startsWith('quoteStorm') &&
                    !vc.name.startsWith('quoteHound') &&
                    !vc.name.startsWith('quotewizard'))
                  ? 2
                  : 1
              )
              .join(' ')}
          />
        ))}
      </div>
    </DashboardItem>
  );
};

export default VendorItem;
