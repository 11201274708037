import gql from 'graphql-tag';
import { CampaignFragment } from '@/graphql/fragments';

export default gql`
  query campaignManyQuery($agentID: ID!) {
    campaignMany(agentID: $agentID) {
      ...CampaignFragment
    }
  }
  ${CampaignFragment}
`;
