import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { IAuthPieceProps } from 'aws-amplify-react/lib/Auth/AuthPiece';
import Amplify from 'aws-amplify';
import {
  AgentsSearchPage,
  AgentsDetailView,
  PriceTiersSearchPage,
  ReportsPage,
  DashboardPage,
  LeadsPage,
  LeadSourcesPage,
  PriceTierDetailView,
  LeadSourceDetailView,
  InfastructurePage,
  RefundsPage,
  NetworksSettings,
  VendorsListPage,
  VendorsDetailView,
  AnalyticsPage,
} from '@/areas';
import {
  NotificationProvider,
  LoadingBarProvider,
  NetworkProvider,
  CurrentUserProvider,
  AttributesProvider,
} from '@/context';
import { useAdmin } from '@/hooks';
import AmplifyAuthenticator from '@/components/Auth/AmplifyAuthenticator';
import { LeftNav, TopNav } from '@/components/Nav';
import access_obj from '@/utils/uiAccess';
import { Vars } from './utils';

Amplify.configure(Vars.awsmobile);

const AppWrapper: React.SFC = () => {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem('admin-navbar-collapsed') === 'true'
  );

  const { role } = useAdmin();
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    ref.current?.scrollTo(0, 0);
  }, [location]);

  const route_map = {
    vendor: (
      <Route component={VendorsListPage} exact path="/vendors" key="vendors" />
    ),
    vendor_detail: (
      <Route
        component={VendorsDetailView}
        exact
        path="/vendors/:id/:path"
        key="vendors-id-path"
      />
    ),
    network: (
      <Route
        component={NetworksSettings}
        // exact
        path="/settings"
        key="network-path"
      />
    ),
    price_tier: (
      <Route
        component={PriceTiersSearchPage}
        exact
        path="/price-tiers"
        key="price-tiers"
      />
    ),
    price_tier_id: (
      <Route
        component={PriceTierDetailView}
        exact
        path="/price-tiers/:id"
        key="price-tiers-id"
      />
    ),
    agents: (
      <Route component={AgentsSearchPage} exact path="/agents" key="agents" />
    ),
    agent_detail: (
      <Route
        component={AgentsDetailView}
        exact
        path="/agents/:id/:path"
        key="agents-id-path"
      />
    ),
    reports: (
      <Route component={ReportsPage} exact path="/reports" key="reports" />
    ),
    dashboard: <Route component={DashboardPage} exact path="/" key="home" />,
    leads: <Route component={LeadsPage} exact path="/leads" key="leads" />,
    lead_sources: (
      <Route
        component={LeadSourcesPage}
        exact
        path="/lead-sources"
        key="lead-sources"
      />
    ),
    lead_sources_id: (
      <Route
        component={LeadSourceDetailView}
        exact
        path="/lead-sources/:id"
        key="lead-sources-id"
      />
    ),
    infastructure: (
      <Route
        component={InfastructurePage}
        exact
        path="/infastructure"
        key="infastructure"
      />
    ),
    analytics: (
      <Route
        component={AnalyticsPage}
        exact
        path="/analytics"
        key="analytics"
      />
    ),
    refunds: (
      <Route component={RefundsPage} exact path="/refunds" key="refunds" />
    ),
  } as any;

  const pages = useMemo(
    () => access_obj[role].page.allowed.map((page: any) => route_map[page]),
    [role]
  );

  return (
    <div className="h-100 d-flex">
      <LeftNav collapsed={collapsed} />
      <div
        ref={ref}
        className="sonic-container flex-fill d-flex flex-column"
        style={{ height: '100%', overflowY: 'scroll' }}
      >
        <TopNav
          toggleCollapse={() => {
            setCollapsed((o) => !o);
            localStorage.setItem(
              'admin-navbar-collapsed',
              collapsed ? 'false' : 'true'
            );
          }}
        />
        <LoadingBarProvider>
          <Switch>
            {pages}
            {access_obj[role].page.base_redirect}
          </Switch>
        </LoadingBarProvider>
      </div>
    </div>
  );
};

const AuthorizedApp: React.FC<IAuthPieceProps> = ({ authState }) => {
  return authState === 'signedIn' || authState === 'verifyContact' ? (
    <NetworkProvider>
      <AttributesProvider>
        <CurrentUserProvider>
          <NotificationProvider>
            <AppWrapper />
          </NotificationProvider>
        </CurrentUserProvider>
      </AttributesProvider>
    </NetworkProvider>
  ) : null;
};

const App: React.SFC = () => (
  <AmplifyAuthenticator>
    <AuthorizedApp />
  </AmplifyAuthenticator>
);

export default App;
