import gql from 'graphql-tag';
import { VendorFragment } from '../fragments';

export default gql`
  mutation vendorUpdateOne($input: UpdateOneVendorInput) {
    __typename
    vendorUpdateOne(input: $input) {
      ...VendorFragment
    }
  }
  ${VendorFragment}
`;
