import React from 'react';
import { Card, CardTitle, FormText } from 'reactstrap';
import { Field } from '@availity/form';
import { useFormikContext } from 'formik';
import classNames from 'classnames';

const RejectLeadsCard: React.FC = () => {
  const { values } = useFormikContext();

  return (
    <div>
      <Card
        body
        className={classNames({
          'd-none': values?.filters?.length === 0,
        })}
      >
        <CardTitle tag="h4" className="lead text-uppercase">
          Rejects
        </CardTitle>
        <FormText>
          By using this system, you are instructing the system to send an HTTP
          400 status code with an error message to your vendor for all filtered,
          duplicate or disconnected leads. Some vendors refer to this as "Cancel
          Logic". The vendor will recieve a response with the message "filtered"
          for filtered rejects, "duplicate" for duplicate rejects and
          "disconnected - no append" for disconnected rejects. Once a lead has
          been rejected it can no longer be delivered to you. Reporting on
          rejected leads can be found in the marketplace.
        </FormText>
        {/* <Field
        name="rejectDisconnected"
        type="checkbox"
        disabled={!vendor!.flags.allowDisconnectedRejects}
        groupClassName="mb-2"
        label={
          <span className="d-flex align-items-center">
            {vendor!.flags.allowDisconnectedRejects ? (
              <FaInfoCircle className="text-warning mr-1" />
            ) : (
              <FaBan className="text-danger mr-1" />
            )}
            {vendor!.vendorName}{' '}
            {vendor!.flags.allowDisconnectedRejects ? 'may' : 'does not'} allow
            lead rejection based on the phone connection status.
          </span>
        }
      />
      <Field
        groupClassName="mb-2"
        name="rejectDuplicates"
        type="checkbox"
        disabled={!vendor!.flags.allowDuplicateRejects}
        label={
          <span className="d-flex align-items-center">
            {vendor!.flags.allowDuplicateRejects ? (
              <FaInfoCircle className="text-warning mr-1" />
            ) : (
              <FaBan className="text-danger mr-1" />
            )}
            {vendor!.vendorName}{' '}
            {vendor!.flags.allowDuplicateRejects ? 'may' : 'does not'} allow
            lead rejection based on the phone duplicate status.
          </span>
        }
      /> */}
        <Field
          id="rejects-compliance-checkbox"
          name="acceptsTermsAndConditions"
          groupClassName="align-items-start mb-2"
          className="mt-1"
          label="I understand that my leads will be rejected based on the checks and filters I have selected."
          type="checkbox"
        />
      </Card>
    </div>
  );
};

export default RejectLeadsCard;
