import React from 'react';
import { FormGroup, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import { useField } from 'formik';
import MoneyInput from './MoneyInput';

const MoneyField: React.FC<any> = ({
  name,
  minimum,
  label,
  size,
  placeholder,
  id,
  noCents,
}) => {
  const [field, { error }, { setValue }] = useField(name);

  return (
    <FormGroup style={{ margin: 0 }}>
      <Label for={name}>{label}</Label>
      <InputGroup size={size || 'sm'} style={{ minWidth: '100px' }}>
        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
        <MoneyInput
          id={id}
          minimum={minimum}
          onChange={(v: number) => setValue(v)}
          value={field.value}
          placeholder={placeholder}
          noCents={noCents}
        />
      </InputGroup>
      <div className="text-danger">{error}</div>
    </FormGroup>
  );
};

export default MoneyField;
