/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { Field } from '@availity/form';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';
import { CSVDownloader, CSVReader } from 'react-papaparse';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useNetwork, useNotifications, usePageLoader } from '@/hooks';
import { Form, Formik } from 'formik';
import { updateCompanyListMutation } from '@/graphql/mutations';
import { networkDetailsQuery } from '@/graphql/queries';
import classNames from 'classnames';

const EditCompanyListsModal: React.FC<{
  isOpen: boolean;
  toggle: () => void;
  list: any;
  listType: string;

  refetch: () => void;
}> = ({ toggle = () => {}, isOpen, list, listType, refetch }) => {
  const { create } = useNotifications();
  const { start, complete } = usePageLoader();
  const { network } = useNetwork();

  const { data, loading } = useQuery(networkDetailsQuery, {
    skip: !isOpen || listType !== 'postalCode',
    variables: { id: network?.id, fetchAll: true },
    onCompleted: () => complete(),
    onError: (error) => console.error(error),
  });
  if (loading) {
    start();
  }
  const postalCodeDisplayValues =
    data?.currentNetwork?.companyLists?.postalCode?.values;
  const allValues = (postalCodeDisplayValues || list.region)
    .split('\n')
    .map((v: any) => ({ [listType]: v }));

  const [updateCompanyList, { loading: mutationLoad }] = useMutation(
    updateCompanyListMutation,
    {
      onCompleted: () => {
        create('success', 'Company list updated successfully');
        refetch();
        toggle();
        complete();
      },
      onError: (e) => {
        console.error(e);
        create('error', 'Error updating company list');
      },
    }
  );
  if (mutationLoad) {
    start();
  }
  if (loading && !data) return null;

  return (
    <Formik
      initialValues={{ listType: '', listValues: '', listFile: '' }}
      onSubmit={(values, { resetForm }) => {
        updateCompanyList({
          variables: {
            id: network?.networkID,
            list: values.listType,
            data: values.listFile
              ? values.listFile
              : values.listValues
                  .replace(/(^[ \t]*\n)/gm, '')
                  .replace(/\n*$/, ''),
          },
        });
        resetForm();
      }}
    >
      {({ values, setFieldValue }) => {
        useEffect(() => {
          setFieldValue('listType', listType);
          if (values.listType === 'postalCode') {
            if (
              list?.postalCode?.total > 1000 &&
              isOpen &&
              listType === 'postalCode'
            ) {
              create('warning', 'Postal Code list over 1000 items.');
            }
            setFieldValue('listValues', postalCodeDisplayValues);
          }
          if (values.listType === 'region') {
            setFieldValue('listValues', list?.region);
          }
        }, [values.listType, listType]);

        return (
          <Modal isOpen={isOpen} toggle={toggle} size="md">
            <Form>
              <ModalHeader wrapTag={CardHeader} toggle={toggle}>
                List: {values.listType}
              </ModalHeader>
              <ModalBody className="">
                <Row className="d-flex align-items-center">
                  <Col xs={6}>
                    <Field
                      name="listValues"
                      type="textarea"
                      label="Values"
                      style={{ height: 200 }}
                    />
                    <small
                      className={classNames('text-muted', {
                        'd-none':
                          list?.postalCode?.total > 1000 &&
                          listType !== 'postalCode',
                      })}
                    >
                      It is recommended to use a .CSV file when list is over
                      1000 values.
                    </small>
                    <div className="mt-2">
                      <CSVDownloader data={allValues} bom filename={listType}>
                        <Button type="button">Download .CSV</Button>
                      </CSVDownloader>
                    </div>
                  </Col>
                  <Col
                    xs={6}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <CSVReader
                      onDrop={(file) => {
                        const csvString = file
                          .filter((f) => f.data[0] !== '')
                          .map((f) => f.data[0])
                          .join('\n');
                        setFieldValue('listFile', csvString);
                      }}
                      addRemoveButton
                      style={{
                        dropArea: {
                          height: 220,
                          width: 220,
                        },
                        dropFile: {
                          background: 'rgba(255, 255, 255, 0.4)',
                        },
                        fileNameInfo: {
                          background: '#1285ff',
                        },
                        fileSizeInfo: {
                          background: '#1285ff',
                        },
                      }}
                    >
                      <div>Click/Drop .CSV file here.</div>
                    </CSVReader>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="py-1">
                <Button color="secondary" type="button" onClick={toggle}>
                  Cancel
                </Button>
                <Button color="primary" className="float-right" type="submit">
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditCompanyListsModal;
