import React from 'react';
import { Card, CardTitle, Row, Col, Button } from 'reactstrap';
import { ButtonToggle, MoneyField } from '@/components';
import { Field } from '@availity/form';
import { FaTrash } from 'react-icons/fa';
import { useField } from 'formik';
import { useRequiredAttributes } from '@/hooks';

interface RangeFilterProps {
  attribute: 'income' | 'age';
  index: number;
  remove: () => void;
  className?: string;
  label?: string;
}

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const RangeFilter: React.FC<RangeFilterProps> = ({
  attribute,
  index,
  remove,
  className,
  label,
}) => {
  const Tag = attribute === 'income' ? MoneyField : Field;

  const [{ value: skipMissing }, , { setValue: setSkipMissing }] = useField(
    `filters.${index}.skipMissing`
  );

  const [
    { value: skipMissingOnPing },
    ,
    { setValue: setSkipMissingOnPing },
  ] = useField(`filters.${index}.skipMissingOnPing`);

  useRequiredAttributes([attribute], !skipMissing);
  useRequiredAttributes([attribute], !skipMissingOnPing);

  return (
    <Col xs={12} className={className}>
      <Card body className="h-100">
        <CardTitle
          tag="h4"
          className="lead text-uppercase d-flex justify-content-between align-items-center"
        >
          <span>{capitalize(label)} Filter</span>
          <FaTrash className="text-danger cursor-pointer" onClick={remove} />
        </CardTitle>
        <Row noGutters id={`${attribute}-range-filter-row`}>
          <Col xs={3}>
            <Tag
              id={`${attribute}-range-filter-min`}
              size="md"
              name={`filters.${index}.min`}
              type="number"
              placeholder="0"
              label="Min"
              noCents
            />
          </Col>
          <Col xs={3} className="ml-4">
            <Tag
              id={`${attribute}-range-filter-max`}
              size="md"
              name={`filters.${index}.max`}
              type="number"
              placeholder="0"
              label="Max"
              noCents
            />
          </Col>
          <Col xs={4} className="ml-4">
            <Row>
              <Col>
                <div className="mb-2">Skip if Missing</div>
                <div id={`${attribute}-skip-missing`}>
                  <ButtonToggle
                    key={`${attribute}-skip-missing`}
                    active={skipMissing}
                    onToggle={(value: any) => setSkipMissing(value)}
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-2">Skip Missing On Ping</div>
                <div id={`${attribute}-skip-missing`}>
                  <ButtonToggle
                    key={`${attribute}-skip-missing-on-ping`}
                    active={skipMissingOnPing}
                    onToggle={(value: any) => setSkipMissingOnPing(value)}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default RangeFilter;
