import gql from 'graphql-tag';
import { VendorLayerFragment } from '@/graphql/fragments';

export default gql`
  query vendorLayerManyQuery($networkID: String) {
    vendorLayerMany(networkID: $networkID) {
      ...VendorLayerFragment
    }
  }
  ${VendorLayerFragment}
`;
