import React, { useMemo, useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { FaPlus } from 'react-icons/fa';
import useDestinations from '@/hooks/useDestinations';
import VanillaSoftCRMProfile from './VanillaSoftCRMProfile';
import ISalesCRMProfile from './ISalesCRMProfile';
import CustomAPIProfile from './custom';
import useAgent from '../useAgent';
import ConvosoProfile from './ConvosoProfile';

const CRMProfiles: React.FC = () => {
  const {
    user: {
      agentID,
      crmProfiles,
      flags,
      network: { availibleCRMs },
    },
  } = useAgent();
  const { destinations } = useDestinations(agentID);
  const [activeTab, setActiveTab] = useState<string | undefined>();

  const { vanillaSoftDirect = {}, iSalesCRM = {}, convoso = {} } = crmProfiles;
  useEffect(() => {
    if (!activeTab && availibleCRMs) {
      setActiveTab(Object.keys(availibleCRMs)[0]);
    } else if (!activeTab && flags?.allowCustomAPIs) {
      setActiveTab(destinations?.[0]?.destID || 'custom');
    }
  }, [availibleCRMs, activeTab]);
  console.log(availibleCRMs);

  // eslint-disable-next-line array-callback-return
  const filteredAvailibleCRMs = Object.keys(availibleCRMs).reduce(
    (temp: any, key: string) => {
      if (availibleCRMs[key]) {
        return { ...temp, [key]: availibleCRMs[key] };
      }
      return temp;
    },
    {}
  );
  console.log(filteredAvailibleCRMs);
  const apiDestinations = useMemo(
    () => destinations.filter((d) => d.destinationType === 'api'),
    [destinations]
  );

  const crmTabs = useMemo(
    () =>
      Object.keys(filteredAvailibleCRMs || {})
        .map((key) => (
          <NavItem
            key={key}
            active={activeTab === key}
            onClick={() => setActiveTab(key)}
          >
            <NavLink active={activeTab === key}>
              {filteredAvailibleCRMs[key].label}
            </NavLink>
          </NavItem>
        ))
        .concat(
          apiDestinations.map((d) => (
            <NavItem
              key={d.destID}
              active={activeTab === d.destID}
              onClick={() => setActiveTab(d.destID)}
            >
              <NavLink active={activeTab === d.destID}>
                {d.destinationName}
              </NavLink>
            </NavItem>
          )),
          flags.allowCustomAPIs && (
            <NavItem
              key="custom"
              active={activeTab === 'custom'}
              onClick={() => setActiveTab('custom')}
            >
              <NavLink active={activeTab === 'custom'}>
                <FaPlus />
              </NavLink>
            </NavItem>
          )
        )
        .filter(Boolean),
    [filteredAvailibleCRMs, activeTab, apiDestinations]
  );

  const crmFields = useMemo(
    () =>
      Object.keys(filteredAvailibleCRMs || {})
        .map((key) => {
          if (key === 'vanillaSoftDirect') {
            return (
              <TabPane key={key} tabId={key}>
                <VanillaSoftCRMProfile
                  crmID={key}
                  //   onChange={crmUpdated}
                  {...filteredAvailibleCRMs[key]}
                  {...vanillaSoftDirect}
                />
              </TabPane>
            );
          }
          if (key === 'iSalesCRM') {
            return (
              <TabPane key={key} tabId={key}>
                <ISalesCRMProfile
                  crmID={key}
                  //   onChange={crmUpdated}
                  {...filteredAvailibleCRMs[key]}
                  {...iSalesCRM}
                />
              </TabPane>
            );
          }
          if (key === 'convoso') {
            return (
              <TabPane key={key} tabId={key}>
                <ConvosoProfile
                  crmID={key}
                  //   onChange={crmUpdated}
                  {...filteredAvailibleCRMs[key]}
                  {...convoso}
                />
              </TabPane>
            );
          }
          return (
            <TabPane tabId={key}>
              <h1>{key}</h1>
            </TabPane>
          );
        })
        .concat(
          apiDestinations.map((d) => (
            <TabPane key={d.destID} tabId={d.destID}>
              <CustomAPIProfile destinationID={d.destID} />
            </TabPane>
          )),
          flags.allowCustomAPIs && (
            <TabPane key="custom" tabId="custom">
              <CustomAPIProfile destinationID="new" />
            </TabPane>
          )
        )
        .filter(Boolean),
    [
      filteredAvailibleCRMs,
      apiDestinations,
      vanillaSoftDirect,
      iSalesCRM,
      convoso,
    ]
  );

  return (
    <Card className="mb-3">
      <CardHeader>
        <CardTitle tag="h5" className="py-2 h4 mb-0">
          Integrations
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Nav tabs>{crmTabs}</Nav>
        <TabContent activeTab={activeTab} className="mt-3">
          {crmFields}
        </TabContent>
      </CardBody>
    </Card>
  );
};

export default CRMProfiles;
