import React from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalProps,
  ModalHeader,
  Button,
  CardHeader,
} from 'reactstrap';
import { Agent } from '@/types/Agent';
import { useMutation } from '@apollo/react-hooks';
import { resetOneUserPasswordMutation } from '@/graphql/mutations';
import { useNotifications } from '@/hooks';
import { Form, Field } from '@availity/form';
import * as yup from 'yup';
import { SelectField } from '@availity/select';

export interface AgentsPasswordResetModalProps extends ModalProps {
  user: Agent;
}

const AgentsPasswordResetModal: React.SFC<AgentsPasswordResetModalProps> = ({
  user,
  ...props
}) => {
  const { create: createNotification } = useNotifications();
  const [resetPassword] = useMutation(resetOneUserPasswordMutation, {
    onCompleted: () => {
      createNotification('success', 'Successfully Reset Password.');
      props.toggle!();
    },
    onError: () => {
      createNotification('danger', 'Failed to Reset Password.');
    },
  });

  return (
    <Modal {...props}>
      <Form
        onSubmit={(values) => {
          resetPassword({
            variables: {
              input: {
                agentID: user.agentID,
                isTemporary: values.isTemporary,
                newPassword: values.password,
              },
            },
          });
        }}
        initialValues={{
          password: '',
          confirmPassword: '',
          isTemporary: true,
        }}
        validationSchema={yup.object().shape({
          password: yup
            .string()
            .required('This field is required.')
            .min(6, 'Your password must be more than 6 characters'),
          confirmPassword: yup
            .string()
            .required('This field is required.')
            .min(6, 'Your password must be more than 6 characters')
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
          isTemporary: yup.bool(),
        })}
      >
        <ModalHeader wrapTag={CardHeader}>Reset Agent Password</ModalHeader>
        <ModalBody>
          <Field
            name="password"
            type="password"
            placeholder="Enter Password..."
            label="Password"
            autoComplete="new-password"
          />
          <Field
            name="confirmPassword"
            type="password"
            autoComplete="new-password"
            placeholder="Confirm Password..."
            label="Confirm Password"
          />
          <SelectField
            id="temporary-password-dropdown"
            name="isTemporary"
            label="Is Temporary Password"
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
          />
          <ModalFooter className="d-flex justify-content-end">
            <Button color="secondary">Cancel</Button>
            <Button color="danger" type="submit">
              Reset
            </Button>
          </ModalFooter>
        </ModalBody>
      </Form>
    </Modal>
  );
};

export default AgentsPasswordResetModal;
