import { useFormikContext } from 'formik';
import { useEffect, useContext } from 'react';
import { AttributesContext } from '@/context';

export default (requiredAttributes: string[], required: boolean): void => {
  const {
    pingAttributes: standardPingAttributes,
    postAttributes: standardPostAttributes = [],
  } = useContext(AttributesContext);

  const {
    values: { postAttributes, pingAttributes },
    setFieldValue,
  } = useFormikContext<{
    postAttributes: string[];
    pingAttributes: string[];
  }>();

  useEffect(() => {
    const missingAttributes = requiredAttributes
      .filter((requiredAttr) => !postAttributes.includes(requiredAttr))
      .filter((a) => {
        return standardPostAttributes.find((s) => {
          return s.internalAttribute === a;
        });
      });

    if (required && missingAttributes.length > 0) {
      setFieldValue('postAttributes', postAttributes.concat(missingAttributes));
    }
  }, [
    postAttributes,
    required,
    requiredAttributes,
    standardPostAttributes,
    setFieldValue,
  ]);

  useEffect(() => {
    const pingRequiredAttributes = requiredAttributes.filter((r: string) => {
      return standardPingAttributes?.find((a: any) => {
        return a.internalAttribute === r;
      });
    });

    const missingAttributes = pingRequiredAttributes.filter(
      (requiredAttr) => !pingAttributes.includes(requiredAttr)
    );

    if (required && missingAttributes.length > 0) {
      setFieldValue('pingAttributes', pingAttributes.concat(missingAttributes));
    }
  }, [
    standardPingAttributes,
    pingAttributes,
    required,
    requiredAttributes,
    setFieldValue,
  ]);
};
