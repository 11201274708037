import React, { useEffect } from 'react';
import { Form, Field } from '@availity/form';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Button,
} from 'reactstrap';
import { useRouteMatch } from 'react-router';
import { useMutation, useQuery } from 'react-apollo';
import { vendorNode } from '@/graphql/queries';
import { FormikSwitch } from '@/components';
import {
  networkUpdateVendorsMutation,
  updateOneVendorMutation,
} from '@/graphql/mutations';
import { VendorFragment } from '@/graphql/fragments';
import {
  useNetwork,
  useNetworks,
  useNotifications,
  usePageLoader,
} from '@/hooks';
import { SelectField } from '@availity/select';

const VendorsGeneralTab: React.FC = () => {
  const match = useRouteMatch<{
    id: string;
  }>('/vendors/:id');

  const { data: vendorData, loading: vendorLoad } = useQuery(vendorNode, {
    variables: {
      id: match!.params.id,
    },
  });
  const { data: networkData, loading: networkLoad } = useNetworks({
    fetchPolicy: 'network-only',
  });

  const vendor = vendorData?.node;
  const networkIDs = networkData?.networkMany?.map((n: any) => n.networkID);
  const assignedNetworks =
    networkData?.networkMany
      ?.filter((n: any) => {
        if (n.allowedVendors?.includes(vendor?.vendorID)) return n;
      })
      .map((n: any) => n.networkID) || [];

  const { create: createNotification } = useNotifications();
  const { complete } = usePageLoader();

  const [networkUpdateVendors, { data: networkMutationData }] = useMutation(
    networkUpdateVendorsMutation,
    {
      onCompleted: () => {
        createNotification('success', 'Successfully assigned Networks.');
      },
      onError: (error) => {
        createNotification(
          'danger',
          error.message.replace('GraphQL error:', '')
        );
        complete();
      },
    }
  );

  const [updateVendor, { data: vendorMutationData }] = useMutation(
    updateOneVendorMutation,
    {
      onCompleted: () => {
        createNotification('success', 'Successfully updated Vendor.');
      },
      onError: (error) => {
        createNotification(
          'danger',
          error.message.replace('GraphQL error:', '')
        );
        complete();
      },
    }
  );

  if (networkMutationData && vendorMutationData) {
    complete();
  }

  if (vendorLoad || !vendorData) {
    return null;
  }

  if (networkLoad || !networkData) {
    return null;
  }

  return (
    <>
      <Form
        initialValues={{
          vendorID: vendor.vendorID,
          vendorName: vendor.vendorName,
          flags: vendor.flags,
          vendorPriceIncluded: vendor.vendorPriceIncluded,
          assignedNetworks,
        }}
        onSubmit={(values) => {
          updateVendor({
            variables: {
              input: {
                vendorID: values.vendorID,
                vendorName: values.vendorName,
                flags: values.flags,
                vendorPriceIncluded: values.vendorPriceIncluded,
              },
            },
          });
          networkUpdateVendors({
            variables: {
              input: {
                networkIDs,
                assignedIDs: values.assignedNetworks || [],
                vendorID: values.vendorID,
              },
            },
          });
        }}
      >
        {() => {
          return (
            <>
              <Card>
                <CardHeader>
                  <CardTitle
                    tag="h5"
                    className="py-2 h4 mb-0 text-uppercase d-flex align-items-center"
                  >
                    Vendor Information
                  </CardTitle>
                </CardHeader>
                <CardBody tag={Row}>
                  <Col xs={7}>
                    <Field
                      label="Vendor Name"
                      name="vendorName"
                      placeholder="Vendor Name..."
                    />
                  </Col>
                  <Col xs={5} className="d-flex justify-content-center">
                    <FormikSwitch
                      height={28}
                      width={56}
                      handleDiameter={26}
                      name="vendorPriceIncluded"
                      className="mt-3 "
                      label="Vendor Price Included"
                    />
                  </Col>
                  <Col xs={12}>
                    <SelectField
                      name="assignedNetworks"
                      label="Assigned Networks"
                      labelKey="label"
                      valueKey="value"
                      options={[].concat(
                        networkData?.networkMany?.map((n: any) => ({
                          label: n.networkName,
                          value: n.networkID,
                        }))
                      )}
                      isMulti
                    />
                  </Col>
                </CardBody>
              </Card>
              <Card className="mt-3">
                <CardHeader>
                  <CardTitle
                    tag="h5"
                    className="py-2 h4 mb-0 text-uppercase d-flex align-items-center"
                  >
                    Flags
                  </CardTitle>
                </CardHeader>
                <CardBody tag={Row}>
                  <Col xs={3}>
                    <FormikSwitch
                      name="flags.allowAgentDirect"
                      label="Allow Agent Direct"
                    />
                  </Col>
                  <Col xs={3}>
                    <FormikSwitch
                      name="flags.allowDisconnectedRejects"
                      label="Allow Disconnected Rejects"
                    />
                  </Col>
                  <Col xs={3}>
                    <FormikSwitch
                      name="flags.allowDuplicateRejects"
                      label="Allow Duplicate Rejects"
                    />
                  </Col>
                  <Col xs={3}>
                    <FormikSwitch
                      name="flags.sendsPricesWithLeads"
                      label="Send Prices with Leads"
                    />
                  </Col>
                </CardBody>
              </Card>
              <Button
                type="submit"
                color="primary"
                className="float-right mt-3"
                size="lg"
              >
                Save
              </Button>
              <Button
                type="reset"
                color="secondary"
                className="float-right mt-3 mr-2"
                size="lg"
              >
                Reset
              </Button>
            </>
          );
        }}
      </Form>
    </>
  );
};

export default VendorsGeneralTab;
