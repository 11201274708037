import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ListGroup,
  ListGroupItem,
  ModalFooter,
  ModalProps,
  Button,
  Col,
} from 'reactstrap';
import { Pagination, PaginationContent } from '@availity/pagination';
import { PaginationControls, DebouncedInput } from '@/components';
import { FaUser, FaUsers } from 'react-icons/fa';
import { Form, CheckboxGroup, Checkbox } from '@availity/form';
import { useMutation, useApolloClient, useQuery } from '@apollo/react-hooks';
import { agentPaginationQuery, assignedAgentsQuery } from '@/graphql/queries';
import { updateAgentsTiersMutation } from '@/graphql/mutations';
import { useNotifications, useNetwork } from '@/hooks';
import usePriceTier from '@/areas/PriceTiers/DetailView/usePriceTier';

const roleText = (role: string): string => {
  switch (role) {
    case 'user':
      return 'User';
    case 'manager':
      return 'Manager';
    case 'admin':
      return 'Administrator';
    case 'super_admin':
      return 'Super Admin';
    default:
      return 'User';
  }
};

const BulkTierAssignModal: React.SFC<Omit<ModalProps, 'toggle'>> = ({
  isOpen,
  toggle,
  id,
  ...props
}) => {
  const client = useApolloClient();
  const { tier } = usePriceTier(id);
  const priceTierID = tier?.priceTierID;
  const priceTierName = tier?.name;
  const { network } = useNetwork();
  const networkID = network?.networkID;
  const currentNetworkID = network?.id;

  const { create: createNotification } = useNotifications();

  const { data: agentsTierData } = useQuery(assignedAgentsQuery, {
    skip: !priceTierID && !networkID && !isOpen,
    fetchPolicy: 'network-only',
    variables: {
      priceTierID,
      networkID,
    },
    onError: (error) => {
      createNotification(
        'danger',
        `Error loading assigned agents: ${error.message}`
      );
    },
  });

  const [updateAgentsTiers, { loading: updating }] = useMutation(
    updateAgentsTiersMutation,
    {
      onCompleted: () => {
        createNotification('success', 'Successfully updated Agents.');
        toggle();
      },
      onError: () => {
        createNotification('danger', 'Failed to update Agents.');
      },
    }
  );

  if (!agentsTierData) return null;

  return (
    <Modal isOpen={isOpen} toggle={toggle} {...props}>
      <Form
        initialValues={{
          priceTierID,
          assignedAgents: agentsTierData?.assignedAgents,
          searchText: '',
        }}
        onSubmit={(values) => {
          updateAgentsTiers({
            variables: {
              input: {
                assignedAgents: values.assignedAgents,
                priceTierID: values.priceTierID,
                networkID,
                exclude: false,
              },
            },
            update: (cache, { data: { agentsTiersUpdate } }) => {
              cache.writeData({
                id,
                data: {
                  assignedAgents: agentsTiersUpdate,
                },
              });
            },
          });
        }}
      >
        {({ values, resetForm }) => {
          useEffect(() => {
            resetForm();
          }, [isOpen]);

          const fetchItems = async (
            currentPage: number,
            itemsPerPage: number
          ): Promise<any> => {
            const response = await client.query({
              query: agentPaginationQuery,
              variables: {
                filter: {
                  networkID: currentNetworkID,
                  q: values.searchText,
                },
                perPage: itemsPerPage,
                page: currentPage,
                orderBy: {
                  firstName: 'ASC',
                },
              },
            });

            const currentPageData = response.data?.agentPagination.items;

            return {
              totalCount: response.data.agentPagination.count,
              items: currentPageData,
            };
          };

          return (
            <>
              <ModalHeader toggle={toggle}>
                Assign Agents to: {priceTierName}
              </ModalHeader>
              <Pagination
                items={fetchItems}
                itemsPerPage={10}
                resetParams={[values.searchText]}
              >
                <ModalBody>
                  <Col xs={12} className="d-flex">
                    <DebouncedInput name="searchText" className="mr-4" />
                    <PaginationControls
                      pageRange={3}
                      marginPages={0}
                      directionLinks
                      style={{ marginTop: 1 }}
                    />
                  </Col>
                  <CheckboxGroup
                    name="assignedAgents"
                    for="assignedAgents"
                    groupClassName="bg-dark"
                  >
                    <PaginationContent
                      containerProps={{
                        className: 'bg-dark text-white',
                      }}
                      containerTag={ListGroup}
                      itemKey="agentID"
                      component={({ agentID, firstName, lastName, role }) => (
                        <ListGroupItem className="d-flex justify-content-between align-items-center">
                          <Checkbox
                            name={agentID}
                            label={`${firstName} ${lastName} - ${roleText(
                              role
                            )}`}
                            value={agentID}
                          />
                          <FaUser />
                        </ListGroupItem>
                      )}
                    />
                  </CheckboxGroup>
                </ModalBody>
              </Pagination>
              <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
                <Button color="primary" type="submit" disabled={updating}>
                  Save
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default BulkTierAssignModal;
