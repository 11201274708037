/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useMemo } from 'react';
import { FaSearch } from 'react-icons/fa';
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroupButtonDropdown,
} from 'reactstrap';
import {
  PaginationControls,
  DebouncedInput,
  ManagerSelect,
} from '@/components';
import { useAdmin } from '@/hooks';
import access_obj from '@/utils/uiAccess';
import { SelectField } from '@availity/select';

const roles = [
  {
    label: 'User',
    value: 'USER',
  },
  {
    label: 'Manager',
    value: 'MANAGER',
  },
  {
    label: 'Admin',
    value: 'ADMIN',
  },
  {
    label: 'Super Admin',
    value: 'SUPER_ADMIN',
  },
];

export interface SearchControlsProps {
  showManager?: boolean | true;
}

const SearchControls: React.SFC<SearchControlsProps> = ({
  showManager = true,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const search_input = {
    status: (
      <Col
        xs={{
          size: 6,
        }}
      >
        <SelectField
          name="status"
          isClearable
          label="Status"
          options={[
            {
              label: 'Active',
              value: 'active',
            },
            {
              label: 'Deleted',
              value: 'deleted',
            },
          ]}
        />
      </Col>
    ),
  } as any;

  const { role } = useAdmin();

  const inputs = useMemo(
    () => access_obj[role].agent.search.map((page: any) => search_input[page]),
    [role]
  );

  const toggleDropdown = () => setDropdownOpen((o) => !o);

  return (
    <div className="d-flex py-3">
      <InputGroup>
        <InputGroupButtonDropdown
          addonType="prepend"
          isOpen={dropdownOpen}
          toggle={toggleDropdown}
        >
          <DropdownToggle split caret>
            Advanced Search{' '}
          </DropdownToggle>
          <DropdownMenu className="p-4">
            <Row style={{ maxWidth: 700, minWidth: 530 }}>
              <Col
                xs={{
                  size: 6,
                  // offset: 1,
                }}
              >
                <SelectField
                  name="roles"
                  isMulti
                  isClearable
                  label="Roles"
                  options={roles}
                />
              </Col>
              <Col
                xs={{
                  size: 6,
                }}
              >
                <SelectField
                  name="agentApproved"
                  label="Agent Approved"
                  isClearable
                  options={[
                    {
                      label: 'Yes',
                      value: true,
                    },
                    {
                      label: 'No',
                      value: false,
                    },
                  ]}
                />
              </Col>
              {showManager && (
                <Col xs={6}>
                  <ManagerSelect
                    name="manager"
                    label="Manager"
                    isClearable
                    className="advanced-search-manager-select"
                  />
                </Col>
              )}
              {inputs}
            </Row>
            <Button
              color="secondary"
              className="float-right"
              style={{
                zIndex: 0,
              }}
              onClick={toggleDropdown}
            >
              Close
            </Button>
          </DropdownMenu>
        </InputGroupButtonDropdown>
        <DebouncedInput
          name="searchText"
          placeholder="Search by keyword..."
          style={{
            maxWidth: 550,
          }}
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <FaSearch size={14} />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      <div className="d-flex">
        <PaginationControls
          className="d-flex justify-content-center"
          listClassName="mb-0"
          pageRange={3}
          marginPages={0}
          directionLinks
          endLinks
        />
      </div>
    </div>
  );
};

export default SearchControls;
