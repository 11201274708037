import gql from 'graphql-tag';
// import { LeadFragment } from '../fragments';

export default gql`
  query GetReportOptions($networkID: String) {
    getReportOptions(networkID: $networkID) {
      vendors {
        vendorID
        vendorName
      }
      layers {
        vendorID
        layerID
        description
      }
      categories {
        layerID
        mpVendorCampaignID
        mpVendorCampaignName
        description
      }
    }
  }
`;
