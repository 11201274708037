import React, { useMemo } from 'react';
import { Card, CardTitle, Row, Col } from 'reactstrap';
import { SelectField } from '@availity/select';
import { FaTrash } from 'react-icons/fa';
import { ButtonToggle, FormikSwitch } from '@/components';
import { capitalize } from '@/utils';
import { useNetwork, useRequiredAttributes } from '@/hooks';
import { useField, useFormikContext } from 'formik';

interface StaticListFilterProps {
  attribute: string;
  index: number;
  options: any[];
  labelKey?: string;
  valueKey?: string;
  remove: () => void;
  className?: string;
  level: string;
  label: string;
}

const creatableListFilters = [
  'postalCode',
  'vendorSubID',
  'vendorPubID',
  'vendorLandingPage',
  'vendorSource',
  'activeProspectOperatingSystem',
  'activeProspectIp',
  'activeProspectDevice',
  'activeProspectPageUrl',
  'activeProspectParentPageURL',
  'activeProspectPageID',
  'activeProspectFormInputMethod',
  'activeProspectFramed',
  'activeProspectCountryCode',
  'activeProspectBrowser',
  'activeProspectTimeZone',
  'activeProspectDomain',
];

const levelOptions = [
  {
    label: 'Company',
    value: 'company',
  },
  {
    label: 'List',
    value: 'layerID',
  },
];

const StaticListFilter: React.FC<StaticListFilterProps> = ({
  attribute,
  index,
  level,
  labelKey,
  valueKey,
  remove,
  className,
  label,
}) => {
  const { network } = useNetwork();
  const [{ value: values }, , { setValue: setValues }] = useField(
    `filters.${index}.values`
  );
  const [{ value: skipMissing }, , { setValue: setSkipMissing }] = useField(
    `filters.${index}.skipMissing`
  );

  const optionsForAttribute = useMemo(() => {
    if (attribute === 'region') {
      return network!.allowedStates.map((s) => ({ label: s, value: s }));
    }

    return values?.map((v: any) => ({ label: v.label, value: v.label })) || [];
  }, [attribute, network, values]);

  // If the field is required then we have to make sure we have the attribute on the required
  // attributes array
  useRequiredAttributes([attribute], !skipMissing);

  return (
    <Col xs={12} className={className}>
      <Card body className="h-100">
        <CardTitle
          tag="h4"
          className="lead text-uppercase d-flex justify-content-between align-items-center"
        >
          <span>{capitalize(label)} Filter</span>
          <FaTrash className="text-danger cursor-pointer" onClick={remove} />
        </CardTitle>
        <Row noGutters id={`${attribute}-static-list-filter-row`}>
          <Col xs={2}>
            <SelectField
              id={`${attribute}-static-list-filter-level`}
              name={`filters.${index}.level`}
              options={levelOptions}
              label="Level"
            />
          </Col>
          <Col xs={5} className="ml-4">
            <SelectField
              id={`${attribute}-static-list-filter-values`}
              name={`filters.${index}.values`}
              options={optionsForAttribute}
              label="Options"
              isDisabled={level === 'company'}
              onChange={(val: any) => {
                if (val === null) {
                  setValues([]);
                }
              }}
              placeholder={
                level === 'company'
                  ? 'Your options are preselected by your company'
                  : 'Select a value...'
              }
              labelKey={labelKey || 'label'}
              valueKey={valueKey || 'value'}
              isMulti
              creatable={creatableListFilters.includes(attribute)}
              raw
            />
          </Col>
          <Col xs={2} className="ml-4">
            <SelectField
              id={`${attribute}-static-list-filter-include`}
              label="Type"
              name={`filters.${index}.include`}
              options={[
                {
                  label: 'Include',
                  value: true,
                },
                {
                  label: 'Exclude',
                  value: false,
                },
              ]}
            />
          </Col>
          <Col xs={2} className="ml-4">
            <div className="mb-2">Skip if Missing</div>
            <div id={`${attribute}-skip-missing`}>
              <ButtonToggle
                key={`${attribute}-skip-missing`}
                active={skipMissing}
                onToggle={(value: any) => setSkipMissing(value)}
              />
            </div>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default StaticListFilter;
