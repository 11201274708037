import React from 'react';
import { Card, CardTitle, FormText } from 'reactstrap';
import { Field } from '@availity/form';
//import { PriceBadge } from '@/components'; some future options may have a price
import { useNetwork } from '@/hooks';

const QualityCard: React.FC = () => {
  // const { network } = useNetwork();
  return (
    <Card body className="h-100">
      <CardTitle tag="h4" className="lead text-uppercase">
        Quality
      </CardTitle>
      <Field
        id="add-enhanced-data-checkbox"
        type="checkbox"
        name="enhanceProfile"
        label="Add Enhanced Data"
        value="enhanceProfile"
      />

      <Field
        id="reject-disconnected-checkbox"
        type="checkbox"
        name="rejectDisconnected"
        label="Reject Disconnected"
        value="rejectDisconnected"
      />

      <Field
        id="reject-dnc-checkbox"
        type="checkbox"
        name="rejectDNC"
        label="Reject DNC"
        value="rejectDNC"
      />
    </Card>
  );
};

export default QualityCard;
