import React from 'react';
import { ListGroup, Container } from 'reactstrap';
import { Pagination, PaginationContent } from '@availity/pagination';
import { useApolloClient } from '@apollo/react-hooks';
import { Formik } from 'formik';
import {
  PaginationControls,
  NoItemsMessage,
  SearchSummary,
} from '@/components';
import { agentPaginationQuery } from '@/graphql/queries';
import { usePageLoader, useNetwork } from '@/hooks';
import AgentListItem from './AgentListItem';
import SearchControls from './Controls/SearchControls';

const AgentSearchPage: React.SFC = () => {
  const client = useApolloClient();
  const { network } = useNetwork();
  const { start, complete } = usePageLoader();

  return (
    <Formik
      initialValues={{
        searchText: '',
        manager: undefined,
        roles: [],
        status: undefined,
        agentApproved: undefined,
        sort: {
          firstName: 'ASC',
        },
      }}
      onSubmit={() => {}}
    >
      {({ values }) => {
        const fetchItems = async (
          currentPage: number,
          itemsPerPage: number
        ): Promise<any> => {
          start();
          const response = await client.query<any>({
            query: agentPaginationQuery,
            variables: {
              perPage: itemsPerPage,
              page: currentPage,
              filter: {
                q: values.searchText,
                networkID: network!.id,
                managerID: values.manager?.id,
                status: values.status !== null ? values.status : undefined,
                roles: values.roles || [],
                agentApproved:
                  typeof values.agentApproved === 'boolean'
                    ? values.agentApproved
                    : undefined,
              },
              orderBy: values.sort,
            },
          });

          const currentPageData = response.data?.agentPagination.items;

          complete();
          return {
            totalCount: response.data.agentPagination.count,
            items: currentPageData.map((a) => {
              return { ...a, editable: true };
            }),
          };
          // }
        };

        return (
          <Container className="h-100 d-flex flex-column">
            <Pagination
              resetParams={[
                values.searchText,
                network.id,
                values.manager,
                values.roles,
                values.agentApproved,
                values.status,
              ]}
              itemsPerPage={25}
              items={fetchItems}
            >
              <SearchControls />
              <SearchSummary className="d-flex justify-content-between py-3" />
              <PaginationContent
                itemKey="agentID"
                component={AgentListItem}
                containerTag={ListGroup}
              />
              <NoItemsMessage className="d-flex justify-content-center align-items-center pt-2 pb-2">
                No Agents found for given search criteria. Try expanding your
                search.
              </NoItemsMessage>
              <div className="d-flex justify-content-end">
                <PaginationControls
                  listClassName="mb-5"
                  pageRange={3}
                  autoHide
                  marginPages={0}
                  directionLinks
                  endLinks
                />
              </div>
            </Pagination>
          </Container>
        );
      }}
    </Formik>
  );
};

export default AgentSearchPage;
