import gql from 'graphql-tag';

export default gql`
  mutation updateCompanyListMutation(
    $id: String!
    $list: String!
    $data: String!
  ) {
    updateCompanyList(input: { id: $id, list: $list, data: $data }) {
      total
      values
    }
  }
`;
