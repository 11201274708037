import React from 'react';
import { useQuery } from 'react-apollo';
import { SelectField } from '@availity/select';
import { useNetwork } from '@/hooks';
import { vendorLayerPaginationQuery } from '@/graphql/queries';

const EarlyHangUpLeadSourceSelect: React.FC<{ vendorID: string }> = ({
  vendorID,
  ...props
}) => {
  const { network } = useNetwork();
  const { data: leadSourceData, loading } = useQuery(
    vendorLayerPaginationQuery,
    {
      variables: {
        perPage: 100,
        filter: {
          vendorID,
          networkID: network?.networkID,
          apiType: 'direct',
          liveTransfersEnabled: false,
        },
      },
    }
  );

  if (loading) return null;

  const { items: leadSources } = leadSourceData.vendorLayerPagination;

  return (
    <SelectField
      debounceTimeout={350}
      delay={350}
      getOptionLabel={(ls: any) => {
        return `${ls.vendor.vendorName} - ${ls.vendorSourceCampaign}`;
      }}
      valueKey="layerID"
      isClearable
      options={
        leadSources.sort((a, b) => a.vendorID.localeCompare(b.vendorID)) || []
      }
      {...props}
    />
  );
};

export default EarlyHangUpLeadSourceSelect;
