import gql from 'graphql-tag';

export default gql`
  query currentNetworkQuery($id: ID!, $fetchAll: Boolean) {
    currentNetwork(id: $id) {
      __typename
      id
      companyLists {
        postalCode(fetchAll: $fetchAll) {
          total
          values
        }
        region
      }
    }
  }
`;
