import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ListGroup,
  ListGroupItem,
  ModalFooter,
  ModalProps,
  Button,
  Col,
} from 'reactstrap';
import { Pagination, PaginationContent } from '@availity/pagination';
import { PaginationControls, DebouncedInput } from '@/components';
import { FaUser, FaUsers } from 'react-icons/fa';
import { Form, CheckboxGroup, Checkbox } from '@availity/form';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { agentPaginationQuery } from '@/graphql/queries';
import { updateAgentsTiersMutation } from '@/graphql/mutations';
import { useNotifications, useNetwork } from '@/hooks';
import usePriceTier from '@/areas/PriceTiers/DetailView/usePriceTier';

const roleText = (role: string): string => {
  switch (role) {
    case 'user':
      return 'User';
    case 'manager':
      return 'Manager';
    case 'admin':
      return 'Administrator';
    case 'super_admin':
      return 'Super Admin';
    default:
      return 'User';
  }
};

const BulkTierAssignModal: React.SFC<Omit<ModalProps, 'toggle'>> = ({
  isOpen,
  toggle,
  id,
  unassign,
  ...props
}) => {
  const client = useApolloClient();
  const {
    tier: { assignedAgents, priceTierID, name: priceTierName },
  } = usePriceTier(id);
  const {
    network: { networkID },
  } = useNetwork();

  const { create: createNotification } = useNotifications();
  const [updateAgentsTiers, { loading: updating }] = useMutation(
    updateAgentsTiersMutation,
    {
      onCompleted: () => {
        createNotification('success', 'Successfully updated Agents.');
        toggle();
      },
      onError: () => {
        createNotification('danger', 'Failed to update Agents.');
      },
    }
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle} {...props}>
      <Form
        onSubmit={(values) => {
          updateAgentsTiers({
            variables: {
              input: {
                assignedAgents: [],
                priceTierID: values.priceTierID,
                networkID,
                exclude: !unassign,
              },
            },
            update: (cache, { data: { agentsTiersUpdate } }) => {
              cache.writeData({
                id,
                data: {
                  assignedAgents: agentsTiersUpdate,
                },
              });
            },
          });
        }}
        initialValues={{
          priceTierID,
          assignedAgents,
          searchText: '',
        }}
      >
        {({ values, resetForm }) => {
          useEffect(() => {
            resetForm();
          }, [isOpen]);

          return (
            <>
              <ModalHeader toggle={toggle}>
                Do you wish to {unassign ? 'un' : ''}assign all Agents in this
                Network?
              </ModalHeader>
              <ModalBody>
                Confirm {unassign ? 'un' : ''}assign all Agents{' '}
                {unassign ? 'from' : 'to'}:{' '}
                <span className="font-italic">{priceTierName}</span>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
                <Button color="primary" type="submit" disabled={updating}>
                  Confirm
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default BulkTierAssignModal;
