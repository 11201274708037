import gql from 'graphql-tag';
import { QueueFragment } from '../fragments';

export default gql`
  mutation createQueueMutation($input: createQueueInput!) {
    createQueue(input: $input) {
      success
      queues {
        ...QueueFragment
      }
    }
  }
  ${QueueFragment}
`;
