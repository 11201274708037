import { useContext } from 'react';
import { NetworkContext } from '@/context';
import { BaseMutationOptions, ExecutionResult } from 'react-apollo';
import { useMutation } from '@apollo/react-hooks';
import { Network } from '@sonic-web-dev/types';
import { updateOneNetworkMutation } from '@/graphql/mutations';
import { NetworkFragment } from '@/graphql/fragments';

export interface CustomNetwork extends Network {
  marketplace: string;
  brokerID: string;
  overflowPriceTierID?: string;
  corpID: string;
  vendorLayerPrices?: {
    rejectDuplicates?: number;
    rejectDisconnected?: number;
    enhanceProfile?: number;
  };
  flags: {
    allowAttributes?: boolean;
  };
  groups: any[];
}
interface NetworkUpdateVariables {
  input: Partial<CustomNetwork>;
}

interface NetworkUpdateResponse {
  agentUpdateOne: CustomNetwork;
}

const useNetwork = ({ createNotification } = {}) => {
  const stuff = useContext(NetworkContext);

  const [updateNetwork, { loading: updating }] = useMutation(
    updateOneNetworkMutation,
    {
      update(cache, { data: { networkUpdateOne } }) {
        cache.writeFragment({
          id: networkUpdateOne.id,
          fragment: NetworkFragment,
          data: networkUpdateOne,
        });
      },

      onCompleted: () =>
        createNotification &&
        createNotification('success', 'Successfully updated network.'),
      onError: () =>
        createNotification &&
        createNotification('danger', 'Failed to update network.'),
    }
  );

  return {
    ...stuff,
    updating,
    update: (
      options?: BaseMutationOptions<
        NetworkUpdateResponse,
        NetworkUpdateVariables
      >
    ): Promise<ExecutionResult> => {
      return updateNetwork(options);
    },
  };
};

export default useNetwork;
