import gql from 'graphql-tag';

export default gql`
  query attributes {
    attributes {
      attributes {
        __typename
        sourceAttribute
        internalAttribute
        attributeCategory
        attributeType
      }
    }
  }
`;
