import gql from 'graphql-tag';

export default gql`
  mutation processAgedLead(
    $maxItems: Int!
    $vendorID: String!
    $layerID: String!
  ) {
    processAgedLeads(
      maxItems: $maxItems
      vendorID: $vendorID
      layerID: $layerID
    ) {
      success
    }
  }
`;
