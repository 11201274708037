import gql from 'graphql-tag';

const AnalyticsAgentFragment = gql`
  fragment AnalyticsAgentFragment on AnalyticsAgent {
    __typename
    agentID
    firstName
    lastName
    email
  }
`;

export default AnalyticsAgentFragment;
