import React from 'react';
import { Row, Col, Card, CardTitle } from 'reactstrap';
import { Field } from '@availity/form';
import { FormikSwitch } from '@/components';

const UploadCard: React.FC = () => {
  return (
    <Card body className="mt-3">
      <CardTitle tag="h4" className="lead text-uppercase">
        Auto Processing
      </CardTitle>
      <Row>
        <Col xs={2} className="mt-3">
          <FormikSwitch
            name="autoProcess"
            label="Auto Process"
            helpMessage="Auto Processes pending aged leads"
          />
        </Col>
        <Col xs={6} className="mt-3">
          <Field
            name="autoProcessThreshold"
            label="Auto Process Threshold"
            helpMessage="The minimum number for auto process to being"
            type="number"
            min={1000}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default UploadCard;
