import React, { useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  IntrospectionFragmentMatcher,
  InMemoryCache,
  ApolloClient,
} from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { QueryClient, QueryClientProvider } from 'react-query';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { Vars } from '@/utils';
import AmplifyAuthenticator from '@/components/Auth/AmplifyAuthenticator';
import { useApolloLink } from '@/hooks';
import getHelmet from '@/utils/helmet';
import { ISignInProps } from 'aws-amplify-react/lib-esm/Auth/SignIn';
import App from './App';
import CacheBuster from './CacheBuster';
import introspectionQueryResultData from './fragmentTypes.json';
import './styles/index.scss';
import '@availity/yup';

const queryClient = new QueryClient();

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({
  fragmentMatcher,
  addTypename: false,
  dataIdFromObject: (o: any) => o?.id,
  cacheRedirects: {
    Query: {
      node: (_, args, { getCacheKey }) => {
        return getCacheKey({
          id: args.id,
        });
      },
      currentNetwork: (_, args, { getCacheKey }) => {
        return getCacheKey({
          id: args.id,
        });
      },
    },
  },
});

Amplify.configure(Vars.awsmobile);

const AuthenticatedApp: React.FunctionComponent<ISignInProps> = ({
  authState,
}) => {
  const link = useApolloLink(authState);

  const client = useMemo(() => {
    if (!link) {
      return null;
    }

    const client = new ApolloClient({
      link,
      connectToDevTools: true,
      cache,
    });

    return client;
  }, [link]);

  if (!link) return null;

  return (
    <ApolloProvider client={client as any}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>
  );
};

const render = async (): Promise<any> => {
  ReactDOM.render(
    <CacheBuster>
      {getHelmet()}
      <QueryClientProvider client={queryClient}>
        <AmplifyAuthenticator>
          <AuthenticatedApp />
        </AmplifyAuthenticator>
      </QueryClientProvider>
    </CacheBuster>,
    document.querySelector('#root')
  );
};

// Render once
render();
