import React, { useState } from 'react';
import { Form } from '@availity/form';
import { DateRangeField } from '@availity/date';
import { Container, Row, CardProps, Col, ListGroup, Spinner } from 'reactstrap';
import { SelectField } from '@availity/select';
import { useQuery } from '@apollo/react-hooks';
import { vendorDashboardQuery } from '@/graphql/queries';
import { FaCalendarMinus } from 'react-icons/fa';
import moment from 'moment';
import { dateRange } from '@availity/yup';
import { LeadsShowExportModal } from '@/modals';
import { useNetwork, useNotifications } from '@/hooks';
import { toUTCDate } from '@/utils';
import VendorItem from './VendorItem';
import DashboardItem from './DashboardItem';

const Dashboard: React.SFC = () => (
  <Container fluid>
    <Form
      initialValues={{
        vendor: undefined,
        leadType: 'vendor',
        dateType: 'created',
        dateRange: {
          startDate: moment().startOf('day').format('YYYY-MM-DD'),
          dateRange: dateRange({}),
          endDate: moment().endOf('day').format('YYYY-MM-DD'),
        },
      }}
      onSubmit={() => {}}
    >
      {({ values }) => {
        console.log(values);

        const startDate = toUTCDate(values.dateRange.startDate, true);
        const endDate = toUTCDate(values.dateRange.endDate, false);

        const [showExportModal, setShowExportModal] = useState(false);
        const { network } = useNetwork();
        const { create } = useNotifications();

        const { data, loading, error } = useQuery(vendorDashboardQuery, {
          pollInterval: 300000,
          fetchPolicy: 'network-only',
          variables: {
            filter: {
              networkID: network?.networkID,
              key: 'vendorID',
              start: startDate,
              end: endDate,
              includeTotal: true,
              leadType: values.leadType,
              dateType: values.dateType,
            },
          },
          skip: !values.dateRange.startDate || !values.dateRange.endDate,
        });

        let vendors;
        let total;

        if (error) {
          console.log(error.message);
          vendors = [];
          total = 0;
        } else {
          vendors = data?.dashboards?.vendors.items || [];
          total = data?.dashboards?.vendors.total;
        }

        console.log(error);

        return (
          <>
            <Row className="mt-5">
              <Col xs={3} sm={2} className="d-flex align-items-center">
                <h2 className="mb-0 d-flex align-items-end h1">
                  Vendor Totals
                </h2>
                {loading && (
                  <Spinner
                    className="ml-3"
                    color="primary"
                    style={{
                      width: 50,
                      height: 50,
                    }}
                  />
                )}
              </Col>
              <Col xs={2}>
                <SelectField
                  name="leadType"
                  label="Lead Type"
                  options={[
                    {
                      label: 'Vendor',
                      value: 'vendor',
                    },
                    // {
                    //   label: 'Split',
                    //   value: 'split',
                    // },
                    {
                      label: 'Recycled',
                      value: 'recycled',
                    },
                    // {
                    //   label: 'Live Transfer',
                    //   value: 'liveTransfer',
                    // },
                  ]}
                />
              </Col>
              <Col xs={2}>
                <SelectField
                  name="dateType"
                  label="Date Type"
                  options={[
                    {
                      label: 'Created Date',
                      value: 'created',
                    },
                    {
                      label: 'Sold Date',
                      value: 'soldDate',
                    },
                    // {
                    //   label: 'Live Transfer',
                    //   value: 'liveTransfer',
                    // },
                  ]}
                />
              </Col>
              <Col
                xs={4}
                sm={{
                  size: 4,
                  offset: 1,
                }}
              >
                <DateRangeField
                  className="leads-date-range"
                  name="dateRange"
                  id="dateRange"
                  label="Date Range (120 Days at a time)"
                  customArrowIcon="-"
                  calendarIcon={
                    <div>
                      <FaCalendarMinus />
                    </div>
                  }
                  datepickerProps={{
                    renderMonthElement: undefined,
                  }}
                  ranges
                />
              </Col>
            </Row>
            <div className="text-muted mb-3 d-flex justify-content-end">
              Costs are weighted averages and does not include processing fees.
            </div>
            <ListGroup>
              {total === 0
                ? 'Query requests must not be greater than 120 Days'
                : total && (
                    <DashboardItem
                      className="bg-transparent mb-3 border-0"
                      {...total}
                      displayName="Totals"
                    />
                  )}
              {vendors.map((v) => (
                <VendorItem
                  key={v.name}
                  openExportsModal={() => setShowExportModal(true)}
                  className="mb-3 border-top border-secondary"
                  leadType={values.leadType}
                  dateType={values.dateType}
                  created={{
                    gte: values.dateRange.startDate,
                    lte: values.dateRange.endDate,
                  }}
                  {...v}
                />
              ))}
              {vendors.length === 0 && !loading && (
                <h4 className="text-center mt-3">No Data For Given Range</h4>
              )}
            </ListGroup>
            <LeadsShowExportModal
              isOpen={showExportModal}
              toggle={() => setShowExportModal((o) => !o)}
            />
          </>
        );
      }}
    </Form>
  </Container>
);

export default Dashboard;
