import React from 'react';
import {
  FaTrash,
  FaArrowRight,
  FaExclamationCircle,
  FaHandHolding,
} from 'react-icons/fa';
import {
  FieldArray,
  useField,
  FieldArrayRenderProps,
  useFormikContext,
  ErrorMessage,
} from 'formik';
import { SelectField } from '@availity/select';
import {
  ListGroup,
  ListGroupItem,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import usePriceTier from '../usePriceTier';
import { CustomPriceTier } from '../../Search/usePriceTiers';

const AssignedVendorCampaignsList: React.FC<Partial<FieldArrayRenderProps>> = ({
  remove,
}) => {
  const { vendorCampaigns, priceTiers, tier: currentTier } = usePriceTier();
  const [{ value: mpVendorCampaignIDs }] = useField<string[]>(
    'mpVendorCampaignIDs'
  );

  const assignedVendorCampaigns = mpVendorCampaignIDs?.map((mvID: string) => {
    const vendorCampaign = vendorCampaigns.find(
      (mv) => mv.mpVendorCampaignID === mvID
    );

    return {
      ...vendorCampaign,
      campaignName: decodeURI(vendorCampaign?.campaignName),
      otherAssignedTiers: priceTiers?.filter(
        (t) =>
          t.mpVendorCampaignIDs.some(
            (mvID) => mvID === vendorCampaign?.mpVendorCampaignID
          ) && currentTier?.priceTierID !== t.priceTierID
      ),
    };
  });

  return (
    <Col xs={6} className="d-flex flex-column">
      <span className="h5">Assigned Lead Sources</span>
      <div className="bg-secondary flex-fill">
        <ListGroup
          className="sonic-container"
          style={{ height: 554, overflowY: 'scroll' }}
        >
          {assignedVendorCampaigns.map((vC, index: number) => {
            if (vC.campaignName === 'undefined') {
              remove!(index);
            }
            return (
              <ListGroupItem
                className="mb-2"
                color="secondary"
                key={`assigned-${vC.mpVendorCampaignID}`}
              >
                <div className="text-muted">
                  {vC.mpVendorCampaignID}{' '}
                  <Link
                    to={`/lead-sources/${vC.layerGlobalID}`}
                    target="_blank"
                    className="ml-2"
                  >
                    <u>Lead Source</u>
                  </Link>
                  {vC.otherAssignedTiers.length > 0 && (
                    <u
                      id={`tiers-${vC.layerID}-${vC.mpVendorCampaignID}`}
                      className="text-primary ml-2"
                      style={{
                        cursor: 'help',
                      }}
                    >
                      Show Other Tiers
                    </u>
                  )}
                </div>
                <div
                  className={classNames('mt-2', { 'd-none': !vC?.description })}
                >
                  <span className="h5 font-weight-bold">{vC?.description}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span>
                    {vC.vendorName} - {vC?.campaignName}
                  </span>
                  {vC.otherAssignedTiers.length > 0 && (
                    <UncontrolledPopover
                      target={`tiers-${vC.layerID}-${vC.mpVendorCampaignID}`}
                      placement="top"
                      trigger="hover"
                    >
                      <PopoverHeader>Assigned Tiers</PopoverHeader>
                      <PopoverBody>
                        {vC.otherAssignedTiers.map((t: CustomPriceTier) => (
                          <div
                            key={`tier-${vC.mpVendorCampaignID}-${t.priceTierID}`}
                            className="d-flex align-items-center"
                          >
                            {t.name}
                          </div>
                        ))}
                      </PopoverBody>
                    </UncontrolledPopover>
                  )}
                  <Button color="danger" onClick={() => remove!(index)}>
                    <FaTrash />
                  </Button>
                </div>
                <div className="text-danger">
                  <ErrorMessage name={`mpVendorCampaignIDs.${index}`} />
                </div>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </div>
    </Col>
  );
};

const recursiveGetParentTiers = (
  priceTiers: CustomPriceTier[],
  mpVendorCampaignID: string,
  fallbackPriceTierID?: string
): CustomPriceTier[] => {
  const tiers = priceTiers.filter(
    (t) => t.fallbackPriceTierID === fallbackPriceTierID
  );

  if (tiers.length === 0) {
    return [];
  }

  // Tiers that have the campaignID selected ie. 0
  const selectedTiers = tiers.filter((t) =>
    t.mpVendorCampaignIDs.some((mvID) => mvID === mpVendorCampaignID)
  );

  const overflowTiers = tiers.filter((t) =>
    t.overflowMpVendorCampaignIDs.some((mvID) => mvID === mpVendorCampaignID)
  );

  return ([] as CustomPriceTier[]).concat(
    selectedTiers,
    ...overflowTiers.map((oT: CustomPriceTier): CustomPriceTier[] =>
      recursiveGetParentTiers(priceTiers, mpVendorCampaignID, oT.name)
    )
  );
};

const UnAssignedVendorCampaignsList: React.SFC<Partial<
  FieldArrayRenderProps
>> = ({ push }) => {
  const {
    vendorCampaigns,
    vendors,
    priceTiers,
    tier: currentTier,
  } = usePriceTier();
  const {
    values: { mpVendorCampaignIDs, overflowMpVendorCampaignIDs, vendorFilter },
  } = useFormikContext<any>();

  const unassignedVendorCampaigns = vendorCampaigns
    .filter(
      (vC) =>
        vC.vendorName === vendorFilter &&
        mpVendorCampaignIDs.every(
          (mvId: string) => mvId !== vC.mpVendorCampaignID
        )
    )
    .map((vC) => ({
      ...vC,
      campaignName: decodeURI(vC?.campaignName),
      tiers: priceTiers?.filter((t) =>
        t.mpVendorCampaignIDs.some((mvID) => mvID === vC.mpVendorCampaignID)
      ),
      otherAssignedTiers: priceTiers?.filter(
        (t) =>
          t.mpVendorCampaignIDs.some(
            (mvID) => mvID === vC.mpVendorCampaignID
          ) && currentTier?.priceTierID !== t.priceTierID
      ),
      // Only recusively get parents if the vC is in the currents overflow
      overflowTiers: currentTier?.overflowMpVendorCampaignIDs.find(
        (vCID) => vCID === vC.mpVendorCampaignID
      )
        ? recursiveGetParentTiers(
            priceTiers!,
            vC.mpVendorCampaignID,
            currentTier.name
          )
        : [],
    }));
  const attributePrefix = 'lead-sources-card';

  return (
    <Col xs={6}>
      <SelectField
        id={`${attributePrefix}-vendors-dropdown`}
        name="vendorFilter"
        label="Vendor"
        options={vendors?.filter((v) => v.value) || []}
      />
      <ListGroup
        style={{ height: 500, overflowY: 'scroll' }}
        className="sonic-container"
      >
        {unassignedVendorCampaigns.map((vC) => (
          <ListGroupItem
            className="mb-2"
            color="secondary"
            key={`unassigned-${vC.mpVendorCampaignID}-${vC.layerID}`}
          >
            <div className="text-muted">
              {vC.mpVendorCampaignID}{' '}
              <Link
                to={`/lead-sources/${vC.layerGlobalID}`}
                target="_blank"
                className="ml-2"
              >
                <u>Lead Source</u>
              </Link>
              {vC.otherAssignedTiers.length > 0 && (
                <u
                  id={`tiers-${vC.layerID}-${vC.mpVendorCampaignID}`}
                  className="text-primary ml-2"
                  style={{
                    cursor: 'help',
                  }}
                >
                  Show Tiers
                </u>
              )}
            </div>
            <div className={classNames('mt-2', { 'd-none': !vC?.description })}>
              <span className="h5 font-weight-bold">{vC?.description}</span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span>
                {vC.vendorName} - {vC.campaignName}
              </span>
              {vC.otherAssignedTiers.length > 0 && (
                <UncontrolledPopover
                  target={`tiers-${vC.layerID}-${vC.mpVendorCampaignID}`}
                  placement="top"
                  trigger="hover"
                >
                  <PopoverHeader>Assigned Tiers</PopoverHeader>
                  <PopoverBody>
                    {vC.tiers.map((t: CustomPriceTier) => (
                      <div
                        key={`tier-${vC.mpVendorCampaignID}-${t.priceTierID}`}
                        className="d-flex align-items-center"
                      >
                        {t.name}{' '}
                        {vC.overflowTiers.find(
                          (oT) => oT.priceTierID === t.priceTierID
                        ) && (
                          <FaExclamationCircle className="text-warning ml-2" />
                        )}
                      </div>
                    ))}
                  </PopoverBody>
                </UncontrolledPopover>
              )}
              <Button
                color="primary"
                disabled={vC.overflowTiers.length > 0}
                onClick={() => push!(vC.mpVendorCampaignID)}
              >
                <FaArrowRight />
              </Button>
            </div>
          </ListGroupItem>
        ))}
      </ListGroup>
    </Col>
  );
};

const LeadSources: React.FC = () => {
  const { errors } = useFormikContext<{ mpVendorCampaignIDs: string[] }>();
  return (
    <FieldArray
      name="mpVendorCampaignIDs"
      render={({ push, remove }) => (
        <Card
          className={classNames(
            'mb-3',
            errors.mpVendorCampaignIDs && 'border-danger'
          )}
        >
          <CardHeader className="d-flex justify-content-between">
            <CardTitle tag="h5" className="py-2 h4 mb-0">
              Lead Sources
            </CardTitle>
          </CardHeader>
          <CardBody tag={Row}>
            <UnAssignedVendorCampaignsList push={push} />
            <AssignedVendorCampaignsList remove={remove} />
          </CardBody>
        </Card>
      )}
    />
  );
};

export default LeadSources;
