import gql from 'graphql-tag';

export const VendorCampaignTierBase = `
campaignName
mpVendorCampaignName
id
mpVendorCampaignID
priceTierIDs
layerGlobalID
vendorName
layerID
vendorPrice
fixedBidPrice
default
defaultPrice
defaultPercentage
customPricingEnabled
dynamicPricingEnabled
defaultPercentageEnabled
description
`;

const VendorCampaignTierFragment = gql`
  fragment VendorCampaignTierFragment on VendorCampaignTier {
    id
    __typename
    ${VendorCampaignTierBase}
  }`;

export default VendorCampaignTierFragment;
