/* eslint-disable no-undef, no-console */

import React, { useState, useEffect, createContext } from 'react';
import packageJson from '../../package.json';

// version from response - first param, local version second param
const semverGreaterThan = (versionA: string, versionB: string): boolean => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const refreshCacheAndReload = async (): Promise<void> => {
  console.log('Clearing cache and hard reloading...');
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    const names = await caches.keys();

    names.forEach((name: string) => caches.delete(name));
  }

  // delete browser cache and hard reload
  window.location.reload(true);
};

export const CacheContext = createContext({
  loading: true,
});

const CacheBuster: React.SFC = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const fetchVersion = async (): Promise<void> => {
    // eslint-disable-next-line no-undef
    const response = await fetch('/static/meta.json');
    const meta = await response.json();

    const latestVersion = meta.version;
    const currentVersion = packageJson.version;

    const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);

    // If we need to refresh
    if (shouldForceRefresh) {
      console.log(
        `We have a new version - ${latestVersion}. Should force refresh`
      );
      refreshCacheAndReload();
    }

    // Set the loading state
    setLoading(false);
  };

  // On Mount fetch the current version and refresh if needed
  useEffect(() => {
    fetchVersion();
  }, []);

  return (
    <CacheContext.Provider
      value={{
        loading,
      }}
    >
      {children}
    </CacheContext.Provider>
  );
};

export default CacheBuster;
