import gql from 'graphql-tag';

export const VendorCampaignBase = `
    campaignName
    mpVendorCampaignName
    mpVendorCampaignID
    layerGlobalID
    vendorName
    layerID
    delaySecondsForCall
    hasIncomingCalls
    doNotOverflow
    fixedBidPrice
    vendorPrice
    default
    defaultPrice
    defaultPercentage
    defaultPercentageEnabled
    customPricingEnabled
    dynamicPricingEnabled
    apBidEnhancementCategoryOveride
    description
    overrideMLPrice
    overrideMaxMLPrice

`;

const VendorCampaignFragment = gql`
  fragment VendorCampaignFragment on VendorCampaign {
    id
    __typename
    ${VendorCampaignBase}
  }`;

export default VendorCampaignFragment;
