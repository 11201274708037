import React from 'react';
import { Row, Col } from 'reactstrap';
import { FieldArray, useField } from 'formik';
import { useNetwork } from '@/hooks';
import AddonDropdown, { acaVerification } from './AddonDropdown';
import EligibleAddon from './EligibleAddon';

const AddonsTab: React.FC = () => {
  const { network } = useNetwork();
  const [{ value: filters }] = useField('filters');

  return (
    <FieldArray
      name="filters"
      render={(arrayHelpers) => (
        <Row className="mt-3">
          <Col xs={12} className="mb-3 d-flex">
            <AddonDropdown
              filters={filters}
              network={network}
              push={arrayHelpers.push}
            />
            <div className="d-flex align-items-center flex-wrap ml-2">
              {filters.map(
                (filter: any, index: number) =>
                  ['ADDON'].includes(filter.type) && (
                    <EligibleAddon
                      key={`${filter.type}-${filter.attribute}-${index}`}
                      index={index}
                      {...filter}
                      className="mb-2"
                      remove={() => arrayHelpers.remove(index)}
                    />
                  )
              )}
            </div>
          </Col>
        </Row>
      )}
    />
  );
};
export { acaVerification };

export default AddonsTab;
