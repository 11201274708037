import React, { useRef, useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { Storage } from 'aws-amplify';
import { FaDownload, FaSync, FaTimes } from 'react-icons/fa';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
} from 'reactstrap';
import Pagination, { PaginationContent } from '@availity/pagination';
import { reportManyQuery } from '@/graphql/queries';
import { NetworkContext } from '@/context';
import { useQuery } from '@apollo/react-hooks';
import { Vars } from '@/utils';
import ReportControls from './ReportControls';

interface ReportRowProps {
  fileName: string;
  url: string;
  cleanName: string;
  creationDate: number;
  status: string;
}
const ReportRow: React.SFC<ReportRowProps> = ({
  url,
  cleanName,
  creationDate,
  status,
}) => {
  const downloadLink = useRef<any>();

  const [link, setLink] = useState<any>(
    'https://i.ytimg.com/vi/j3GoK90M5aM/maxresdefault.jpg'
  );

  Storage.get(url)
    .then((data: any) => setLink(data))
    .catch((error) => console.error(error));

  const getStatus = () => {
    switch (status) {
      case 'FAILURE':
        return (
          <span>
            Failed <FaTimes className="text-danger" />
          </span>
        );
      case 'COMPLETE':
        return (
          <Button
            size="sm"
            title="Download Report"
            color="primary"
            className="ml-1"
            onClick={() => {
              downloadLink.current.click();
            }}
          >
            <FaDownload />
          </Button>
        );
      default:
        return (
          <span>
            Generating <FaSync className="loading-sync text-warning" />
          </span>
        );
    }
  };

  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          {moment(creationDate).format('MMM Do YYYY LTS').toString()}
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center">{cleanName}</div>
      </td>
      <td>
        <div className="d-flex align-items-center">
          {getStatus()}
          <a ref={downloadLink} href={link} download>
            &nbsp;
          </a>
        </div>
      </td>
    </tr>
  );
};

const ReportsPage: React.SFC<any> = () => {
  const { network } = useContext(NetworkContext);

  const { data, loading } = useQuery(reportManyQuery, {
    skip: !network?.networkID,
    variables: {
      folderID: network?.networkID,
      reportFolder: 'network',
    },
  });

  useEffect(() => {
    Storage.configure({
      AWSS3: {
        bucket: `reports.leadarena-${Vars.env}`,
        region: 'us-east-1',
      },
    });
  }, []);
  if (loading) return null;

  const { reportMany } = data;
  return (
    <Pagination
      items={reportMany.sort((a: ReportRowProps, b: ReportRowProps) => {
        if (a.creationDate > b.creationDate) {
          return -1;
        }
        if (a.creationDate < b.creationDate) {
          return 1;
        }
        // a must be equal to b
        return 0;
      })}
      itemsPerPage={50}
    >
      <Card className="my-5 mx-3">
        <CardHeader>
          <CardTitle
            tag="h3"
            className="d-flex justify-content-between align-items-center mb-0"
          >
            Reports
          </CardTitle>
        </CardHeader>
        <CardBody>
          {!network?.marketplace.includes('leadtopia') && (
            <ReportControls agentID="" />
          )}
          <Table striped size="sm" hover>
            <thead>
              <tr>
                <th>Time of Creation</th>
                <th>Report Name</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <PaginationContent
              itemKey="fileName"
              component={ReportRow}
              containerTag="tbody"
            />
          </Table>
        </CardBody>
      </Card>
    </Pagination>
  );
};

export default ReportsPage;
