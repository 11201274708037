/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Card, CardTitle, Row, Col } from 'reactstrap';
import { Field } from '@availity/form';
import { FaTrash } from 'react-icons/fa';
import { useField } from 'formik';
import { useRequiredAttributes } from '@/hooks';
import { ButtonToggle } from '@/components';
import { SelectField } from '@availity/select';
import classNames from 'classnames';

interface DuplicateFilterProps {
  attribute: string;
  index: number;
  remove: () => void;
  className?: string;
  label?: string;
  duplicateLevels: string[];
}

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const DuplicateFilter: React.FC<DuplicateFilterProps> = ({
  attribute,
  index,
  remove,
  className,
  label,
  duplicateLevels = ['corp', 'company'],
}) => {
  if (attribute === 'orgPhone') {
    attribute = 'primaryPhone';
  }

  const [{ value: skipMissing }, , { setValue: setSkipMissing }] = useField(
    `filters.${index}.skipMissing`
  );

  const [{ value: allowOnPing }, , { setValue: setAllowOnPing }] = useField(
    `filters.${index}.allowOnPing`
  );

  const [
    { value: skipGTBDuplicate },
    ,
    { setValue: setGTBDuplicateSkip },
  ] = useField(`skipGTBDuplicate`);

  switch (attribute) {
    case 'primaryPhone':
      useRequiredAttributes(['orgPhone'], !skipMissing);
      break;
    case 'tcpa':
      useRequiredAttributes(
        ['jornayaLeadID', 'trustedFormCertID', 'trustedFormLink'],
        !skipMissing
      );
      break;
    default:
      useRequiredAttributes([attribute], !skipMissing);
  }

  return (
    <Col xs={12} className={className}>
      <Card body className="h-100">
        <CardTitle
          tag="h4"
          className="lead text-uppercase d-flex justify-content-between align-items-center"
        >
          <span>{capitalize(label)} Filter</span>
          <FaTrash className="text-danger cursor-pointer" onClick={remove} />
        </CardTitle>
        <Row noGutters id={`${attribute}-filter-row`}>
          <Col xs={3}>
            <Field
              label={`${attribute} is duplicate after ( Days )`}
              name={`filters.${index}.minimumRefresh`}
              type="number"
              groupClassName="bg-dark text-white"
            />
          </Col>
          <Col xs={2} className="ml-4" id="lead-source-brokers">
            <SelectField
              name={`filters.${index}.level`}
              label="Level"
              labelKey="label"
              valueKey="value"
              options={
                duplicateLevels?.map((i) => ({
                  label: i,
                  value: i,
                })) || []
              }
            />
          </Col>
          <Col xs={2} className="ml-4">
            <div className="mb-2">Allow On Ping</div>
            <ButtonToggle
              key={`${attribute}-skip-missing`}
              active={allowOnPing}
              onToggle={(value: any) => setAllowOnPing(value)}
            />
          </Col>
          {attribute === 'primaryPhone'}
          {
            <Col xs={2}>
              <div className="mb-2">Skip HO GTB</div>
              <ButtonToggle
                key={`${attribute}-skip-gtbDuplicate`}
                active={skipGTBDuplicate}
                onToggle={(value: any) => setGTBDuplicateSkip(value)}
              />
            </Col>
          }
          <Col xs={2}>
            <div className="mb-2">Skip if Missing</div>
            <ButtonToggle
              key={`${attribute}-skip-missing`}
              active={skipMissing}
              onToggle={(value: any) => setSkipMissing(value)}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default DuplicateFilter;
