import gql from 'graphql-tag';

// export default gql`
//   mutation activeService($agentID: String, $email: String, $password: String, $serviceID: String) {
//     serviceActivation(input: {agentID: $agentID, email: $email, password: $password, serviceID: $serviceID}) {
//       callerReadyStatus
//       callerReadyAccountStatus
//   }
//   }
// `;

export default gql`
  mutation activeServiceMutation($input: ServiceActivationInput) {
    serviceActivation(input: $input) {
      callerReadyStatus
      callerReadyAccountStatus
    }
  }
`;
