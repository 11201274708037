import { updateDestinationMutation } from '@/graphql/mutations';
import { useMutation } from '@apollo/react-hooks';
import useDestinations from './useDestinations';
import useNotifications from './useNotifications';

const useDestination = (agentID: string, destinationID?: string) => {
  const { destinations, loading, error, refetch } = useDestinations(agentID);
  const { create: createNotification } = useNotifications();
  const destination = destinations.find((d) => d.destID === destinationID);
  const [updateDestination] = useMutation(updateDestinationMutation, {
    update() {
      createNotification(
        'success',
        `Successfully ${
          destinationID !== 'custom' ? 'update' : 'created'
        } destination`,
        1000
      );
      refetch({
        agentID,
      });
    },
  });

  return {
    loading,
    error,
    update: updateDestination,
    destination,
  };
};

export default useDestination;
