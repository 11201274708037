import gql from 'graphql-tag';
import { QueueFragment } from '../fragments';

export default gql`
  mutation updateQueueMutation($input: RelayUpdateQueueQueueInput!) {
    updateQueue(input: $input) {
      success
      queue {
        id
        status
        loopDelay
        logs
      }
    }
  }
`;
