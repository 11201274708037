import React, { useMemo, useState, useEffect } from 'react';
import {
  ListGroupItem,
  Row,
  Col,
  Button,
  UncontrolledPopover,
} from 'reactstrap';
import Switch from 'react-switch';
import moment from 'moment';
import { FaExclamationTriangle, FaCheck, FaCopy } from 'react-icons/fa';
import { formatCurrencyForDisplay } from '@/utils';
import { sumSpendingPeriods } from './utils';
import { CopyButton } from '@/components';

const CampaignListItem: React.SFC<any> = ({
  campaignName,
  id,
  campaignID,
  priceTier,
  spendingPeriods,
  toggleDate,
  paused,
  toggleSpendingPeriodModal,
}) => {
  const [copied, setCopied] = useState(false);

  const hasSpendingPeriods = useMemo(() => spendingPeriods.length > 0, [
    spendingPeriods.length,
  ]);

  useEffect(() => {
    if (copied) {
      const tm = setTimeout(() => {
        setCopied(false);
      }, 1000);

      return () => clearTimeout(tm);
    }
    return () => {};
  }, [copied]);

  return (
    <>
      <ListGroupItem
        className="mb-3 d-flex align-items-center border-top border-secondary"
        tag={Row}
        noGutters
      >
        <Col xs={2} className="d-flex flex-column text-center">
          <span
            style={{
              fontSize: 20,
            }}
          >
            {campaignName}
          </span>
          <span>Campaign</span>
        </Col>
        <Col xs={2} className="d-flex flex-column text-center">
          <span>
            <Switch
              height={25}
              disabled
              width={50}
              handleDiameter={25}
              checked={!paused}
              onChange={() => {}}
            />
          </span>
          <span id={`name-${campaignID}`}>
            {!paused ? 'Running' : 'Paused'}
          </span>
          <UncontrolledPopover
            target={`name-${campaignID}`}
            placement="bottom"
            trigger="hover"
          >
            Last Modified{' '}
            {toggleDate ? moment(toggleDate).format('MM/DD/YY hh:mm A') : 'N/A'}
          </UncontrolledPopover>
        </Col>
        <Col xs={2} className="d-flex flex-column text-center">
          <span
            style={{
              fontSize: 20,
            }}
          >
            {formatCurrencyForDisplay(sumSpendingPeriods(spendingPeriods))}/wk
          </span>
          <span>Budget</span>
        </Col>
        <Col xs={3} className="d-flex flex-column text-center">
          <span
            style={{
              fontSize: 20,
            }}
          >
            {priceTier.name} -{' '}
            {formatCurrencyForDisplay(
              priceTier.liveTransferPrice || priceTier.price
            )}
          </span>
          <span>Price Tier</span>
        </Col>
        <Col
          xs={{
            size: 3,
          }}
          className="d-flex justify-content-end"
        >
          {hasSpendingPeriods ? (
            <Button
              color="primary"
              className="d-flex align-items-center"
              onClick={() => toggleSpendingPeriodModal(id)}
            >
              View Spending Periods
            </Button>
          ) : (
            <div className="d-flex align-items-center">
              <FaExclamationTriangle className="mr-2" color="#F39C12" /> NO
              SCHEDULE SET
            </div>
          )}
          <CopyButton className="ml-3" content={campaignID} />
        </Col>
      </ListGroupItem>
    </>
  );
};

export default CampaignListItem;
