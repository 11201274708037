import gql from 'graphql-tag';
import { PriceTierFragment } from '@/graphql/fragments';

export default gql`
  query priceTierManyQuery(
    $networkID: String
    $networkIDs: [String!]
    $liveTransfers: Boolean
  ) {
    priceTierMany(
      networkID: $networkID
      networkIDs: $networkIDs
      liveTransfers: $liveTransfers
    ) {
      ...PriceTierFragment
    }
  }
  ${PriceTierFragment}
`;
