import React, { useState, useEffect, useContext } from 'react';
import { Form } from '@availity/form';
import { Storage } from 'aws-amplify';
import * as yup from 'yup';
import { SelectField } from '@availity/select';
import moment from 'moment';
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Row,
  Col,
} from 'reactstrap';
import { Vars } from '@/utils';
import { FaCalendarMinus } from 'react-icons/fa';
import { dateRange } from '@availity/yup';
import { DateRangeField } from '@availity/date';
import { getReportOptionsQuery, reportManyQuery } from '@/graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import LeadsShowExportModal from '@/modals/LeadsShowExportModal';
import omit from 'lodash.omit';
import { useNetwork } from '@/hooks';

interface ReportControlsProps {
  agentID: string;
}
const ReportControls: React.SFC<ReportControlsProps> = ({ agentID }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [showExportModal, setShowExportModal] = useState(false);

  const { network } = useNetwork();
  const toggleDropdown = () => setDropdownOpen((o) => !o);

  const { data = {}, loading } = useQuery(reportManyQuery, {
    skip: !network?.networkID,
    variables: {
      folderID: network.networkID,
      reportFolder: 'network',
    },
  });

  const { reportMany = [] } = data;

  if (loading) return null;

  const noGeneratingReports = reportMany.every(
    ({ status }: any) => status !== 'GENERATING'
  );

  return (
    <div style={{ marginBottom: 10 }}>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle split caret>
          Report Generator{' '}
        </DropdownToggle>
        <DropdownMenu className="p-4" style={{ width: 600 }}>
          <Row xs={12}>
            <Form
              initialValues={{
                reportType: '',
                vendorID: '',
                dateRange: {
                  startDate: moment({ hour: 0 }).format(),
                  endDate: moment({ hour: 0 }).format(),
                },
              }}
              validationSchema={yup.object().shape({
                reportType: yup
                  .string()
                  .required('This field is required.')
                  .isRequired(),
                vendorName: yup.string().when('reportType', {
                  is: 'vendorRefunds',
                  then: yup
                    .string()
                    .required('This field is required.')
                    .isRequired(),
                  otherwise: yup.string(),
                }),
                dateRange: dateRange({}),
              })}
              onSubmit={(values) => {
                const { reportType, vendorID, dateRange } = values;
                const filePath = `leads/exports/searchPayload-${moment().format()}.json`;

                const { startDate = '', endDate = '' } = dateRange as any;
                Storage.vault.put(
                  filePath,
                  JSON.stringify({
                    folderID: network!.networkID,
                    reportFolder: 'network',
                    type: reportType,
                    filter: {
                      vendorID,
                      brokerID: network!.brokerID,
                      startDate,
                      endDate,
                    },
                    networkID: network?.networkID,
                  }),
                  {
                    level: 'private',
                    contentType: 'application/json',
                    bucket: `report-filters-${Vars.env}`,
                    region: 'us-east-1',
                  }
                );
                setShowExportModal(true);
                toggleDropdown();
              }}
            >
              {({ values }) => {
                const {
                  data: searchOptions = {},
                  loading: searchLoading,
                } = useQuery(getReportOptionsQuery, {
                  skip: !network?.networkID,
                  variables: {
                    networkID: network?.networkID,
                  },
                });

                const { vendors = [] } = searchOptions.getReportOptions || {};

                return (
                  <Row style={{ marginLeft: 10, marginRight: 10 }}>
                    <Col xs={12}>
                      <DateRangeField
                        className="leads-date-range"
                        name="dateRange"
                        id="dateRange"
                        label="Date Range"
                        customArrowIcon="-"
                        calendarIcon={
                          <div>
                            <FaCalendarMinus />
                          </div>
                        }
                        datepickerProps={{
                          renderMonthElement: undefined,
                        }}
                        ranges
                      />
                    </Col>
                    <Col xs={6}>
                      <SelectField
                        name="reportType"
                        label="Report Type"
                        className="left-filter-select"
                        valueKey="reportType"
                        placeholder="Select report type..."
                        options={network?.reportOptions || []}
                        getOptionLabel={(opt: any) => {
                          return opt.label;
                        }}
                      />
                    </Col>

                    <Col xs={6}>
                      <SelectField
                        name="vendorID"
                        label="Vendor"
                        labelKey="vendorName"
                        valueKey="vendorID"
                        options={vendors}
                      />
                    </Col>

                    <Col xs={12}>
                      <Button
                        type="submit"
                        size="md"
                        color="primary"
                        disabled={!noGeneratingReports}
                      >
                        Generate
                      </Button>
                      {!noGeneratingReports && (
                        <span className="text-danger ml-2">
                          You can only generate one report at a time.
                        </span>
                      )}
                    </Col>
                  </Row>
                );
              }}
            </Form>
          </Row>
        </DropdownMenu>
      </Dropdown>
      <LeadsShowExportModal
        toggle={() => setShowExportModal((o) => !o)}
        isOpen={showExportModal}
      />
    </div>
  );
};

export default ReportControls;
