import gql from 'graphql-tag';

export default gql`
  mutation brokerTransferMutation($input: BrokerTransferInput) {
    brokerTransfer(input: $input) {
      # fromAgentID, agentID, networkID, brokerID, amount
      transfers {
        id
        agentID
        value
        errors
        status
      }
    }
  }
`;
