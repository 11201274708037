import gql from 'graphql-tag';

import SpendingPeriodFragment from './SpendingPeriodFragment';
import PriceTierFragment from './PriceTierFragment';

const CampaignFragment = gql`
  fragment CampaignFragment on Campaign {
    __typename
    id
    brokerID
    paused
    campaignTierID
    campaignName
    campaignID
    agentID
    fixedCampaignName
    agreed
    marketplace
    destinations
    toggleDate
    myAgentDestinations
    spendingPeriodIDs
    spendingPeriods {
      ...SpendingPeriodFragment
    }
    priceTier {
      ...PriceTierFragment
    }
    filters {
      topLevel {
        field
        include
        recordType
      }
    }
  }
  ${SpendingPeriodFragment}
  ${PriceTierFragment}
`;
export default CampaignFragment;
