import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  FormText,
  Label,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  Form,
} from 'reactstrap';
import LoadingButton from '@gopro16/button';
import { FaLock } from 'react-icons/fa';
import MoneyInput from '@/components/MoneyInput';
import { useNotifications } from '@/hooks';
import { useMutation } from '@apollo/react-hooks';
import { brokerTransferMutation } from '@/graphql/mutations';
import { formatCurrencyForDisplay } from '@/utils';

const AddCreditModal: React.SFC<{
  isOpen: boolean;
  toggle: any;
  broker: any;
}> = ({ isOpen, toggle, broker }) => {
  const { create: createNotification } = useNotifications();
  const [creditToAdd, setCreditToAdd] = useState(0);
  const [confirmCreditToAdd, setConfirmCreditToAdd] = useState(false);

  const selectedBroker = broker;

  const [brokerTransfer, { loading: updating }] = useMutation(
    brokerTransferMutation,
    {
      onCompleted: () => {
        createNotification('success', 'Transfer successful');
        setCreditToAdd(0);
        toggle({ broker: {}, refresh: true });
      },
      onError: (error) =>
        createNotification(
          'danger',
          error.message.replace('GraphQL error:', '')
        ),
    }
  );
  useEffect(() => {
    setConfirmCreditToAdd(false);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Add Credit to {selectedBroker.brokerName}
      </ModalHeader>
      <ModalBody>
        <Container>
          <Form
            onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {
              event.preventDefault();

              brokerTransfer({
                variables: {
                  input: {
                    amount: creditToAdd,
                    fromAgentID: broker.brokerID,
                    agentID: broker.agentID,
                    networkID: broker.networkID,
                    brokerID: broker.brokerID,
                  },
                },
              });
            }}
          >
            <Label for="addCredit">{`Transfer Credit from ${broker.brokerName}`}</Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">$</InputGroupAddon>
              <MoneyInput
                name="credit"
                onChange={setCreditToAdd}
                value={creditToAdd}
                min={0}
              />
              <InputGroupAddon addonType="append">USD</InputGroupAddon>
            </InputGroup>
            <br />
            <span className="h4 text-warning">
              You are transfering: {formatCurrencyForDisplay(creditToAdd)}
            </span>
            <br />
            <FormGroup className="mt-3 d-flex flex-column">
              <div>
                <Button
                  color="success"
                  onClick={() => setConfirmCreditToAdd(true)}
                  disabled={creditToAdd === 0 || confirmCreditToAdd}
                >
                  <span className="mr-2">Transfer Credit</span>
                </Button>
                {confirmCreditToAdd && (
                  <LoadingButton
                    className="ml-3"
                    color="warning"
                    loading={updating}
                    disabled={updating}
                  >
                    <span className="mr-2">Confirm</span>
                    <FaLock />
                  </LoadingButton>
                )}
              </div>
              <br />
              <small className="text-muted">
                Pressing this button transfers credit from the broker account.
                Please review the total amount above before pressing this
                button.
              </small>
            </FormGroup>
          </Form>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddCreditModal;
